import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { sendEndpointRequestExtended } from "../../iop/iop";
import { SkillEndpoints } from "../../api/Endpoints";
import { UserContext } from "../../provider";
import './SkillCards.css';
import { Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import CreateEvidence from './UserCreateEvidence';

const useStyles = makeStyles((theme) => ({
  noDataContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginTop: '5vh',
    marginLeft: '15%'
  },
  noDataBubble: {
    position: 'relative',
    backgroundColor: '#9c27b0',
    padding: theme.spacing(2),
    borderRadius: '8px',
    textAlign: 'center',
    boxShadow: theme.shadows[3],
    margin: theme.spacing(2),
    "&:hover": {
      backgroundImage: 'linear-gradient(to left, #e7b3f5, #d7bdde)',

    },
  },
  noDataText: {
    color: theme.palette.text.secondary,
  } 
}));

/**
 * Evidence Cards
 */
const EvidenceCards = ({ userEmail, skillTitle, refreshKey }) => {
  const classes = useStyles();
  const { user } = useContext(UserContext);
  const [evidenceList, setEvidenceList] = useState([]);
  const [evidenceImages, setEvidenceImages] = useState([]);
  const [contents, setContents] = useState([]);
  const[modalOpen, setModalOpen] = useState(false);
  //const [selectedSkill, setSelectedSkill] = useState(null);
  const navigate = useNavigate();
  const authorized = user && user.email === userEmail;

  const handleBubbleClick = () => {
    setModalOpen(true);
  };

  const fetchExistingEvidenceList = async () => {
    try {
      const pathVars = {
        "user-name": userEmail,
        "skill-title": skillTitle
      };
      const response = await sendEndpointRequestExtended( { authorized }, SkillEndpoints.endpoints.getEvidenceDescriptorList, null, null, pathVars);

      if (!(response.success || response.status === 200) || !response.data) {
        throw new Error(`Failed to get user skill evidence: ERROR: ${response.error?.code || 'Unknown error'}`);
      }

      setEvidenceList(response.data);
    } catch (error) {
      console.error('Error fetching existing evidence list:', error);
      alert('Failed to load existing evidence list. Please try again.');
    }
  };

  const handleViewClick = (evidence) => {
    navigate(`/evidence/${userEmail}/${skillTitle}/${evidence}`);
  };

  const handleEditClick = (evidence) => {
    /* Navigate to the page where the user can edit evidence
       content */
    navigate(`/edit-evidence/${skillTitle}/${evidence}`);
  };

const fetchContent = async (evidenceTitle) => {
  if (!evidenceTitle) return;

  try {
    const pathVars = {
      "user-name": user.email,
      "skill-title": skillTitle,
      "content-title": evidenceTitle
    };
    const response = await sendEndpointRequestExtended( { authorized }, SkillEndpoints.endpoints.getEvidenceContext, null, null, pathVars);
    console.log("{EvidenceCard} Fetch Content Success: ", response);

    if (!(response.success || response.status === 200) || !response.data) {
      throw new Error(`Failed to fetch user evidence content: ERROR: ${response.error?.code || 'Unknown error'}`);
    }
    if (response.data.txtContent) {
      var content = response.data.txtContent.toString() || "";
      while (content.includes("<img")) {
        var start = content.indexOf("<img");
        var end = content.slice(start).indexOf(">") + start + 1;
        var imgString = content.slice(start, end);
        if (evidenceImages[evidenceTitle]) {
          content = content.slice(0, start) + content.slice(end);
        } else {
          var srcString = imgString.slice(imgString.indexOf("src"));
          var srcStart = srcString.indexOf("\"");
          var srcEnd = srcString.indexOf("\"", srcStart + 1);
          srcString = srcString.slice(srcStart + 1, srcEnd);
          setEvidenceImages(prev => ({ ...prev, [evidenceTitle]: srcString }));
          content = content.slice(0, start) + content.slice(end);
          }
        }

        content = content.replace(/<\/?(p|h2|h3|h4|figure class="image"|figure|ul|li|blockquote|a|strong)>/g, "");
        content = content.replace("&nbsp;", "");
  
        if (content.length > 300) {
          content = content.slice(0, 300);
        }
      }

      setContents(prev => ({ ...prev, [evidenceTitle]: content }));
      return content;
    } catch (error) {
      console.error("{EvidenceCard} Fetch Content Error: ", error);
    } finally {
    }
  };

  useEffect(() => {
    fetchExistingEvidenceList();

  }, [refreshKey]);

  useEffect(() => {
    evidenceList.forEach(evidence => {
      fetchContent(evidence.skillTitle);
    });
  }, [evidenceList]);

  return (
    <div>
      {evidenceList.length > 0 ? (
        <div className="skill-cards-container">
        <CreateEvidence
          skillTitle={skillTitle}
          authorized={authorized}
        />
          {evidenceList.map((evidence) => (
            <div 
              key={evidence.skillTitle}
              className="skill-card"
            >
              {evidenceImages[evidence.skillTitle] && (
                <img src={evidenceImages[evidence.skillTitle]} alt={evidence.title} className="skill-card-image" />
              )
              }
              <div className="skill-card-content">
                <h3>{evidence.skillTitle}</h3>
                {contents[evidence.skillTitle] && <p>{contents[evidence.skillTitle]}</p>}
                {!contents[evidence.skillTitle] && (
                  <p sx={{ fontStyle: "italic", textAlign: "center" }}>
                    No content on this evidence yet...
                  </p>
                )}
                <div className="skill-card-buttons">
                  <button onClick={() => handleViewClick(evidence.skillTitle)} className="view-button">View</button>
                  { authorized && <button onClick={() => handleEditClick(evidence.skillTitle)} className="edit-button">Edit</button>}
                </div>
              </div>
            </div>
          ))}
      </div>
        ) : (
          <div className={classes.noDataContainer }>
          <Typography variant="h3"
            sx={{
              marginBottom: '0.5em'
            }}
            className={classes.noDataText}>
            Looks like you don't have any evidence for this skill yet...
          </Typography>

      <Typography variant="body1"
        sx={{ marginBottom: '4em' }}
        className={classes.noDataText}>
        When you add skill related content to your profile, it will be available here for you
        and others to view.
      </Typography>
            <CreateEvidence
              skillTitle={skillTitle}
              isStartCreating={true}
              authorized={authorized}
            />
        
      </div>
        )}
    </div>
  );
};

export default EvidenceCards;