import logo from "../../images/skills-log-logo.png";
import { useNavigate } from "react-router-dom";

const NavbarImage = () => {
  const navigate = useNavigate();
  return (
    <div
      className="nav-header"
    >
      <div>
        { <img src={logo} className="logo" alt="logo" /> }
      </div>
    </div>
  );
};
export default NavbarImage;
