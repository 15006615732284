import { useLocation } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { getRequest } from "../../api/helper";
import { verifyUsers } from "../../api/user";
import {
  Button,
  CssBaseline,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
} from "@mui/material";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import theme from "../../styles/theme";
import { ThemeProvider } from "@mui/material/styles";
import { toast } from "react-toastify";
import useStyles from "../../styles/styles";

const UnverifiedUsers = () => {
  const { state } = useLocation();
  const { url } = state;
  const [emailsToBeVerified, setEmailsToBeVerified] = useState([]);
  const [allUnverifiedUsers, setAllUnverifiedUsers] = useState([]);
  const [successfullyVerifiedUsers, setSuccessfullyVerifiedUsers] = useState(
    []
  );
  const classes = useStyles();

  const fetchAllUnverifiedUsers = async () => {
    try {
      const unverifiedUsers = await getRequest(url);
      setAllUnverifiedUsers(unverifiedUsers.emails);
      console.log(
        "All Unverfied Users Endpoint in Unverified Users: ",
        unverifiedUsers
      );
    } catch (error) {
      console.error("Error fetching all unverified users: ", error);
    }
  };

  useEffect(() => {
    fetchAllUnverifiedUsers();
  }, [allUnverifiedUsers]);

  const toggleEmailSelection = (email) => {
    if (emailsToBeVerified.includes(email)) {
      setEmailsToBeVerified((prevSelectedUsers) =>
        prevSelectedUsers.filter((user) => user !== email)
      );
    } else {
      setEmailsToBeVerified((prevSelectedUsers) => [
        ...(prevSelectedUsers || []),
        email,
      ]);
    }
  };

  const handleButtonSubmit = () => {
    try {
      if (emailsToBeVerified.length < 1) {
        toast.error("No users selected");
        return;
      }
      const response = verifyUsers(emailsToBeVerified);
      setSuccessfullyVerifiedUsers(response);
      console.log(
        "Successfully verified unverified users: ",
        successfullyVerifiedUsers
      );
      toast.success("Successfully verified users");
      setAllUnverifiedUsers([]);
      setEmailsToBeVerified([]);
    } catch (error) {
      console.error("Error verifying users: ", error);
      toast.error("Error verifying users:");
    }
  };

  return (
    <>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className={classes.CentralizeDiv}>
          <div>
            <Typography sx={{ mt: 3 }} variant="h4" gutterBottom>
              Select users for verification
            </Typography>
            <Button
              variant="contained"
              fullWidth
              color="primary"
              sx={{ mt: 3, mb: 3 }}
              disabled={emailsToBeVerified.length === 0}
              onClick={() => handleButtonSubmit()}
            >
              Validate Selected Users {<KeyboardArrowRightIcon />}
            </Button>
            {allUnverifiedUsers.length > 0 ? (
              <>
                <Typography variant="h4" gutterBottom>
                  All Unverified Users
                </Typography>
                <List>
                  {allUnverifiedUsers.map((email, index) => (
                    <ListItemButton
                      style={{
                        backgroundColor: emailsToBeVerified.includes(email)
                          ? theme.palette.success.main
                          : "",
                      }}
                    >
                      <ListItem
                        key={index}
                        onClick={() => toggleEmailSelection(email)}
                      >
                        <ListItemText style={{ textAlign: "center" }}>
                          {email}
                        </ListItemText>
                      </ListItem>
                    </ListItemButton>
                  ))}
                </List>
              </>
            ) : (
              <Typography sx={{ mt: 3 }} variant="h6" gutterBottom>
                No unverified users left
              </Typography>
            )}
          </div>
          {/* )} */}
        </div>
      </ThemeProvider>
    </>
  );
};
export default UnverifiedUsers;
