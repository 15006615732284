const Footer = () => {
  return (
    <footer id="footer">
      <ul className="icons">{/* Social media icons */}</ul>
      <ul className="copyright">
        <li>&copy; Genics. All rights reserved.</li>
      </ul>
    </footer>
  );
};
export default Footer;
