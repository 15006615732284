import React, { useContext, useEffect } from "react";
import { UserContext, DashboardContext } from "../../provider";
import Loading from "../pages/Loading";
import { useSearchParams, Link, Outlet } from "react-router-dom";
import { retrieveDash } from "../../api/dashboard";
import { headers } from "../../api/user";
import { CssBaseline } from "@mui/material";
import Grid from "@mui/material/Grid";
import theme from "../../styles/theme";
import { ThemeProvider } from "@mui/material/styles";
import "../../styles/assets/css/main.css";

const Dashboard = ({}) => {
  const { user } = useContext(UserContext);
  const { board, updateBoard } = useContext(DashboardContext);
  const [searchParams] = useSearchParams();

  const loadDashboard = async () => {
    try {
      const data = await retrieveDash({
        headers: headers(),
      });
      updateBoard(data);
    } catch (error) {
      console.error("Failed to load dashboard:", error);
    }
  };

  useEffect(() => {
    if (!board || Object.values(board._links).length === 0) {
      loadDashboard();
    }
  }, [board, updateBoard]);

  if (!board) {
    return (
      <div>
        {searchParams.get("permission_denied") && (
          <p style={{ color: "red" }}>Permission Denied</p>
        )}
        <Loading />
      </div>
    );
  }

  return (
    <>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div style={{ padding: "2rem" }}>
          <Outlet />
        </div>
      </ThemeProvider>
    </>
  );
};
export default Dashboard;
