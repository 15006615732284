import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getRequest } from "../../api/helper";
import { headers } from "../../api/user";
import axios from "axios";
import { CssBaseline, Typography } from "@mui/material";
import useStyles from "../../styles/styles";
import theme from "../../styles/theme";
import { ThemeProvider } from "@mui/material/styles";
import EndorsementBadge from "../EndorsementBadge";
import { toast } from "react-toastify";

const HiddenEndorsements = () => {
  const classes = useStyles();
  const { state } = useLocation();
  const { url } = state;
  const navigate = useNavigate();

  const [report, setReport] = useState([]);
  const [dependentUrl, setDependentUrl] = useState("");

  const fetchReportData = async () => {
    try {
      setDependentUrl(url);
      const response = await getRequest(url);
      const report = await response.report;
      setReport(report);
    } catch (error) {
      console.error("Error fetching hidden:", error);
    }
  };

  const executeButtonLink = async (link) => {
    try {
      console.log("URL", dependentUrl);
      const response = await axios.post(link.href, null, {
        headers: headers(),
      });
      fetchReportData();
      console.log("Response from UnhideEndorsement", response);
      toast.success("Endorsement added to report");
    } catch (error) {
      console.error("Error unhiding:", error);
      toast.error("Unable to add endorsement to report at this moment");
    }
  };

  useEffect(() => {
    fetchReportData();
  }, []);

  if (report.length === 0) {
    return (
      <div className={classes.CentralizeDiv}>
        <Typography variant="h3" color="primary" my={5}>
          No hidden endorsements
        </Typography>
      </div>
    );
  }

  return (
    <>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className={classes.CentralizeDiv}>
          <Typography
            variant="h3"
            textAlign={"center"}
            color="primary"
            my={3}
            p={3}
          >
            Hidden Endorsements
          </Typography>

          <div>
            {report.map((badge) => (
              <EndorsementBadge
                key={badge.badgeDetails.id}
                badgeDetails={badge.badgeDetails}
                endorsements={badge.endorsementDtos}
                executeButtonLink={executeButtonLink}
                hiddenEndorsementsPage={true}
              />
            ))}
          </div>
        </div>
      </ThemeProvider>
    </>
  );
};

export default HiddenEndorsements;
