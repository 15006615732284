import { UserContext } from "../../../provider";
import React, { useState, useEffect, useContext } from "react";
import { Box, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { sendEndpointRequestExtended } from "../../../iop/iop";
import { SkillEndpoints } from "../../../api/Endpoints";
import CircularProgress from '@mui/material/CircularProgress';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  centeredContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '200px',
    backgroundColor: '#f9f9f9',
    borderRadius: '8px',
    padding: theme.spacing(3),
    boxShadow: theme.shadows[1],
  },
  loadingText: {
    color: theme.palette.primary.main,
    marginBottom: theme.spacing(2),
  },
  loadingIcon: {
    color: theme.palette.primary.main,
  },
  errorText: {
    color: theme.palette.error.main,
    marginBottom: theme.spacing(2),
  },
  errorIcon: {
    color: theme.palette.error.main,
    fontSize: '2rem',
  },
  noDataContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginTop: '5vh'
  },
  noDataBubble: {
    position: 'relative',
    backgroundColor: '#9c27b0',
    padding: theme.spacing(2),
    borderRadius: '8px',
    textAlign: 'center',
    boxShadow: theme.shadows[3],
    margin: theme.spacing(2),
    "&:hover": {
      backgroundImage: 'linear-gradient(to left, #e7b3f5, #d7bdde)',
      
    },
  },
  noDataText: {
    color: theme.palette.text.secondary,
  },
  container: {
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
  box: {
    borderRadius: '8px',
    padding: theme.spacing(2),
    boxShadow: theme.shadows[3],
    border: `1px solid ${theme.palette.divider}`,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    transition: 'background-color 0.3s ease, border 0.3s ease',
    animation: 'bounce 0.6s ease-in-out infinite',
    "&:hover": {
      backgroundImage: 'linear-gradient(to right, #fbe6ff, #e7caed)',
    },
  },
  '@keyframes bounce': {
    '0%, 100%': {
      transform: 'translateY(0)',
    },
    '50%': {
      transform: 'translateY(-10px)',
    },
  },
  header: {
    fontSize: '4em',
    fontWeight: 'bold',
    textAlign: 'left',
    marginBottom: theme.spacing(1),
  },
  cardHeader: {
    fontSize: '1.25rem',
    fontWeight: 'bold',
    textAlign: 'left',
    marginBottom: theme.spacing(1),
  },
  text: {
    fontSize: '1rem',
    textAlign: 'left',
    color: theme.palette.text.primary,
  },
}));

const UserQuickActionPane = ({ username }) => {
  const classes = useStyles();
  const [actionData, setActionData] = useState([]);
  const [authorized, setAuthorized] = useState(false);
  const navigate = useNavigate();
  const { user } = useContext(UserContext);

  const DisplayDataState = Object.freeze({
    VISIBLE: "visible",
    LOADING: "loading",
    NO_DATA: "no data",
    FAILED_FETCH: "failed fetch",
  });
  const [displayDataState, setDisplayDataState] = useState(DisplayDataState.LOADING);

  /**
   * Fetch all of the skills that this user is working on
   */
  const fetchUserSkills = async () => {
    try {
      const response = await sendEndpointRequestExtended(
        { authorized }, 
        SkillEndpoints.endpoints.getUserSkillList, 
        null, 
        null, 
        { "user-name": username }
      );

      if (Array.isArray(response.data)) {
        setDisplayDataState(response.data.length > 0 ? DisplayDataState.VISIBLE : DisplayDataState.NO_DATA);
        if (response.data.length > 0) setActionData(response.data);
      } else {
        setDisplayDataState(DisplayDataState.FAILED_FETCH);
      }
    } catch (error) {
      setDisplayDataState(DisplayDataState.FAILED_FETCH);
    }
  };


  useEffect(() => {
    if (username) {
      fetchUserSkills();
    }
  }, [username]);

  useEffect(() => {
    if (user && username === user.email) {
      setAuthorized(true);
    } else {
      setAuthorized(false);
    }
  }, [user]);
  const handleBubbleClick = () => {
    navigate('/skills?tutorial=true');
  };

  /* Loading display state */
  if (displayDataState === DisplayDataState.LOADING) {
    return (
      <Box className={classes.centeredContainer}>
        <Typography variant="h6" className={classes.loadingText}>
          Retrieving Skill Data...
        </Typography>
        <CircularProgress className={classes.loadingIcon} />
      </Box>
    );
  }

  /* Failed fetch display state */
  if (displayDataState === DisplayDataState.FAILED_FETCH) {
    return (
      <Box className={classes.centeredContainer}>
        <Typography variant="h6" className={classes.errorText}>
          Failed to fetch skill data. Please try again later.
        </Typography>
        <ErrorOutlineIcon className={classes.errorIcon} />
      </Box>
    );
  }

  /* No data display state */
  if (displayDataState === DisplayDataState.NO_DATA) {
    return (
    
      <div className={classes.noDataContainer}>

          { authorized &&  <Typography variant="h3" 
          sx={{
            marginBottom: '0.5em'
          }}
                                       className={classes.noDataText}>
            Looks like you don't have any skills yet...
          </Typography>}

          { authorized &&  <Typography variant="body1"
                                       sx={{ marginBottom: '4em' }}
                                       className={classes.noDataText}>
            When you add skill related content to your profile, it will be available here for you
            and others to view.
          </Typography>}

        { authorized && <Box className={classes.noDataBubble} onClick={handleBubbleClick}>
        <Typography sx={{ color: 'white', fontWeight: 'bold' }} variant="h6" className={classes.noDataText}>
          Start creating content
        </Typography>
        </Box> }

          { !authorized &&  <Typography variant="h6" className={classes.noDataText}>
            No Skills available for this user
          </Typography>  }
          { !authorized &&  <Typography variant="body1" className={classes.noDataText}>
            Come back soon to view this users' skills!
          </Typography>}
      </div>
    );
  }

  /* Success case display state */
  return (
    <Box className={classes.container}>
        { authorized && <Typography variant="h2" className={classes.header}>
          My Skills
        </Typography> }
        { !authorized && <Typography variant="h2" className={classes.header}>
          Skills
        </Typography> }
      {actionData.map((item, index) => (
        <Box key={index} className={classes.box} onClick={() => navigate(`/user-skill/${username}/${item.parentSkillTitle}`)}>
          <Typography variant="h6" className={classes.cardHeader}>
            {item.parentSkillTitle}
          </Typography>
          {/* <Typography className={classes.text}> //TODO PMG  Badge count will always be zero. Why you ask? Well, because it is unclear what a badge even is.
            Badges Earned: 12
          </Typography> */}
          <Typography className={classes.text}>
            Endorsements: {item.endorsementsEarned >= 0 ? item.endorsementsEarned : "?"}
          </Typography>
        </Box>
      ))}
    </Box>
  );
};

export default UserQuickActionPane;
