import React, { useState, useEffect } from "react";
import { useLocation, Link } from "react-router-dom";
import { getRequest } from "../../api/helper";
import { CssBaseline, Typography, Paper } from "@mui/material";
import useStyles from "../../styles/styles";
import theme from "../../styles/theme";
import { ThemeProvider } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";

const UserDetails = () => {
  const classes = useStyles();
  const { state } = useLocation();
  const { url } = state;

  const [UserData, setUserData] = useState([]);
  const [Success, setSuccess] = useState([]);

  const loadUserDetails = async () => {
    try {
      const response = await getRequest(url);
      const report = await response;
      setUserData(report);
      console.log("REPORT: " + report);
    } catch (error) {
      console.error("Error fetching user details:", error);
      setSuccess(false);
    }
  };

  useEffect(() => {
    loadUserDetails();
  }, []);

  if (!Success) {
    return (
      <>
        <ThemeProvider theme={theme}>
          <CssBaseline />

          <Typography variant="h3">User Details </Typography>

          <Table stickyHeader size="small">
            <TableBody>
              <Paper
                sx={{
                  p: 2,
                  m: 4,
                  boxShadow: 5,
                }}
              >
                <TableRow>
                  <TableCell style={{ borderBottom: "none" }}>
                    <Typography variant="h6">
                      No details available for this user.
                    </Typography>
                  </TableCell>
                </TableRow>
              </Paper>
            </TableBody>
          </Table>
        </ThemeProvider>
      </>
    );
  } else {
    return (
      <>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Typography variant="h3">User Details</Typography>

          <Table stickyHeader size="small">
            <TableBody>
              <>
                <Paper
                  sx={{
                    p: 2,
                    m: 4,
                    boxShadow: 5,
                  }}
                >
                  <TableRow>
                    <TableCell style={{ borderBottom: "none" }}>
                      <Typography variant="h6">
                        <Typography display="inline" variant="h6" sx={{fontWeight: "bold"}}>Name: </Typography>
                        {UserData.lastName}, {UserData.firstName}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{ borderBottom: "none" }}>
                      <Typography variant="h6">
                      <Typography display="inline" variant="h6" sx={{fontWeight: "bold"}}>Email: </Typography>
                        {UserData.email}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{ borderBottom: "none" }}>
                      <Typography variant="h6">
                      <Typography display="inline" variant="h6" sx={{fontWeight: "bold"}}>Badges: </Typography>
                        {UserData.badges?.length}
                      </Typography>
                    </TableCell>
                  </TableRow>
                </Paper>
              </>
            </TableBody>
          </Table>
        </ThemeProvider>
      </>
    );
  }
};

export default UserDetails;
