import React, { useState, useEffect } from "react";
import { Box, Typography, Card, CardContent, Link, Button } from "@mui/material";
import { makeStyles } from "@mui/styles";
import CircularProgress from '@mui/material/CircularProgress';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { sendEndpointRequest } from "../iop/iop";
import { EndorsementEndpoints } from "../api/Endpoints";
import { useNavigate } from "react-router-dom";

// import endorsementBackground from "../images/pexels-philip-kapadia-94537380-9302264.jpg";

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    padding: theme.spacing(4),
    minHeight: '100vh',
    minWidth: '100%',
    // backgroundImage: `url(${endorsementBackground})`,
    backgroundSize: 'cover',
    // backgroundPosition: 'center',
    zIndex: 1,
    overflow: 'hidden',
  },

  container: {
    padding: `${theme.spacing(2)} !important`,
    display: 'flex !important',
    flexDirection: 'column !important',
    gap: `${theme.spacing(2)} !important`,
  },
  centeredContainer: {
    display: 'flex !important',
    flexDirection: 'column !important',
    alignItems: 'center !important',
    justifyContent: 'center !important',
    minHeight: '200px !important',
    backgroundColor: '#f9f9f9 !important',
    borderRadius: '8px !important',
    padding: `${theme.spacing(3)} !important`,
    boxShadow: `${theme.shadows[1]} !important`,
    zIndex: 2,
  },
  largeMessageBox: {
    width: '60%',
    padding: theme.spacing(4),
    backgroundColor: '#fff',
    borderRadius: '8px',
    boxShadow: theme.shadows[3],
    textAlign: 'left',
    fontSize: '1.25rem',
    fontWeight: 'normal',
    margin: theme.spacing(4, 0),
    "& p": {
      fontSize: '1rem',
      fontWeight: 'normal',
      marginTop: theme.spacing(2),
      color: theme.palette.text.secondary,
      zIndex: 2,
    },
  },

  largeMessageLink: {
    fontWeight: 'bold',
    color: theme.palette.primary.main,
    marginTop: theme.spacing(2),
    textDecoration: 'underline',
    cursor: 'pointer',
    zIndex: 2,
  },
  box: {
    backgroundImage: 'linear-gradient(to right, #e6e8e8, #d1d4d4) !important',
    borderRadius: '8px !important',
    padding: `${theme.spacing(2)} !important`,
    boxShadow: `${theme.shadows[3]} !important`,
    border: `1px solid ${theme.palette.divider} !important`,
    display: 'flex !important',
    flexDirection: 'column !important',
    justifyContent: 'flex-start !important',
    transition: 'background-color 0.3s ease, border 0.3s ease !important',
    "&:hover": {
      backgroundImage: 'linear-gradient(to left, #e7b3f5, #d7bdde) !important',
      border: `4px solid ${theme.palette.divider} !important`,
    },
    zIndex: 2,
  },
  header: {
    fontSize: '4rem !important',
    fontWeight: 'bold !important',
    textAlign: 'left !important',
  },
  subheader: {
    fontSize: '1.1rem !important',
    color: '#707070',
    textAlign: 'left !important',
  },
  text: {
    fontSize: '1rem !important',
    textAlign: 'left !important',
    color: `${theme.palette.text.primary} !important`,
  },
  loadingText: {
    color: `${theme.palette.primary.main} !important`,
    marginBottom: `${theme.spacing(2)} !important`,
  },
  loadingIcon: {
    color: `${theme.palette.primary.main} !important`,
  },
  errorText: {
    color: `${theme.palette.error.main} !important`,
    marginBottom: `${theme.spacing(2)} !important`,
  },
  errorIcon: {
    color: `${theme.palette.error.main} !important`,
    fontSize: '2rem !important',
  },
  noDataContainer: {
    display: 'flex !important',
    flexDirection: 'column !important',
    alignItems: 'center !important',
    justifyContent: 'flex-end !important',
    height: '30vh !important',
  },
  noDataText: {
    color: `${theme.palette.text.secondary} !important`,
  },
  largeText: {
    fontSize: '2rem !important',
    textAlign: 'left !important',
    fontWeight: 'bold !important',
  },
  linkText: {
    color: `${theme.palette.primary.main} !important`,
    cursor: 'pointer !important',
    textDecoration: 'underline !important',
    marginTop: '1em',
  },
  
}));

const MyEndorsementsPage = () => {
  const classes = useStyles();
  const [receivedEndorsements, setReceivedEndorsements] = useState([]); /* All received endorsements */
  const [pendingEndorsements, setPendingEndorsements] = useState([]);   /* All pending endorsements */
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const navigate = useNavigate();

  const fetchEndorsements = async () => {
    try {
      const response = await sendEndpointRequest(EndorsementEndpoints.endpoints.getEndorsementsForUser, null, null, null);
  
      const receivedData = response?.data || [];
  
      /* Separate endorsements into received and pending */
      const pendingEndorsements = [];
      const receivedEndorsements = [];
  
      receivedData.forEach((endorsement) => {
        if (endorsement.endorsed) {
          receivedEndorsements.push(endorsement);
        } else {
          pendingEndorsements.push(endorsement);
        }
      });
  
      setReceivedEndorsements(receivedEndorsements);
      setPendingEndorsements(pendingEndorsements);
  
      setLoading(false);
    } catch (error) {
      setError(true);
      setLoading(false);
    }
  };
  

  useEffect(() => {
    fetchEndorsements();
  }, []);

  if (loading) {
    return (
      <Box className={classes.centeredContainer}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box className={classes.centeredContainer}>
        <Typography variant="h6" color="error">
          Failed to fetch endorsements. Please try again later.
        </Typography>
        <ErrorOutlineIcon className={classes.errorIcon} />
      </Box>
    );
  }

  if (receivedEndorsements.length === 0 && pendingEndorsements.length === 0) {
    return (
      <Box className={classes.root}>
      <div />
          <Typography className={classes.header}>My Endorsements</Typography>
          <Box className={classes.largeMessageBox}
              sx ={{
              }}
          >
            <Typography
                variant='h6'
                sx={{
                fontWeight: 'bold',
                textAlign: 'left',
                fontSize: '2em',
            }}>Endorsements you earn will be displayed here</Typography>
            <Typography className={classes.bodyText}>
              You can add content or evidence that highlights your expertise in specific skills. Once you've added content, you can request endorsements from others to validate your abilities. Endorsed content helps demonstrate your proficiency and builds your reputation in the community.
            </Typography>
            <Box mt={4}>
              <Button className={classes.linkText}
                onClick={() => {
                  navigate('/skills?tutorial=true');
                }}
              >
                Starting adding evidence for your skills here!
              </Button>
            </Box>
          </Box>
      </Box>
    );
  }

  return (
    <Box className={classes.root}>
      <div/>
      <Typography className={classes.header}>My Endorsements</Typography>
      <Typography className={classes.subheader} sx={{marginBottom: '1em'}}>See all endorsements both pending and received below</Typography>
      <Box className={classes.cardContainer}>
        {receivedEndorsements.length > 0 && (
          <>
          {receivedEndorsements.map((endorsement, index) => (
            <Card key={index} className={classes.card} sx={{ 
              backgroundColor: '#f9f9f9',
              boxShadow: 3,
              borderRadius: 2,
              marginBottom: 4,
              width: '40%'
            }}>
              <CardContent sx={{ textAlign: 'left'}}> 
                <Typography variant="h6" sx={{ fontWeight: 'bold' }}> 
                  {endorsement.firstName} {endorsement.lastName}
                </Typography>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1 }}>
                  <Typography variant="body2" sx={{ color: 'text.secondary', fontSize: '0.875rem' }}>
                    {endorsement.email}
                  </Typography>
                  <Typography variant="body2" sx={{ color: 'text.secondary', fontSize: '0.875rem', textAlign: 'right' }}>
                    {endorsement.phoneNumbeString}
                  </Typography>
                </Box>
                <Typography sx={{ textAlign: 'left' }}>
                  {endorsement.message}
                </Typography>
              </CardContent>
            </Card>
          ))}
          </>
        )}
        {/* Display pending endorsements */}
        {pendingEndorsements.length > 0 && (
          <>
          {pendingEndorsements.map((endorsement, index) => (
            <Card key={index} className={classes.card} sx={{ 
              backgroundColor: '#f9f9f9',
              boxShadow: 3,
              borderRadius: 2,
              marginBottom: 4,
              width: '40%',
              position: 'relative'
            }}>
              <Box 
                sx={{
                  position: 'absolute',
                  top: 8,
                  right: 8,
                  backgroundColor: '#f7cb3b',
                  padding: '4px 8px',
                  borderRadius: 2,
                  fontWeight: 'bold',
                  fontSize: '1rem',
                  color: '#333',
                  fontSize: '0.875rem',
                }}
              >
                PENDING
              </Box>
              <CardContent sx={{ textAlign: 'left'}}> 
                <Typography variant="h6" sx={{ fontWeight: 'bold' }}> 
                  {endorsement.firstName} {endorsement.lastName}
                </Typography>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1 }}>
                  <Typography variant="body2" sx={{ color: 'text.secondary', fontSize: '0.875rem' }}>
                    {endorsement.email}
                  </Typography>
                  <Typography variant="body2" sx={{ color: 'text.secondary', fontSize: '0.875rem', textAlign: 'right' }}>
                    {endorsement.phoneNumbeString}
                  </Typography>
                </Box>
                <Typography sx={{ textAlign: 'left', fontStyle: 'italic', color: '#B0B0B0' }}>
                  This endorsement is currently pending
                </Typography>
              </CardContent>
            </Card>
          ))}
          </>
        )}
      </Box>
    </Box>
  );
};

export default MyEndorsementsPage;
