import axios from 'axios';
import { headers } from './user';
import { OrganizationEndpoints } from './Endpoints';
import { sendEndpointRequest } from '../iop/iop';
import { ErrorType } from './APIErrors';

/**
 * Send a request to API to create new organization
 * 
 * @param {OrganizationDto} organization - The organization to create
 * @param {OrganizationConfigurationDto} configOptions - The configuration options for the organization
 * @param {string} outData - id to output to caller post creation. If null or undefined, it will just be ignored.
 * @returns {ErrorType} - The error type
 */
export async function  apiCreateNewOrg  (organization, configOptions, outData) {
    /* Pack up the data to send to the API */
    const data = {
        organization  : organization,
        configs       : configOptions
      };
    let endpoint = OrganizationEndpoints.endpoints.createOrganizationWithConfigurations;
    let response = await sendEndpointRequest(endpoint, null, data);

    return response;
  };

  export async function removeAUserFromOrg (link) {
    try {
      console.log({ headers: headers() }, { "url": link });
      const response = await axios.post(link, null, { headers: headers() });
      console.log("Remove A User/Admin from an org: ", response);
      return response.data;
    } catch (err) {
      console.error('Remove A User/Admin from an org failed:', err);
      throw err;
    }
  };

  export async function softDeleteOrg (link) {
    try {
      console.log({ headers: headers() }, { "url": link });
      const response = await axios.post(link, null, { headers: headers() });
      console.log("Soft Deleted an Org: ", response);
      return response.data;
    } catch (err) {
      console.error('Soft Deleted an Org failed:', err);
      throw err;
    }
  };

  export async function addUserBadgeFromOrgBadgeList (link) {
    try {
      console.log({ headers: headers() }, { "url": link });
      const response = await axios.post(link, null, { headers: headers() });
      console.log("Added a user badge API: ", response);
      return response.data;
    } catch (err) {
      console.error('FAILED to add a user badge API:', err);
      throw err;
    }
  };