import { sendEndpointRequest } from "../iop/iop";
import { SkillEndpoints } from "./Endpoints";

/**
 * Send an API request to publish evidence content.
 * 
 * @param {string} evidenceTitle 
 * @param {string} userEmail 
 * @param {string} skillTitle 
 * @returns {GResponseCommonObject} response
 */
export const SkillRequestPublishEvidence =  async (evidenceTitle, userEmail, skillTitle) => {
    let pathVars = {
        "user-name": userEmail,
        "skill-title": skillTitle,
        "content-title": evidenceTitle
    };
    return( await sendEndpointRequest(SkillEndpoints.endpoints.publishEvidenceContent, null, null, pathVars) );
};

/**
 * Send an API request to publish a skill.
 * 
 * @param {string} skillTitle 
 * @returns {GResponseCommonObject} response
 */
export const SkillRequestPublishSkill =  async (skillTitle) => {
    return( sendEndpointRequest(SkillEndpoints.endpoints.publishSkill, null, null, {"skill-title": skillTitle}) );
};
