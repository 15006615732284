import React, { useState, useContext } from "react";
import { useLocation } from "react-router-dom";
import { UserContext } from "../../provider/AppProvider";
import AlertMessage from "../utils/AlertMessage.js";
import ErrorSection from "../utils/ErrorsSection";
import {
  sendPassRecoveryEmail,
  authenticatedPasswordChange,
} from "../../api/password";
import { CssBaseline, Typography } from "@mui/material";
import useStyles from "../../styles/styles";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Box from "@mui/system/Box";


const AuthChangePass = () => {
  const classes = useStyles();
  const { state } = useLocation();
  const { url } = state;

  const [c_pass, setCPass] = useState("");
  const [new_pass0, setNewPass0] = useState("");
  const [new_pass1, setNewPass1] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const { setErrors, errors, exeLogout } = useContext(UserContext);

  const exeResetPassword = async (url, c_pass, new_pass0) => {
    setErrors(null);
    console.log("URL in exeResetPassword: ", url);
    authenticatedPasswordChange(url, c_pass, new_pass0)
      .then((response) => {
        console.log("Auth Pass Change initiated, ", response);
        alert(response);
        exeLogout();
      })
      .catch((error) => {
        console.error("Auth Pass Change failed, ", error);
        if (error.response.data.error == null) {
          setErrors(`Error Type: ${error.response.data.data.message ?? 'Unknown'}\n`)
        } else {
          setErrors(
            `Error Type: ${error.response.data.error.type ?? 'Unknown'}\n` +
            `Error Detail: ${error.response.data.error.detail ?? 'Unknown'}\n` +
            `Error Title: ${error.response.data.error.title ?? 'Unknown'}\n` +
            `Error Code: ${error.response.data.error.errorCode ?? 'Unknown'}`
          );
        }
      });
  };

  const submitChangePass = (e) => {
    e.preventDefault();

    if (c_pass == "" || new_pass0 == "" ||  new_pass1 == "") {
      setErrors("All fields required");
      return;
    }
    if (new_pass0 == new_pass1) {
      console.log("Passwords match, submitting the form, URL: ", url);
      exeResetPassword(url, c_pass, new_pass0);
    } else {
      setErrors("New Passwords do not match");
      return;
    }
  };

  const closeAlert = () => {
    setAlertMessage("");
  };

  return (
    <>
      <CssBaseline />
      <div className={classes.InputFormFlex}>
        <div>
          <Typography variant="h3" className={classes.FormCaption}>
            Change Password
          </Typography>
          <Grid container spacing={3} maxWidth={700}>
            <Grid item xs={12} sm={12}>
              <TextField
                required
                id="currentPassword"
                name="currentPassword"
                type="password"
                label="Current Password"
                value={c_pass}
                fullWidth
                variant="standard"
                onChange={(e) => setCPass(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                required
                id="newPassword"
                name="newPassword"
                type="password"
                label="New Password"
                value={new_pass0}
                fullWidth
                variant="standard"
                onChange={(e) => setNewPass0(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                required
                id="repeatNewPass"
                name="repeatNewPass"
                type="password"
                label="Repeat New Password"
                value={new_pass1}
                fullWidth
                variant="standard"
                onChange={(e) => setNewPass1(e.target.value)}
              />
            </Grid>
          </Grid>
          <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
          >
            <Box sx={{ width: "300px" }}>
              <Button
                className={classes.button}
                onClick={submitChangePass}
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Submit
              </Button>
            </Box>
          </Grid>

          <Grid container direction="column" alignItems="center">
            <Box sx={{ maxWidth: "550px" }}>
              <div>
                {errors && <ErrorSection errors={errors}></ErrorSection>}
              </div>
            </Box>
          </Grid>
        </div>
      </div>
    </>
  );
};

export default AuthChangePass;
