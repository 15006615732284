import { UserContext } from "../../../provider";
import React, { useState, useEffect, useContext } from "react";
import useStyles from "../../../styles/styles";
import { RegistrationEndpoints, UserEndpoints } from "../../../api/Endpoints";
import { sendEndpointRequestFile, sendEndpointRequest, sendEndpointRequestNoAuth } from "../../../iop/iop";
import { toast } from "react-toastify";
import EditIcon from "@mui/icons-material/Edit";
import { ErrorType } from "../../../api/APIErrors";
import { Navigate } from "react-router-dom";
import { handleCommonFileError } from "../../../api/APIErrorUtils";

const UserProfilePane = ({ username }) => {
  const { user } = useContext(UserContext);
  const classes = useStyles();
  const [image, setImage] = useState("https://via.placeholder.com/250");
  
  const [viewingOwnProfile, setViewingOwnProfile] = useState(false);
  const [userFirstName, setUserFirstName] = useState("");
  const [userLastName, setUserLastName] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [userTitle, setUserTitle] = useState("");
  const [notFound, setNotFound] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const { exeLogout } = useContext(UserContext);

  const [originalUser, setOriginalUser] = useState({
    firstName: "",
    lastName: "",
    email: "",
    title: ""
  });

  let updatedUser = {
    firstName: userFirstName,
    lastName: userLastName,
    title: userTitle,
    email: userEmail,
  };

  useEffect(() => {
    if (user && user.email === username) {
      setViewingOwnProfile(true);
    } else {
      setViewingOwnProfile(false);
    }
  }, [user, username]);

  useEffect(() => {
    const getUserData = async () => {
      const params = { username };
      try {
        const response = await sendEndpointRequestNoAuth(UserEndpoints.endpoints.getUserContext, params, null, null);
        if (response && response.data) {
          setUserFirstName(response.data.firstName);
          setUserLastName(response.data.lastName);
          setUserEmail(response.data.email);
          setUserTitle(response.data.userTitle || "No title yet");
          setImage(response.data.avatarUrl || "https://via.placeholder.com/250");

          setOriginalUser({
            firstName: response.data.firstName,
            lastName: response.data.lastName,
            email: response.data.email,
            title: response.data.title || ""
          });
        } else {
          setNotFound(true);
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
        toast.error("An error occurred while fetching user data.");
      }
    };

    getUserData();
  }, [username, user, viewingOwnProfile]);

  const handleEditProfile = () => {
    setIsEditing(!isEditing);
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    const formData = new FormData();
    formData.append("image", file);

    try {
      const response = await sendEndpointRequestFile(UserEndpoints.endpoints.uploadUserAvatar, null, formData);
      if (response.success) {
        toast.success("Image updated successfully.");
        setAvatarImage(file);
      } else {
        handleCommonFileError(response.error);
      }
    } catch (error) {
      console.error("Error uploading avatar:", error);
      toast.error("An error occurred while uploading the avatar.");
    }
  };

  const setAvatarImage = (file) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      setImage(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const handleEditAvatarClick = () => {
    document.getElementById("avatar-upload").click();
  };

  /**
   * Handles submission of user profile edits, comparing updated fields with original user data.
   * If there are changes, sends appropriate requests to update each modified field:
   * - First Name and Last Name
   * - Email
   * - Title
   * 
   * For each field, validates the response and displays relevant success or error messages.
   * On successful email update logs out the user.
   * 
   * @param {Event} event - The form submission event, which prevents the default form action.
   */
  const handleSubmitEdit = async (event) => {
    event.preventDefault();

    try {
      let updatedFields = [];

      if (userFirstName !== originalUser.firstName) {
        updatedFields.push({
          field: "firstName",
          value: userFirstName
        });
      }

      if (userLastName !== originalUser.lastName) {
        updatedFields.push({
          field: "lastName",
          value: userLastName
        });
      }

      if (userEmail !== originalUser.email) {
        updatedFields.push({
          field: "email",
          value: userEmail
        });
      }

      if (userTitle !== originalUser.title) {
        updatedFields.push({
          field: "userTitle",
          value: userTitle
        });
      }

      for (const { field, ___ } of updatedFields) {
        let params = {};
        if (field === "firstName" || field === "lastName") {
          params = {
            firstName: userFirstName,
            lastName: userLastName
          };
          let response = await sendEndpointRequest(UserEndpoints.endpoints.editUserName, params, null, null);
          if(response.success) {
            toast.success("Name was successfully updated");
          } 
          /* otherwise parse the error */
          else {
            switch(response?.error?.code) {
              case ErrorType.API_ERROR_INPUT_VALIDATION_NOT_NULL:
                toast.error("Hm. That input doesn't appear to be a name...");
                break;

              case ErrorType.API_ERROR_DATABASE_ENTITY_NOT_FOUND:
              case ErrorType.API_ERROR_USER_NOT_FOUND:
              default:
                  toast.error("An unknown error occurred. Please try again later.");
                  break;
            }
          }
        } else if (field === "email") {
          params = { email: userEmail };
          let response = await sendEndpointRequest(RegistrationEndpoints.endpoints.editUserEmail, params, null, null);

          /* Email request was successful, display a success message briefly and proceed
             to log the user out */
          if (response.success) {
            toast.success("Email was successfully updated");

            setTimeout(() => {
              exeLogout();
            }, 4000);

          }
          /* otherwise parse the error */
          else {
            switch(response?.error?.code) {
              case ErrorType.API_ERROR_INPUT_VALIDATION_INVALID_EMAIL:
                toast.error("Email not formatted correctly.");
                break;

              case ErrorType.API_ERROR_DATABASE_DUPLICATE_ENTITY: /* Don't let folks just know that the email already exists.... */
              case ErrorType.API_ERROR_USER_NOT_FOUND:
              default:
                  toast.error("An unknown error occurred. Please try again later.");
                  break;
            }
          }
        } else if (field === "userTitle") {
          params = { userTitle: userTitle };
          await sendEndpointRequest(UserEndpoints.endpoints.editUserTitle, params, null, null);
        }
      }

      setIsEditing(false);
    } catch (error) {
      console.error("Error updating user profile:", error);
      toast.error("An error occurred while updating the profile.");
    }
  };

  if (notFound) {
    return <Navigate to="/404" replace />;
  }

  return (
    <section>
      <div className={classes.profileSection}>
        <div className={classes.profileAvatarContainer}>
          <img src={image} alt="User Avatar" className={classes.profileAvatar} />
          {viewingOwnProfile && (
            <button className={classes.editAvatarButton} onClick={handleEditAvatarClick}>
              <EditIcon />
            </button>
          )}
        </div>
        {userFirstName && userLastName && (
          <>
            <h2 className={classes.profileName}>
              <span className={classes.profileFirstName}>{userFirstName}</span>
              <span className={classes.profileLastName}>{userLastName}</span>
            </h2>
            {/* Display the title if it exists; otherwise, show the placeholder text*/}
            {userTitle &&  userTitle !== "No title yet" ? (
                <p className={classes.profileTitle}>{userTitle}</p>
            ) : (
                <p className={classes.profileTitle} style={{fontStyle: 'italic'}}>
                  No title yet
                </p>
            )}
            {viewingOwnProfile && (
              <button className={classes.editProfileButton} onClick={handleEditProfile}>
                Edit Profile
              </button>
            )}
          </>
        )}
        <input
          id="avatar-upload"
          type="file"
          hidden
          onChange={handleFileChange}
          accept="image/*"
        />
    {isEditing && (
      <form onSubmit={handleSubmitEdit} className={classes.editProfileForm}>
        <input
          type="text"
          placeholder="First Name"
          value={userFirstName}
          onChange={(e) => setUserFirstName(e.target.value)}
          required
          className={classes.formInput}
        />
        <input
          type="text"
          placeholder="Last Name"
          value={userLastName}
          onChange={(e) => setUserLastName(e.target.value)}
          required
          className={classes.formInput}
        />
        <input
          type="text"
          placeholder="Title"
          value={userTitle}
          onChange={(e) => setUserTitle(e.target.value)}
          className={classes.formInput}
        />
        <p style={{marginTop: '2em', marginBottom: '-1em', color:'orange', fontWeight:'800'}}>Warning: Changing your email address will result in logging out the account and requiring email re-verification.</p>
        <input
          type="email"
          placeholder="Email"
          value={userEmail}
          onChange={(e) => setUserEmail(e.target.value)}
          required
          className={classes.formInput}
        />
        <button type="submit" className={classes.submitButton}>Submit</button>
      </form>
    )}
      </div>
    </section>
  );
};

export default UserProfilePane;
