import "../../styles/assets/css/main.css";

const ErrorSection = ({ errors }) => {
  return (
    <div className="errorFooter">
      <span>{errors}</span>
    </div>
  );
};

export default ErrorSection;
