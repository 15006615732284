import React, { useState, useContext, useEffect } from "react";
import { UserContext, DashboardContext } from "../../provider";
import ErrorSection from "../utils/ErrorsSection";
import "../../styles/component.css";
import { CssBaseline, Typography } from "@mui/material";
import useStyles from "../../styles/styles";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Box from "@mui/system/Box";
import MenuItem from "@mui/material/MenuItem";
import OutlinedInput from "@mui/material/OutlinedInput";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import { toast } from "react-toastify";
import { sendEndpointRequest } from "../../iop/iop";
import { sendEndpointRequestFile } from "../../iop/iop";

import { BadgeEndpoints } from "../../api/Endpoints";
import { ErrorType } from "../../api/APIErrors";

const AddBadge = () => {
  const classes = useStyles();

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [minEndorsements, setMinEndorsements] = useState("");
  const [file, setFile] = useState(null);
  const [image, setImage] = useState(null);
  const { errors } = useContext(UserContext);
  const { loadBadges } = useContext(DashboardContext);

  const resetState = () => {
    setTitle("");
    setDescription("");
    setMinEndorsements("");
  };

  /**
   * Set up and ship requests to create the badge for either
   * the image or no image endpoint.
   */
  const handleCreateBadgeButonPressed = async () => {

    /* local vars */
    let response          /* Genic's response object                         */
    let formData = null;  /* Badge form data                                 */ 
    let endpoint = null;  /* endpoint to use (file vs no file)               */
    let txProc = null;    /* transmission procedure to use (file vs no file) */
    let msg = null;       /* success message to display to user              */

    /* If a file was uploaded, then use the image endpoint, otherwise 
     * use the no file endpoint.
     */
    if (file) {
      formData = new FormData();
      formData.append("imageFile", file);
      endpoint = BadgeEndpoints.endpoints.createNewBadge;
      txProc = sendEndpointRequestFile;
      msg = "Badge created successfully with image.";
    } else {
      endpoint = BadgeEndpoints.endpoints.createNewBadgeNoFile;
      txProc = sendEndpointRequest;
      msg = "Badge created successfully without image.";
    }
  
    const data = {
      description: description,
      minimumEndorsements: minEndorsements,
      title: title,

    };

    const queryParams = `badgeDetails=${encodeURIComponent(
      JSON.stringify(data)
    )}`;

    /* Send the request and check for errors */

    response = await txProc(endpoint, queryParams, formData);

    if(!response.success) {
      processErrors(response);
    } else {
      resetState();
      loadBadges();

      toast.success(msg);
    }

  };

  /**
   * Process expected badge creation errors from the API.
   */
  function processErrors(response) {
          /* Handle all errors */
          switch (response.error.code) {
            case ErrorType.ERR_DATABASE_DUPLICATE_ENTITY:
              toast.error("A badge with this name already exists.");
              break;

           case ErrorType.ERR_FILE_FILE_SIZE_EXCEEDED:
              toast.error("File size exceeded.");
              break;

           case ErrorType.ERR_FILE_UNSUPPORTED_FILE_TYPE:
              toast.error("File type not allowed.");
              break;

           case ErrorType.ERR_FILE_FILE_UPLOAD_FAILED:
              toast.error("File upload failed.");
              break;

            case ErrorType.ERR_INPUT_VALIDATION_INVALID_INPUT:
              toast.error("Ensure all fields are filled out.");

            default:
              toast.error("Unknown error occured.");
              break;
          }
  }

  const handleChange = (e) => {
    if (e.target.files.length === 0) {
      console.log("no file chosen");
      return;
    }
    setFile(e.target.files[0]);
    const image = e.target.files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      setImage(reader.result);
    };
    if (image) {
      reader.readAsDataURL(image);
    }
  };

  const removeFile = () => {
    setFile(null);
    setImage(null);
  };

  return (
    <>
      <CssBaseline />
      <div className={classes.InputFormFlex}>
        <div>
          <Typography variant="h3" className={classes.FormCaption}>
            Create New Badge
          </Typography>
          <Grid container spacing={3} maxWidth={700}>
            <Grid item xs={12} sm={12}>
              <TextField
                required
                id="title"
                name="title"
                label="Title"
                value={title}
                fullWidth
                variant="standard"
                onChange={(e) => setTitle(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                required
                id="description"
                name="description"
                label="Description"
                value={description}
                fullWidth
                variant="standard"
                onChange={(e) => setDescription(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <FormControl style={{ minWidth: 300 }}>
                <Select
                  id="minEndorsements"
                  value={minEndorsements}
                  label="minEndorsements"
                  width="100px"
                  displayEmpty
                  input={<OutlinedInput />}
                  onChange={(e) => setMinEndorsements(e.target.value)}
                >
                  <MenuItem disabled value="">
                    <em>Minimum Endorsements</em>
                  </MenuItem>
                  {[...Array(10).keys()].map((number) => (
                    <MenuItem key={number + 1} value={number + 1}>
                      {number + 1}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
          >
            <Box sx={{ width: "300px" }}>
              <Button
                variant="outlined"
                className={classes.button}
                sx={{ mt: 3, mb: 2 }}
                fullWidth
                component="label"
              >
                Upload File
                <input type="file" hidden onChange={handleChange} accept=".jpg, .jpeg, .png" />
              </Button>
            </Box>
          </Grid>
          <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
          >
            <Box sx={{ width: "300px" }}>
              <Button
                className={classes.button}
                onClick={() => handleCreateBadgeButonPressed()}
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Create Badge
              </Button>
            </Box>
          </Grid>

          <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
          >
            <Box sx={{ width: "300px" }}>
              {image && (
                <img
                  src={image}
                  alt="Badge Image"
                  style={{
                    width: "100%",
                    height: "auto",
                    alignContent: "center",
                  }}
                />
              )}
            </Box>
          </Grid>

          {file && (
            <Grid
              container
              spacing={0}
              direction="column"
              alignItems="center"
              justifyContent="center"
            >
              <Box sx={{ width: "300px" }}>
                <Button
                  variant="outlined"
                  className={classes.button}
                  sx={{ mt: 3, mb: 2 }}
                  fullWidth
                  component="label"
                  onClick={removeFile}
                  color="error"
                >
                  Remove File
                </Button>
              </Box>
            </Grid>
          )}

          <Grid container direction="column" alignItems="center">
            <Box sx={{ maxWidth: "550px" }}>
              <div>
                {errors && <ErrorSection errors={errors}></ErrorSection>}
              </div>
            </Box>
          </Grid>
        </div>
      </div>
    </>
  );
};

export default AddBadge;
