// src/App.js
import React, { useContext } from "react";
import Routes from "./Routes";
import ErrorBoundary from "./ErrorBoundary";
import { AppProvider, DashboardProvider, useAppProvider } from "./provider";
import { BrowserRouter } from "react-router-dom";
import { CssBaseline } from "@mui/material";
import useStyles from "./styles/styles";
import { ToastContainer, toast } from "react-toastify";

export default function App() {
  const classes = useStyles();
  const { loading, user } = useAppProvider();

  return (
    <>
      <CssBaseline />
      <div className={classes.App}>
        <BrowserRouter>
          <ErrorBoundary>
            <AppProvider>
              <DashboardProvider>
                <Routes user={user} />
              </DashboardProvider>
            </AppProvider>
          </ErrorBoundary>
        </BrowserRouter>
      </div>
      <ToastContainer />
    </>
  );
}
