import React from "react";
import Page from "./Page";

//Page which passes in props to main Page Component based on Non-Auth behavior
const NoAuthPage = (props) => {
  return (
    <>
      <Page showNav={false} content={props.content} />
    </>
  );
};

export default NoAuthPage;
