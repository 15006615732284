import React, { useState } from 'react';
import CreateSkill from './OwnerCreateSkill';
import SkillCards from './SkillCards';

const OwnerSkillManager = () => {  
  const [skill, setSkill] = useState(null);
  const [refreshKey, setRefreshKey] = useState(0); // Key to trigger refresh

  // Function to trigger refresh
  const triggerRefresh = () => {
    setRefreshKey((prevKey) => prevKey + 1);
    setSkill(null);
    };

    return (
        <div className="editor-wrapper">
            <div className="heading-container" style={{textAlign: 'center', marginTop: '100px', marginRight: '830px',}}>
                <h1 style={{
                    fontSize: '4rem', fontWeight: '900', color: 'black',}}>
                    Skills</h1>
                <p style={{fontSize: '15px', fontWeight: 'lighter', color: '#555', marginTop: '-5px',
                    marginLeft: '35px', minWidth: '10em'
                }}>Select a skill to add evidence to</p>
            </div>
            <div className="skills-container" >
                <div className="create-skill" style={{
                    display: 'flex',
                    alignItems: 'flex-start',
                    marginLeft: '-40px',
                    marginTop: '-25px',
                    width: '100%',
                }}>
                    <CreateSkill
                        setSkill={(newSkill) => {
                            setSkill(newSkill);
                        }}
                    />
                </div>
            </div>
            <div className="skills-cards-container" style={{width: '100%', marginTop: '-55px'}}>

        {/* This is an authorized owner page only. If that ever changes need to re-evaluate
            the SkillCards parameters */}
          <SkillCards setSkill={setSkill} authorized={true} refreshKey={refreshKey} isOwner={true} /> {}
            </div>
        </div>
    );
};

export default OwnerSkillManager;
