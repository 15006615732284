import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { config } from '../../Constants';

// Define the functional component
const CreateSkill = ({ setSkill }) => {
    const navigate = useNavigate();
    const [inputValue, setInputValue] = useState('');

    // State to track if the modal is open or closed
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModalButtonHovered, setModalButtonHovered] = useState(false);

    const handleInputChange = (event) => {
        setInputValue(event.target.value);
    };

    const getToken = () => {
        return localStorage.getItem('token');
    };

    const handleButtonClick = async () => {
        try {
            const apiUrl = `${config.url.API_BASE_URL}/api/skill/create-skill`;

            const response = await fetch(apiUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${getToken()}`,
                },
                body: JSON.stringify({ title: inputValue }),
            });

            const result = await response.json();
            console.log('API Response:', result);

            // Check for errors in the result
            if (result.success === false) {
                throw new Error(result.error.detail); // Create a new Error object
            }

            // Pass the skill data back to the parent component
            setSkill(result.data); // Assume result.data contains the created skill
            setInputValue(''); // Clear the input field after submission

            alert(`New Skill Created! ${JSON.stringify(result.data)}`);
            closeModal(); // Close modal after submission
            navigate(`/skill-manager/${inputValue}`);
        } catch (error) {
            console.error('Error during API call:', error);
            alert('An error occurred while creating the skill. Please try again.');
        }
    };

    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setInputValue(''); // Optionally reset the input field when closing the modal
    };

    return (
        <div style={{ position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '20px' }}>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', marginRight: '20px', marginTop: '-24px' }}>
                
                {/* Button to open the modal */}
                <div style={{ position: 'relative' }}>
                    <button
                        id="createSkillButton"
                        style={{
                            backgroundColor: 'white',
                            color: 'black',
                            padding: '0',
                            borderRadius: '50%',
                            fontSize: '20px',
                            border: '3.5px solid black',
                            width: '50px',
                            height: '50px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            cursor: 'pointer',
                            transition: 'background-color 0.3s, transform 0.2s, box-shadow 0.3s',
                            boxShadow: isModalButtonHovered ? '0 4px 10px rgba(0, 0, 0, 0.2)' : 'none',
                            transform: isModalButtonHovered ? 'scale(1.1)' : 'scale(1)',
                        }}
                        onMouseEnter={() => setModalButtonHovered(true) }
                        onMouseLeave={() => setModalButtonHovered(false)}
                        onClick={openModal}
                    >
                        <span style={{ color: 'black', fontSize: '30px', lineHeight: '1' }}>+</span>
                    </button>

                    {/* Tooltip thingy */}
                    {isModalButtonHovered && (
                        <div style={{
                            position: 'absolute',
                            bottom: window.innerWidth < 1510 ? '-5px' : '60px', // Positions the tooltip near the center of the button when space is limited
                            left: '50%',
                            transform: window.innerWidth < 1510 ? 'translateX(30px)' : 'translateX(-50%)', // Adjust tooltip to the right when space is limited
                            backgroundColor: 'black',
                            color: 'white',
                            padding: '5px 10px',
                            borderRadius: '5px',
                            whiteSpace: 'nowrap',
                            zIndex: 1000,
                        }}>
                            Create New Skill
                        </div>
                    )}
                </div>

            </div>

            {/* Modal */}
            {isModalOpen && (
                <div style={{
                    position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    display: 'flex', alignItems: 'center', justifyContent: 'center', zIndex: 999,
                }}>
                    <div style={{
                        backgroundColor: 'white', padding: '20px', borderRadius: '8px', width: '400px', maxWidth: '90%',
                    }}>
                        <h2>Create New Skill</h2>
                        <label htmlFor="textInput" style={{ display: 'block', marginBottom: '5px', fontWeight: 'bold' }}>
                            Enter New Skill Title:
                        </label>
                        <input
                            type="text"
                            id="textInput"
                            value={inputValue}
                            onChange={handleInputChange}
                            style={{
                                width: '100%', padding: '8px', marginBottom: '5px', borderRadius: '5px',
                                border: '1px solid #ccc',
                            }}
                        />
                        <button
                            onClick={handleButtonClick}
                            style={{
                                width: '100%', padding: '10px', color: 'white', border: 'none', borderRadius: '5px',
                                backgroundColor: inputValue.trim() ? '#590D82' : '#D1A6E6',
                                cursor: inputValue.trim() ? 'pointer' : 'not-allowed',
                                opacity: inputValue.trim() ? 1 : 0.6,
                            }}
                            disabled={!inputValue.trim()}
                        >
                            Submit
                        </button>
                        <button onClick={closeModal} style={{ marginTop: '10px' }}>Cancel</button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default CreateSkill;
