import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { sendEndpointRequest } from "../../iop/iop";
import { SkillEndpoints } from "../../api/Endpoints";
import { Box, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { ErrorType } from "../../api/APIErrors";
import { toast } from "react-toastify";

const useStyles = makeStyles((theme) => ({
  noDataContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginTop: '5vh'
  },
  noDataBubble: {
    position: 'relative',
    backgroundColor: '#9c27b0',
    padding: theme.spacing(2),
    borderRadius: '8px',
    textAlign: 'center',
    boxShadow: theme.shadows[3],
    margin: theme.spacing(2),
    "&:hover": {
      backgroundImage: 'linear-gradient(to left, #e7b3f5, #d7bdde)',

    },
  },
  noDataText: {
    color: theme.palette.text.secondary,
  }
}));

// Define the functional component
const CreateEvidence = ({ skillTitle, isStartCreating, authorized }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [evidenceTitle, setEvidenceTitle] = useState("");
  const [inputValue, setInputValue] = useState('');

  // State to track if the modal is open or closed
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalButtonHovered, setModalButtonHovered] = useState(false);

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
    setEvidenceTitle(event.target.value);
  };

  const handleCreateSkillEvidence = async () => {
    try {
      console.log("SKILLTITLE: ", skillTitle, "DESCRIPTOR: ", evidenceTitle);

      const dto = {
        skillTitle: skillTitle,
        contentTitle: evidenceTitle
      };

      const response = await sendEndpointRequest(SkillEndpoints.endpoints.createNewEvidence, null, dto, null);

      if (response?.success === false) {
        handleCreateSkillEvidenceCreationErrors(response);
      } else {
        /* Navigate the user to the editor for the newly created evidence */
        closeModal();
        navigate(`/edit-evidence/${skillTitle}/${evidenceTitle}`);
      }

    /* Handle local errors */
    } catch (error) {
      console.log("ERROR RETRIEVING USER SKILL DESCRIPTORS: ", error);
      toast.error("Failed to create new skill evidence. Please try again later.");
    }
  };

  const handleCreateSkillEvidenceCreationErrors = (response) => {
    switch(response?.error?.code) {
      case ErrorType.ERR_INPUT_VALIDATION_INVALID_INPUT:
        toast.error('Invalid input. Please try again.');
        break;
  
      case ErrorType.ERR_DATABASE_ENTITY_NOT_FOUND:
        toast.error('This skill does not exist or has changed names. Please refresh the page.');
        break;
  
      case ErrorType.ERR_RELATION_DUPLICATE_MEMBER:
        toast.error('Whoops, it looks like this title already exists. Please choose a different name.');
        break;
  
      default:
        toast.error('An error occurred while creating the skill evidence. Please try again later.');
        break; 
    }
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setInputValue('');
  };

  return (
    <div style={{ position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '20px' }}>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', marginRight: '20px', marginTop: '-24px' }}>

        {/* Button to open the modal */}

        <div style={{ position: 'relative' }}>
          {authorized && <div>
            {isStartCreating ?
              (<Box className={classes.noDataBubble} onClick={openModal}>
                <Typography sx={{ color: 'white', fontWeight: 'bold' }} variant="h6" className={classes.noDataText}>
                  Start creating content
                </Typography>
              </Box>
              ):
            (
            <button
              id="createSkillButton"
              style={{
                backgroundColor: 'white',
                color: 'black',
                padding: '0',
                borderRadius: '50%',
                fontSize: '20px',
                border: '3.5px solid black',
                width: '50px',
                height: '50px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                cursor: 'pointer',
                transition: 'background-color 0.3s, transform 0.2s, box-shadow 0.3s',
                boxShadow: isModalButtonHovered ? '0 4px 10px rgba(0, 0, 0, 0.2)' : 'none',
                transform: isModalButtonHovered ? 'scale(1.1)' : 'scale(1)',
              }}
              onMouseEnter={() => setModalButtonHovered(true)}
              onMouseLeave={() => setModalButtonHovered(false)}
              onClick={openModal}
            >
              <span style={{ color: 'black', fontSize: '30px', lineHeight: '1' }}>+</span>
            </button>
              )}
          </div>}

          {/* Tooltip thingy */}
          {isModalButtonHovered && (
            <div style={{
              backgroundColor: 'black',
              color: 'white',
              padding: '5px 10px',
              borderRadius: '5px',
              whiteSpace: 'nowrap',
              zIndex: 1000,
            }}>
              Create New Skill
            </div>
          )}
        </div>

      </div>

      {/* Modal */}
      {isModalOpen && (
        <div style={{
          position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)',
          display: 'flex', alignItems: 'center', justifyContent: 'center', zIndex: 999,
        }}>
          <div style={{
            backgroundColor: 'white', padding: '20px', borderRadius: '8px', width: '400px', maxWidth: '90%',
          }}>
            <h2>Add a catchy title</h2>
            <h4>Don't worry, you can change it later</h4>
            <input
              type="text"
              id="textInput"
              value={inputValue}
              onChange={handleInputChange}
              style={{
                width: '100%', padding: '8px', marginBottom: '5px', borderRadius: '5px',
                border: '1px solid #ccc',
              }}
            />
            <button
              onClick={handleCreateSkillEvidence}
              style={{
                marginBottom: '10px',
                width: '100%', padding: '10px', color: 'white', border: 'none', borderRadius: '5px',
                backgroundColor: inputValue.trim() ? '#590D82' : '#D1A6E6',
                cursor: inputValue.trim() ? 'pointer' : 'not-allowed',
                opacity: inputValue.trim() ? 1 : 0.6,
              }}
              disabled={!inputValue.trim()}
            >
              Submit
            </button>
            <button 
              onClick={closeModal} 
              style={{
                width: '100%',
                padding: '10px',
                color: 'black',
                border: '1px solid #ddd',
                borderRadius: '5px',
                backgroundColor: 'transparent',
                transition: 'background-color 0.3s ease',
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default CreateEvidence;