import React, { useState, useContext } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { UserContext } from "../../provider";
import useStyles from "../../styles/styles";
import { ErrorType } from "../../api/APIErrors";
import { VerifyEmailPage } from "../pages/VerifyEmailPage"

// TODO PMG "remember me" button
// import FormControlLabel from "@mui/material/FormControlLabel";
// import Checkbox from "@mui/material/Checkbox";

const PageState = {
  SIGN_IN: 0,
  VERIFY_EMAIL: 1,
};

/**
 * Application Sign-In Page.
 * Renders the login interface and updates the display based on API login responses.
 * On successful login, the user is redirected to their main landing page.
 */
export default function SignIn() {
  const classes = useStyles();
  const [pageState, setPageState] = useState(PageState.SIGN_IN);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [errorText, setErrorText] = useState("");
  const { handleLogin } = useContext(UserContext);

  const submitLogin = async (e) => {
    e.preventDefault();

    let error = await handleLogin(username, password);
    if( error != ErrorType.ERR_NO_ERR ) {
      handleLoginError(error);
    }
  };

  /**
   * Handle login error. Determines the message that is displayed to the end
   * user or the action that should occur when a login error occurs.
   *
   * NOTE: we should be careful to not get too detailed with these errors.
   *       Email needing verification is a special case because the user
   *       was able to authenticate successfully but their account must
   *       first be enabled before they can use it.
   *
   * @param {APIError} login error
   */
  const handleLoginError = (error) => {
    switch(error) {

      /* If the user has been soft-deleted show this message */
      case ErrorType.ERR_USER_DELETED:
        setErrorText("Could not login at this time. Please contact an administrator.");
        break;

      /* Not enabled means user is unverified */
      case ErrorType.ERR_USER_NOT_ENABLED:
        return setPageState(PageState.VERIFY_EMAIL);
        break;

      /* Unknown case (including possible auth error) show 
         a generic error message */
      default:
        setErrorText("Failed Login.")
        break;
    }
  }

  return (
    <>
      {pageState == PageState.VERIFY_EMAIL && <VerifyEmailPage email={username} header={"Please verify your email"}/>}

      {pageState == PageState.SIGN_IN && <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "#590D82" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <Box
            component="form"
            onSubmit={submitLogin}
            noValidate
            sx={{ mt: 1 }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              onChange={(e) => setUsername(e.target.value)}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              onChange={(e) => setPassword(e.target.value)}
            />
            {/* TODO PMG "remember me" button */}
            {/* <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            /> */}
            { (errorText !== "") && (
              <Typography sx={{ color: "red" }}>{errorText}</Typography>
            )}
            <Button
              className={classes.button}
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Sign In
            </Button>
            <Grid container>
              <Grid item xs>
                <Link href="/recover" variant="body2">
                  Forgot password?
                </Link>
              </Grid>
              <Grid item>
                <Link href="/register" variant="body2">
                  {"Don't have an account? Sign Up"}
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Typography
          variant="body2"
          color="text.secondary"
          align="center"
          sx={{ mt: 8, mb: 4 }}
          >
          {"Copyright © Skills Log "}
          {new Date().getFullYear()}
          {"."}
        </Typography>
      </Container>}
    </>
  );
}
