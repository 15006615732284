import React, { useContext } from "react";
import { UserContext, DashboardContext } from "../../provider";
import { getRequest } from "../../api/helper";
import useStyles from "../../styles/styles";
import theme from "../../styles/theme";
import { ThemeProvider } from "@mui/material/styles";
import { CssBaseline, Typography, Paper, Box, Button } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import BadgeImage from "../BadgeImage";

export default function UserBadges() {
  const classes = useStyles();
  const { board } = useContext(DashboardContext);
  const { navigate } = useContext(UserContext);

  const navigateToEventCreationComponent = (link, title, description) => {
    if (!link) return;
    navigate("/create-event", {
      state: {
        url: link,
        title: title,
        description: description,
        eventId: null,
      },
    });
  };

  const navigateToEventsOfBadgeComponent = (link, title, description) => {
    if (!link) return;
    navigate("/view-events", {
      state: {
        url: link,
        title: title,
        description: description,
      },
    });
  };

  return (
    <>
      <ThemeProvider theme={theme}>
      <Typography>
          not seeing any? try reloading the page!
        </Typography>
        <CssBaseline />
        <Typography className={classes.TableTitle} variant="h3">
          User Badges 
        </Typography>
        <Table stickyHeader size="small">
          <TableBody>
            {board?.dash?.UserBadges?.map((userBadge) => (
              <Paper
                sx={{
                  p: 2,
                  m: 4,
                  boxShadow: 5,
                }}
              >
                <TableRow key={userBadge?.badgeDetails?.title}>
                  <TableCell style={{ borderBottom: "none" }}>
                    <BadgeImage key={userBadge.email} badge={userBadge} />
                  </TableCell>
                  <TableCell style={{ borderBottom: "none" }}>
                    <Box>
                      <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                        {userBadge?.badgeDetails?.title}
                      </Typography>
                      <Typography variant="h6">
                        {userBadge?.badgeDetails?.description}
                      </Typography>
                      <Typography variant="h6">
                        Minimum Endorsements:{" "}
                        {userBadge?.badgeDetails?.minimumEndorsements}
                      </Typography>
                      <Typography
                        variant="h6"
                        sx={{
                          color:
                            userBadge?.status === "EARNED"
                              ? "green"
                              : userBadge?.status === "IN_PROGRESS"
                              ? "orange"
                              : "black",
                        }}
                      >
                        {userBadge?.status}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell style={{ borderBottom: "none" }}>
                    {Object.keys(userBadge._links || {}).map((linkKey) => (
                      <Button
                        className={classes.button}
                        onClick={() => {
                          if (
                            userBadge._links[linkKey]?.href.includes(
                              "event/add"
                            )
                          ) {
                            navigateToEventCreationComponent(
                              userBadge._links[linkKey]?.href,
                              userBadge?.badgeDetails?.title,
                              userBadge?.badgeDetails?.description
                            );
                          } else if (
                            userBadge._links[linkKey]?.href.includes(
                              "event/all-for-user"
                            )
                          ) {
                            navigateToEventsOfBadgeComponent(
                              userBadge._links[linkKey]?.href,
                              userBadge?.badgeDetails?.title,
                              userBadge?.badgeDetails?.description
                            );
                          } else {
                            getRequest(userBadge._links[linkKey]?.href);
                          }
                        }}
                        variant="contained"
                        sx={{ m: 1 }}
                      >
                        {linkKey.replace(/\/\/\//g, " ")}
                      </Button>
                    ))}
                  </TableCell>
                </TableRow>
              </Paper>
            ))}
          </TableBody>
        </Table>
      </ThemeProvider>
    </>
  );
}
