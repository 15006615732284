import SkillCards from './SkillCards';
import React, { useEffect, useState, useContext} from 'react';
import { UserContext } from "../../provider";

/***
 * Skill View Page
 */
const SkillViewPage = () => {

  const PageMode = {
    AUTHORIZED: 'AUTHORIZED',
    UNAUTHORIZED: 'UNAUTHORIZED',
  }
  const { user } = useContext(UserContext);
  const [pageMode, setPageMode] = useState(PageMode.UNAUTHORIZED);
  const [userEmail, setUserEmail] = useState(null);

  useEffect(() => {
    if(user) {
      setPageMode(PageMode.AUTHORIZED);
      setUserEmail(user.email);
    }
  });

  return (
    <div className="editor-wrapper">
      <div className="heading-container" style={{ textAlign: 'center', marginTop: '100px', marginRight: '830px', }}>
        <h1 style={{
          fontSize: '4rem', fontWeight: '900', color: 'black',
        }}>
          Skills</h1> 
        <p style={{
          fontSize: '15px', fontWeight: 'lighter', color: '#555', marginTop: '-5px',
          marginLeft: '35px', textAlign: 'left'
        }}>Select a skill to add evidence to</p>
      </div>
      <div className="skills-cards-container" style={{ width: '100%'}}>
        <SkillCards authorized={pageMode === PageMode.AUTHORIZED}  isOwner={false} userEmail={userEmail}/> { }
      </div>
     </div>
  );
};

export default SkillViewPage;