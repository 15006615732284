import axios from 'axios';
import { headers } from './user';
import { config } from '../Constants';
    
export const retrieveDash = async () => {
    try {
        const response = await axios.get(`${config.url.API_BASE_URL}/api/dashboard/get-dash`, {headers:headers()})
        return response.data;
    } catch(error) {
        console.log("Error loading" , error)
        throw error;
    }
}