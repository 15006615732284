import { useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2
import theme from "../styles/theme";
import { ThemeProvider } from "@mui/material/styles";

const EndorsementBadgeTile = (props) => {
  const [endorsement, setEndorsement] = useState(props.endorsement);
  const [isHiddenEndorsementsPage, setIsHiddenEndorsementPage] = useState(
    props.isHiddenEndorsementsPage
  );

  return (
    <>
      <ThemeProvider theme={theme}>
        <Grid xs={12} sm={8} md={6} lg={4} xl={3} item my={2}>
          <Box
            bgcolor={
              isHiddenEndorsementsPage ? "inactive.main" : "success.light"
            }
            p={3}
            sx={{
              border: 1,
              borderColor: "primary.main",
              borderRadius: "16px",
            }}
          >
            <Typography textAlign={"left"} variant="h6" gutterBottom>
              <strong>Name: </strong> {endorsement.firstName}{" "}
              {endorsement.lastName}
            </Typography>
            <Typography textAlign={"left"} variant="h6" gutterBottom>
              <strong>Email: </strong> {endorsement.email}
            </Typography>
            <Typography textAlign={"left"} variant="h6" gutterBottom>
              <strong>Phone Number: </strong> {endorsement.phoneNumber}
            </Typography>
            <Typography textAlign={"left"} variant="h6" gutterBottom>
              <strong>Received On: </strong> {endorsement.updatedAt}
            </Typography>
            <Typography textAlign={"left"} variant="h6" gutterBottom>
              <strong>Endorsement: </strong> {endorsement.endorsementBody}
            </Typography>
            {endorsement.links &&
              endorsement.links.map((link) => (
                <Button
                  variant="text"
                  color="primary"
                  key={link.id}
                  onClick={() => {
                    console.log("Clicked on button:", link.rel);
                    props.executeButtonLink(link);
                  }}
                >
                  {isHiddenEndorsementsPage ? "Unhide" : "Hide"} endorsement
                </Button>
              ))}
          </Box>
        </Grid>
      </ThemeProvider>
    </>
  );
};
export default EndorsementBadgeTile;
