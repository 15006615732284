import { createTheme } from "@mui/material/styles";

const bgGray = "#e9e9e9";
const genicsPurple = "#590D82";
const genicsPurpleFade = "#0d02215b";
const genicsPurpleLowOpacity = "#0d022111";
const genicsDarkBlue = "#0f084b";
const genicsLightBlue = "#a6cfd5";
const genicsGreen = "#c2e7d9";
const genicsGreenLowOpacity = "#c2e7d97c";
const sideNavWidth = 220;

const theme = createTheme({
  palette: {
    primary: {
      main: genicsPurple,
      light: genicsLightBlue,
      dark: genicsDarkBlue,
      contrastText: "#fff",
    },
    secondary: {
      main: genicsPurple,
      light: genicsPurpleFade,
      dark: genicsPurple,
      contrastText: "#fff",
    },
    success: {
      main: genicsGreen,
      light: genicsGreenLowOpacity,
      dark: genicsGreen,
      contrastText: "#fff",
    },
    inactive: {
      main: "#e9e9e9",
      light: "#e9e9e9",
      dark: "#e9e9e9",
      contrastText: "$fff",
    },
  },
  typography: {
    fontFamily: "Roboto, sans-serif",
  },
  // other customizations...
});

export default theme;
