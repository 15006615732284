
/**
 * 
 * Check if all elements in the array are of the given type.
 * 
 * @param {Array} array 
 * @param {Type} type 
 * @returns 
 */
export function containsObjectsOfType(array, type) {
    for (let obj of array) {
        if (!(obj instanceof type)) {
            return false;
        }
    }
    return true;
}
