/**
 * Module providing an instance of Axios with interceptors.
 * @module AxiosInstance
 * @see {@link https://github.com/axios/axios|Axios}
 */

import axios from 'axios';
import { config } from '../Constants';
import { isGResponse } from '../iop/iop';

/**
 * Axios interceptor to manipulate response data.
 * @param {Object} response - The response object.
 * @returns {Object|Array} The modified response data.
 */
axios.interceptors.response.use(
    response => {
        /* If the response matches the expected format, return the data property.
         * This ensures I/O is backwards compatible with older versions of the API */
        if (isGResponse(response) && !axios.useGResponse) {
            response.data = response.data.data;
            return response;

        /* If the response matches the expected format and axios.useGResponse is true, */
        } else if (isGResponse(response) && axios.useGResponse) {
            return response.data;

        /* Otherwise, return the response as axios intended.
         */
        } else {
            return response;
        }
    },
);

/**
 * Sets the base URL for all axios requests.
 * @type {string}
 * @default
 */
axios.defaults.baseURL = config.url.API_BASE_URL;

export default axios;
