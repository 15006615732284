/**
 * Utils.js
 * 
 * Common utilities.
 *
 */

/**
 * Sleep for a given number of milliseconds.
 * 
 * @param {int} ms 
 * @returns 
 */
export const sleep = (ms) => new Promise(resolve => setTimeout(resolve, ms));
