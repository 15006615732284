import { useState, useEffect } from "react";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2
import EndorsementBadgeTile from "./EndorsementBadgeTile";
import { Box, Typography } from "@mui/material";
import BadgeImage from "./BadgeImage";

const EndorsementBadge = (props) => {
  const [badge, setBadge] = useState(props.badgeDetails);
  const [badgeEndorsements, setBadgeEndorsements] = useState(
    props.endorsements
  );
  const [isHiddenEndorsementsPage, setIsHiddenEndorsementPage] = useState(
    props.hiddenEndorsementsPage
  );

  useEffect(() => {
    setBadge(props.badgeDetails);
    setBadgeEndorsements(props.endorsements);
  }, [props.badgeDetails, props.endorsements]);

  return (
    <Box
      bgcolor={
        isHiddenEndorsementsPage ||
        badgeEndorsements.length < badge.minimumEndorsements
          ? "inactive.main"
          : "success.light"
      }
      sx={{
        border: 1,
        borderRadius: "16px",
        padding: 2,
        margin: 2,
      }}
    >
      <Box>
        {isHiddenEndorsementsPage ? (
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography variant="h3" style={{ margin: "auto" }}>
              {badge.title}
            </Typography>
            <Box>
              <BadgeImage badge={badge} />
            </Box>
          </Box>
        ) : (
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography variant="h3" style={{ margin: "auto" }}>
              {badge.title} {badgeEndorsements.length} /{" "}
              {badge.minimumEndorsements}
            </Typography>
            <Box>
              <BadgeImage badge={badge} />
            </Box>
          </Box>
        )}
        <Typography variant="h4"> {badge.description}</Typography>
      </Box>
      <Grid container spacing={2}>
        {badgeEndorsements.map((e) => (
          <EndorsementBadgeTile
            key={e.id}
            endorsement={e}
            executeButtonLink={props.executeButtonLink}
            isHiddenEndorsementsPage={isHiddenEndorsementsPage}
          ></EndorsementBadgeTile>
        ))}
      </Grid>
    </Box>
  );
};
export default EndorsementBadge;
