import React, { useContext } from "react";
import { DashboardContext, UserContext } from "../../provider";
import { useOrgLinks } from "../../action/dashboardActions";
import useStyles from "../../styles/styles";
import theme from "../../styles/theme";
import { ThemeProvider } from "@mui/material/styles";
import { Box, Button, CssBaseline, Typography, styled } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

export default function Admin() {
  const classes = useStyles();
  const { board } = useContext(DashboardContext);
  const { handleExecuteLink } = useOrgLinks();
  const { user } = useContext(UserContext);

  const StyledTableRow = styled(TableRow)`
    &:nth-of-type(odd) {
      background-color: ${({ theme }) =>
        theme.palette.action.hover}; // accessing the theme
    }
    &:nth-of-type(even) {
      background-color: "grey";
    }
  `;

  return (
    <>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {user.roles.includes("ROLE_OWNER") && (
          <Box>
            <TableCell>
              <Button
                variant="contained"
                onClick={() =>
                  handleExecuteLink(
                    board._links["Unaffiliated///Role///Admins"].href
                  )
                }
              >
                Unaffiliated Role Admins
              </Button>
            </TableCell>
            <TableCell>
              <Button
                variant="contained"
                onClick={() =>
                  handleExecuteLink(board._links["Create///New///Admin"].href)
                }
              >
                Create New Admin
              </Button>
            </TableCell>
          </Box>
        )}
        <Typography className={classes.TableTitle} variant="h3">
          Admins
        </Typography>
        <Table stickyHeader size="small">
          {board.dash.Admins?.length === 0 ? (
            <TableHead>
              <TableRow>
                <TableCell>
                  <Typography variant="h6">
                    No active admins currently exist.
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="h6"></Typography>
                </TableCell>
              </TableRow>
            </TableHead>
          ) : (
            <>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Typography variant="h6">Email</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="h6">Actions</Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {board.dash.Admins?.map((admin) => (
                  <StyledTableRow key={admin.email}>
                    <TableCell>{admin.email}</TableCell>
                    <TableCell>
                      {Object.keys(admin._links || {}).map((linkKey) => (
                        <Button
                          className={classes.button}
                          onClick={() =>
                            handleExecuteLink(admin._links[linkKey]?.href)
                          }
                          variant="contained"
                          sx={{ m: 1 }}
                        >
                          {linkKey.replace(/\/\/\//g, " ")}
                        </Button>
                      ))}
                    </TableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </>
          )}
        </Table>
      </ThemeProvider>
    </>
  );
}
