import React, { useContext } from "react";
import { DashboardContext, UserContext } from "../../provider";
import { useOrgLinks } from "../../action/dashboardActions";
import useStyles from "../../styles/styles";
import theme from "../../styles/theme";
import { ThemeProvider } from "@mui/material/styles";
import { Button, Box, CssBaseline, Typography, Paper } from "@mui/material";
import Grid from "@mui/material/Grid";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";

export default function Organizations() {
  const classes = useStyles();
  const { board } = useContext(DashboardContext);
  const { handleExecuteLink } = useOrgLinks();
  const { user } = useContext(UserContext);

  // Determine header text based on board and dash
  let headerText;
  if (!board || !board.dash || !board.dash.Organizations) {
    headerText = "Something went wrong.";
  } else if (board.dash.Organizations.length === 0) {
    headerText = "You don't have any organizations.";
  } else {
    headerText = "Organizations";
  }

  return (
    <>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {user.roles.includes("ROLE_OWNER") && (
          <Box>
            <TableCell>
              <Button
                variant="contained"
                onClick={() =>
                  handleExecuteLink(board._links["Create///Organization"].href)
                }
              >
                Create New Organization
              </Button>
            </TableCell>
          </Box>
        )}
        <Typography className={classes.TableTitle} variant="h3">
          {headerText}
        </Typography>
        {!board || !board.dash || !board.dash.Organizations ? (
          <TableRow>
            <TableCell colSpan={2}>
              <Typography variant="h6">
                Please contact the system administrator.
              </Typography>
            </TableCell>
          </TableRow>
        ) : board.dash.Organizations.length === 0 ? (
          <Paper
            sx={{
              p: 2,
              m: 4,
              boxShadow: 5,
            }}
          >
            <Typography variant="h6">
              To join a new organization, please contact the system
              administrator.
            </Typography>
          </Paper>
        ) : (
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              {board.dash.Organizations.map((organization) => (
                <Paper
                  sx={{
                    p: 2,
                    m: 2,
                    boxShadow: 5,
                    minWidth: 350,
                  }}
                >
                  <TableRow key={organization.name}>
                    <TableCell style={{ borderBottom: "none", minWidth: 200 }}>
                      <Box>
                        <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                          {organization.name}
                        </Typography>
                        <Typography variant="h6">
                          {organization?.domain}
                        </Typography>
                        <Typography variant="h6">
                          {organization?.description}
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell style={{ borderBottom: "none" }}>
                      {Object.keys(organization._links || {}).map((linkKey) => (
                        <Button
                          className={classes.button}
                          onClick={() =>
                            handleExecuteLink(
                              organization._links[linkKey]?.href
                            )
                          }
                          variant="contained"
                          sx={{ m: 1 }}
                        >
                          {linkKey.replace(/\/\/\//g, " ")}
                        </Button>
                      ))}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{ borderBottom: "none" }}>
                      <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                        Admins
                      </Typography>
                    </TableCell>
                    <TableCell style={{ borderBottom: "none" }}></TableCell>
                  </TableRow>
                  {organization.admins.length === 0 ? (
                    <TableRow>
                      <TableCell style={{ borderBottom: "none" }}>
                        <Typography variant="h6">No Admins</Typography>
                      </TableCell>
                      <TableCell style={{ borderBottom: "none" }}></TableCell>
                    </TableRow>
                  ) : (
                    organization.admins.map((admin) => (
                      <TableRow key={organization.name}>
                        <TableCell style={{ borderBottom: "none" }}>
                          <Typography variant="h6">{admin.email}</Typography>
                        </TableCell>
                        <TableCell style={{ borderBottom: "none" }}>
                          {Object.keys(admin._links || {}).map((linkKey) => (
                            <Button
                              className={classes.button}
                              onClick={() =>
                                handleExecuteLink(
                                  organization._links[linkKey]?.href
                                )
                              }
                              variant="contained"
                              sx={{ m: 1 }}
                            >
                              {linkKey.replace(/\/\/\//g, " ")}
                            </Button>
                          ))}
                        </TableCell>
                      </TableRow>
                    ))
                  )}
                </Paper>
              ))}
            </Grid>
          </Grid>
        )}
      </ThemeProvider>
    </>
  );
}
