import React, { useState, useContext, useEffect } from "react";
import { UserContext, DashboardContext } from "../../provider";
import { headers } from "../../api/user";
import { useLocation, Link } from "react-router-dom";
import ErrorSection from "../utils/ErrorsSection";
import axios from "axios";
import EndorsementRequestPopup from "../popups/EndorsementRequestPopup";
import { Button, Box, CssBaseline, Typography, Paper } from "@mui/material";
import useStyles from "../../styles/styles";
import theme from "../../styles/theme";
import { ThemeProvider } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";

const ViewBadgeEvents = () => {
  const classes = useStyles();
  const { state } = useLocation();
  const { url, title, description } = state;

  const [eventList, setEventList] = useState([]);
  const { setErrors, errors, navigate } = useContext(UserContext);
  const [selectedEvent, setSelectedEvent] = useState({});
  const [showPopup, setShowPopup] = useState(false); // State to control the visibility of the endorsement request popup
  const [
    showDeleteEventConfirmationPopup,
    setShowDeleteEventConfirmationPopup,
  ] = useState(false); // State to control the visibility of the confirmation popup

  const loadAllEvents = async () => {
    console.log("Load Events URL: ", url);
    try {
      const response = await axios.get(url, { headers: headers() });
      console.log("loadAllEvents Response: ", response.data);
      setEventList(response.data);
    } catch (error) {
      console.error("ERROR loadAllEvents Response: ", error);
      setErrors(errors);
    }
  };

  useEffect(() => {
    loadAllEvents(url);
  }, [url]);

  const openEndorsementRequestPopup = (link, eventTitle) => {
    setSelectedEvent({ link, eventTitle });
    setShowPopup(true);
  };

  const navigateToAllEndorsementsOfAnEvent = (link, eventTitle) => {
    navigate("/all-endorsements-for-badge", {
      state: {
        url: link,
        eventTitle: eventTitle,
      },
    });
  };

  const handleDeleteEvent = (link) => {
    setSelectedEvent({ link });
    setShowDeleteEventConfirmationPopup(true);
  };

  const confirmDeleteEvent = async () => {
    try {
      // Execute the delete event link.href
      const response = await axios.delete(selectedEvent.link.href, {
        headers: headers(),
      });
      // Reload the events after deletion
      console.log(response);
      loadAllEvents();
    } catch (error) {
      console.error("Error deleting event: ", error);
      setErrors(errors);
    } finally {
      // Close the confirmation popup
      setShowDeleteEventConfirmationPopup(false);
    }
  };

  const cancelDeleteEvent = () => {
    // Close the confirmation popup
    setShowDeleteEventConfirmationPopup(false);
  };

  const navigateToEditEvent = (event) => {
    if (!event) return;

    navigate("/create-event", {
      // TODO PMG - update to '/edit-event'
      state: {
        url: url, // TODO PMG - should not pass in url, the parameter for badge id should be handled differently
        badgeTitle: title,
        badgeDescription: description,
        eventId: event.id,
        existingEventTitle: event.title,
        existingEventDate: event.eventDate,
        existingEventDescription: event.description,
      },
    });
  };

  return (
    <>
      <CssBaseline />
      <Typography variant="h6" style={{ textAlign: "left" }}>
        <Link to={"/mybadges"} style={{ marginRight: "20px" }}>
          Back to Badges
        </Link>
      </Typography>

      <Typography className={classes.TableTitle} variant="h3">
        All events for badge "{title}"
      </Typography>

      {showPopup && (
        <EndorsementRequestPopup
          link={selectedEvent.link}
          title={selectedEvent.eventTitle}
          onClose={() => setShowPopup(false)}
        />
      )}

      {showDeleteEventConfirmationPopup && (
        <Paper
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            zIndex: 999,
            backgroundColor: "white",
          }}
        >
          <Box sx={{ m: 2 }}>
            <Typography variant="h5" sx={{ fontWeight: "bold", color: 'red'}}>
              Are you sure you want to delete this event? We cannot recover this
              action!
            </Typography>
            <Button
              className={classes.button}
              variant="contained"
              sx={{ m: 1 }}
              onClick={confirmDeleteEvent}
            >
              Yes
            </Button>
            <Button
              className={classes.button}
              variant="contained"
              sx={{ m: 1 }}
              onClick={cancelDeleteEvent}
            >
              No
            </Button>
          </Box>
        </Paper>
      )}

      {eventList.length === 0 ? (
        <>
          <Paper
            sx={{
              p: 2,
              m: 4,
              boxShadow: 5,
            }}
          >
            <Typography variant="h4" sx={{ fontWeight: "bold" }}>
              No events for this badge
            </Typography>
          </Paper>
        </>
      ) : (
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12}>
            {eventList.map((event) => (
              <Paper
                sx={{
                  p: 2,
                  m: 4,
                  boxShadow: 5,
                }}
              >
                <Table stickyHeader size="small">
                  <TableBody>
                    <TableRow>
                      <TableCell
                        style={{ borderBottom: "none", maxWidth: 200 }}
                      >
                        <Box>
                          <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                            {event?.title}
                          </Typography>
                          <Typography variant="h6">
                            {event?.description}
                          </Typography>
                          <Typography variant="h6">
                            {event?.eventDate}
                          </Typography>
                        </Box>
                      </TableCell>
                      <TableCell style={{ borderBottom: "none" }}>
                        {event.links && (
                          <>
                            {event.links.map((link, index) => (
                              <Box key={index}>
                                <Button
                                  className={classes.button}
                                  variant="contained"
                                  key={index}
                                  sx={{ m: 1 }}
                                  onClick={() => {
                                    if (
                                      link.rel.includes("Request///Endorsement")
                                    ) {
                                      console.log("inside of: ", link.rel);
                                      openEndorsementRequestPopup(
                                        link,
                                        event?.title
                                      );
                                    } else if (
                                      link.rel.includes(
                                        "Get///All///Endorsements"
                                      )
                                    ) {
                                      console.log("inside of: ", link.rel);
                                      navigateToAllEndorsementsOfAnEvent(
                                        link,
                                        event?.title
                                      );
                                    } else if (
                                      link.rel.includes("Delete///Event")
                                    ) {
                                      console.log("inside of: ", link.rel);
                                      handleDeleteEvent(link);
                                    } else {
                                      console.log("Clicked on: ", link.rel);
                                    }
                                  }}
                                >
                                  {link.rel.replace(/\/\/\//g, " ")}
                                </Button>
                              </Box>
                            ))}
                            <Button
                              className={classes.button}
                              variant="contained"
                              sx={{ m: 1 }}
                              onClick={() => navigateToEditEvent(event)}
                            >
                              Edit
                            </Button>
                          </>
                        )}
                      </TableCell>
                    </TableRow>
                    <TableCell style={{ borderBottom: "none" }}></TableCell>
                    {errors && (
                      <TableRow>
                        <TableCell style={{ borderBottom: "none" }}>
                          <Box sx={{ maxWidth: "550px" }}>
                            <ErrorSection errors={errors}></ErrorSection>
                          </Box>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </Paper>
            ))}
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default ViewBadgeEvents;
