import AppProvider, { UserContext, useProvider } from "./AppProvider";
import DashboardProvider, { DashboardContext } from "./DashboardProvider";

const combineComponents = (components) => {
  return components.reduce(
    (AccumulatedComponents, CurrentComponent) => {
      return ({ children }) => {
        return (
          <AccumulatedComponents>
            <CurrentComponent>{children}</CurrentComponent>
          </AccumulatedComponents>
        );
      };
    },
    ({ children }) => <>{children}</>
  );
};
const useAppProvider = useProvider;

export {
  AppProvider,
  UserContext,
  useAppProvider,
  DashboardContext,
  DashboardProvider,
};
export default combineComponents([AppProvider, DashboardProvider]);
