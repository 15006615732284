import React, { useState, useContext, useEffect } from "react";
import { UserContext } from "../../provider/AppProvider";
import ErrorSection from "../utils/ErrorsSection";
import axios from "axios";
import { CssBaseline, Typography } from "@mui/material";
import useStyles from "../../styles/styles";

const AddOrgAdmin = () => {
  const classes = useStyles();
  const [adminEmail, setSelectedAdmin] = useState("");
  const [adminList] = useState([]);
  const { errors } = useContext(UserContext);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchOrganizations = async () => {
      const headers = {
        Accept: "*/*",
        "Content-Type": "application/json",
      };

      try {
        setLoading(false);
      } catch (err) {
        console.error("Loading Organizations failed:", err);
        setLoading(false);
      }
    };

    fetchOrganizations();
  }, []);

  const submitAddAdmin = () => {};

  return (
    <>
      <CssBaseline />
      <div className="inputFormFlex">
        <div className="inputForm">
          <Typography variant="h2" className="formCaption">
            Add Admin To Organization:{" "}
          </Typography>
          <div className="labelAndSelect">
            <label className="selectLabel">Select Admin:</label>
            <select
              className="select"
              value={adminEmail}
              onChange={(e) => setSelectedAdmin(e.target.value)}
            >
              <option value="">Select an organization</option>
              {loading ? (
                <option>Loading admin list...</option>
              ) : (
                adminList.map((org, index) => (
                  <option key={index} value={org}>
                    {org}
                  </option>
                ))
              )}
            </select>
          </div>
          <button className="button" onClick={submitAddAdmin}>
            Add Admin
          </button>
          <div>{errors && <ErrorSection errors={errors}></ErrorSection>}</div>
        </div>
      </div>
    </>
  );
};

export default AddOrgAdmin;
