import React, { useContext, useState } from "react";
import { UserContext } from "../../provider/AppProvider";
import { sendPassRecoveryEmail } from "../../api/password";
import { CssBaseline, Typography } from "@mui/material";
import useStyles from "../../styles/styles";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Box from "@mui/system/Box";

const Recover = () => {
  const classes = useStyles();
  const { navigate, setErrors } = useContext(UserContext);
  const [email, setEmail] = useState("");

  const submitRecover = (e) => {
    e.preventDefault();
    sendPassRecoveryEmail(email)
      .then((response) => {
        console.log("Recovery Initiated:", response);
        setErrors(null);
      })
      .catch((error) => {
        console.error("Recovery failed:", error.response.data);
        setErrors(error);
      });
    navigate("/recover-message");
  };

  return (
    <>
      <CssBaseline />
      <div className={classes.InputFormFlex}>
        <div>
          <Typography variant="h3" className={classes.FormCaption}>
            Recover Account
          </Typography>
          <Grid container spacing={3} maxWidth={700}>
            <Grid item xs={12} sm={12}>
              <TextField
                required
                id="email"
                name="email"
                label="Email"
                value={email}
                fullWidth
                variant="standard"
                onChange={(e) => setEmail(e.target.value)}
              />
            </Grid>
          </Grid>
          <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
          >
            <Box sx={{ width: "300px" }}>
              <Button
                className={classes.button}
                onClick={submitRecover}
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Send Email Recovery
              </Button>
            </Box>
          </Grid>
        </div>
      </div>
    </>
  );
};

export default Recover;
