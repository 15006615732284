import UserSelectSkillDescriptors from './UserSelectSkillDescriptors';
import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import EvidenceCards from './EvidenceCards';
import { UserContext } from "../../provider";

/**
 * Page to display information about all of a user's evidence for a specific skill
 *
 * This page is publcally accessible and does not require authentication
 *
 */
const UserSkillEvidenceManager = () => {
  const { skillTitle } = useParams();
  const { userEmail } = useParams();
  const { user } = useContext(UserContext);
  const authorized = user && user.email === userEmail;

  const navigate = useNavigate();

  return (
    <div>
      <div className="heading-container" style={{ textAlign: 'start', marginTop: '100px', marginLeft: '10%',}}>
        <h1 style={{fontSize: '4rem', fontWeight: '900', color: 'black',}}>
          {skillTitle}
        </h1>
        { authorized && <p style={{fontSize: '18px', fontWeight: 'lighter', color: '#555'
        }}>
          Add content that showcases you have this skill
        </p> }
        { !authorized && <p style={{fontSize: '18px', fontWeight: 'lighter', color: '#555'
        }}>
          See the below content that showcases this user has this skill
        </p> }
      </div>
      <div className="skills-container" >
        <div className="skills-cards-container" style={{ width: '100%' }}>
        <div className="create-skill" style={{
          display: 'flex',
          alignItems: 'flex-start',
          marginLeft: '15%',
          width: '100%',
        }}>
          
        </div>
          <EvidenceCards userEmail={userEmail} skillTitle={skillTitle} isOwner={false} /> { }
        </div>
      </div>
   </div>
  );
};

export default UserSkillEvidenceManager;