const prod = {
  url: {
    // API_BASE_URL: 'http://54.146.202.172',
    API_BASE_URL: "https://api.skills-log.com",
    UI_BASE_URL: "https://app.skills-log.com",
  },
};

const dev = {
  url: {
    API_BASE_URL: "http://localhost:8080",
    UI_BASE_URL: "http://localhost:3000",
  },
};

export const config = process.env.NODE_ENV === "production" ? prod : dev;
