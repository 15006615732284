import { getRequest } from "../api/helper";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { DashboardContext } from "../provider";
export const useOrgLinks = () => {
  const navigate = useNavigate();
  const { board } = useContext(DashboardContext);

  const {
    exeSoftDeleteUser,
    exeRemoveAUserFromOrg,
    exeSoftDeleteOrg,
    addUserBadge,
    loadUserBadges,
  } = useContext(DashboardContext);
  const handleExecuteLink = (link) => {
    if (!link) return;

    if (link.includes("authenticated-pass")) {
      console.log("Handling Reset Password link");
      navigate("/auth-change-pass", { state: { url: link } });
    } else if (link.includes("createAdmin")) {
      console.log("Handling Create Admin link");
      navigate("/create-admin", { state: { url: link } });
    } else if (link.includes("soft-delete-user")) {
      console.log("Handling soft delete link: ", link);
      exeSoftDeleteUser(link);
    } else if (link.includes("organization/create")) {
      console.log("Handling create org link: ", link);
      navigate("/create-org", { state: { url: link } });
    } else if (link.includes("organization/removeAdmin")) {
      console.log("Handling remove admin link: ", link);
      exeRemoveAUserFromOrg(link);
    } else if (link.includes("organization/remove-user")) {
      console.log("Handling remove user link: ", link);
      exeRemoveAUserFromOrg(link);
    } else if (link.includes("organization/delete-org")) {
      console.log("Handling soft delete org link: ", link);

      /* Refresh the page */
      window.location.reload();
      exeSoftDeleteOrg(link);
    } else if (link.includes("organization/addAdmin")) {
      console.log("Handling Add Admin to Org Link: ", link);
      // Extract organization name from the board data
      const orgName = board?.dash?.Organizations.find(
        (org) => org._links["organization/addAdmin"].href === link
      )?.name;
      navigate("/org-add-admin", {
        state: { organizationName: orgName, url: link },
      });
    } else if (link.includes("owner/no-org-users")) {
      console.log("Handling redirect to No Org Users: ", link);
      navigate("/no-org-users", { state: { url: link } });
    } else if (link.includes("owner/no-org-admins")) {
      console.log("Handling redirect to No Org Admins: ", link);
      navigate("/no-org-admins", { state: { url: link } });
    } else if (link.includes("badge/upload")) {
      console.log("Handling redirect to Add a new Badge: ", link);
      navigate("/add-badge", { state: { url: link } });
    } else if (link.includes("associate-badge")) {
      console.log("Handling redirect to Add Badge To Organization: ", link);
      navigate("/add-badge-to-org", { state: { url: link } });
    } else if (link.includes("owner/all-unverified")) {
      console.log("Handling unverified accounts: ", link);
      navigate("/all-unverified-users", { state: { url: link } });
    } else if (link.includes("badge/createUserBadge")) {
      console.log(
        "Handling create user badge then navigate to user badges: ",
        link
      );
      addUserBadge(link);
      loadUserBadges();
      navigate("/mybadges");
    } else if (link.includes("endorsement/not-hidden")) {
      console.log("Handling Get All Not Hidden Endorsement Report: ", link);
      // addNotHiddenEndorsements(link); we can set up state later for optimizations
      navigate("/endorsements-not-hidden", { state: { url: link } });
    } else if (link.includes("endorsement/hidden")) {
      console.log("Handling Get All Hidden Endorsements: ", link);
      // addHiddenEndorsements(link); we can set up state later for optimizations
      navigate("/endorsements-hidden", { state: { url: link } });
    } else if (link.includes("endorsement/report")) {
      navigate("/endorsements-report", { state: { url: link } });
    }else if (link.includes("organization/detail")) {
      navigate("/org-details", { state: { url: link } });
    } else if (link.includes("user/id")) {
      navigate("/user-details", { state: { url: link } });
    } else {
      console.log("Link: ", link);
      // Handle other links

      getRequest(link)
        .then((response) => {
          console.log("Link execution successful:", response);
        })
        .catch((error) => {
          console.error("Failed to execute link:", error);
        });
    }
  };
  return { handleExecuteLink };
};
