
export class EndpointType {
    constructor(rootPath, endpoints) {
        this.rootPath = rootPath;
        this.endpoints = endpoints;
    }
    static create(rootPath, endpoints) {
        return new EndpointType(rootPath, endpoints);
    }
}

export class Endpoint {
    constructor(subpath, requestMethod) {
        this.subpath = subpath;
        this.requestMethod = requestMethod;
    }

    static create(subpath, requestMethod) {
        return new Endpoint(subpath, requestMethod);
    }
}
