import React, { createContext, useContext, useEffect, useState } from "react";
import { getCurrentUser, login } from "../api/user";
import { Loading } from "../components";
import { useNavigate } from "react-router-dom";
import { ErrorType } from '../api/APIErrors';

export const UserContext = createContext({});

export default function AppProvider({ children }) {
  const [user, setUser] = useState(undefined);
  const [debug, setDebug] = useState(true);
  const [errors, setErrors] = useState();
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const loadUser = async () => {
    try {
      await getCurrentUser().then((data) => setUser(data));
    } catch (ex) {
      localStorage.clear();
    }
    setLoading(false);
  };

  useEffect(() => {
    loadUser();
  }, []);

  const handleLogin = async (username, password) => {
    const error = await login(username, password)

    if(error == ErrorType.ERR_NO_ERR) {
      getCurrentUser().then((data) => {
        setUser(data);
        setErrors(null);
        navigate(`/profile-landing/${data.email}`);
      });
    }

    return error;
  };

  const exeLogout = () => {
    localStorage.clear();
    setUser(null);
    setErrors(null);
    navigate("/");
  };

  return (
    <UserContext.Provider
      value={{
        user,
        updateUser: (user) => setUser(user),
        toggleDebug: () => setDebug(!debug),
        debug,
        setErrors,
        handleLogin,
        exeLogout,
        errors,
        navigate,
      }}
    >
      {loading && <Loading />}
      {!loading && children}
    </UserContext.Provider>
  );
}

export const useProvider = () => useContext(UserContext);
