import React from "react";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/material";
import BadgeImage from "./BadgeImage";

const BadgeListItem = ({ badge }) => {

  const StyledTableRow = styled(TableRow)`
    &:nth-of-type(odd) {
      background-color: ${({ theme }) =>
        theme.palette.action.hover}; // accessing the theme
    }
    &:nth-of-type(even) {
      background-color: "grey";
    }
  `;

  return (
    <StyledTableRow>
      <TableCell>{badge.title}</TableCell>
      <TableCell>{badge.description}</TableCell>
      <TableCell>{badge.minimumEndorsements}</TableCell>
      <TableCell>
        <BadgeImage key={badge.email} badge={badge} />
      </TableCell>
    </StyledTableRow>
  );
};
export default BadgeListItem;
