import React, { useState, useContext, useEffect } from "react";
import { UserContext, DashboardContext } from "../provider";
import { getRequest } from "../api/helper";
import { headers } from "../api/user";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { CssBaseline, Typography, styled } from "@mui/material";
import { BadgeEndpoints } from "../api/Endpoints";

const BadgeImage = ({ badge, size }) => {
  const [badgeItem, setBadgeItem] = useState(badge);
  const { setErrors, errors, navigate } = useContext(UserContext);
  const [url, setUrl] = useState(null);
  const [image, setImage] = useState(null);
  const [imageError, setImageError] = useState(false);

  const StyledTableRow = styled(TableRow)`
    &:nth-of-type(odd) {
      background-color: ${({ theme }) =>
        theme.palette.action.hover}; // accessing the theme
    }
    &:nth-of-type(even) {
      background-color: "grey";
    }
  `;

  const badgeHeight = size ? size : "100px";

  const handleImageError = () => {
    setImageError(true);
  };

  useEffect(() => {
    const fetchBadgeImage = async (id) => {
      try {
        let endpoint = BadgeEndpoints.endpoints.getBadge;
        let requestUrl = endpoint["subpath"].toString();
        requestUrl += `?id=${id}`;
        const imageUrl = await getRequest(requestUrl);
        setUrl(imageUrl);
      } catch (error) {
        console.error("Error fetching badge image URL: ", error);
        setErrors(error.response);
      }
    };
    fetchBadgeImage(badgeItem.id);
  }, []);

  return (
    <>
      {!imageError ? (
        <img
          src={url}
          alt="Badge Image"
          style={{ maxWidth: "100%", maxHeight: badgeHeight }}
          onError={handleImageError}
        ></img>
      ) : (
        <img
          src={require("../images/GenicsLogoBlueGradient.png")}
          alt="Badge Image"
          style={{ maxWidth: "100%", maxHeight: badgeHeight }}
        ></img>
      )}
    </>
  );
};
export default BadgeImage;
