import { useState, useRef } from "react";
import React, { useContext, useEffect } from "react";
import { UserContext, DashboardContext } from "../../provider";
import { links } from "./navbar_data";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import { retrieveDash } from "../../api/dashboard";
import { headers } from "../../api/user";
import { useOrgLinks } from "../../action/dashboardActions";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { AppBar, Box, Toolbar, IconButton, Tabs, Tab } from "@mui/material";
import NavbarImage from "./NavbarImage";
import { useNavigate } from "react-router-dom";

const Navbar = () => {
  const [value, setValue] = useState(null);
  const [showLinks, setShowLinks] = useState(false);
  const linksContainerRef = useRef(null);
  const linksRef = useRef(null);
  const { user } = useContext(UserContext);
  const { board, updateBoard } = useContext(DashboardContext);
  const { exeLogout } = useContext(UserContext);
  const { handleExecuteLink } = useOrgLinks();
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();
  const [currentUrl, setCurrentUrl] = useState("");

  const toggle = () => {
    setShowLinks(!showLinks);
  };

  const linkStyles = {
    height: showLinks
      ? `${linksRef.current.getBoundingClientRect().height}px`
      : "0px",
  };

  useEffect(() => {
    if (!board || Object.values(board._links).length === 0) {
      loadDashboard();
    }
  }, [board, updateBoard]);

  useEffect(() => {
    // Set the initial tab based on the current URL path
    const currentPath = window.location.pathname;
    const matchingTab = links.find((link) => link.url === currentPath);
    if (matchingTab) {
      setValue(matchingTab.id);
    } else if (currentPath === `/profile-landing/${user?.email}`) {
      setValue(0);
    } else {
      setValue(null);
    }
  }, []);

  const loadDashboard = async () => {
    try {
      const data = await retrieveDash({
        headers: headers(),
      });
      updateBoard(data);
    } catch (error) {
      console.error("Failed to load dashboard:", error);
    }
  };

  const handleLogout = () => {
    exeLogout();
  };

  const handleSignIn = () => {
    navigate("/login");
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const navigateToHome = () => {
    navigate(`/profile-landing/${user?.email}`);
    
  };

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
    const selectedLink = links.find((link) => link.id === newValue);
    if (selectedLink) {
      navigate(selectedLink.url);
    }
  };

  return (
    <AppBar position="static" sx={{ backgroundColor: "white" }}>
      <Box displayPrint="none">
        <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box // Box for the skills-log-logo image
              sx={{
                display: "inline-block",
                overflow: "hidden",
                width: "125px", // Adjust the length
                height: "108px", // Adjust the height
                transform: "translate(-34px, 22px)" // Adjust the position of the image
              }}
          >
            <NavbarImage />
          </Box>

          <div className="navbarTabs">
            <Tabs
              indicatorColor="secondary"
              textColor="inherit"
              value={value === null ? false : value}
              onChange={handleTabChange}
              sx={{
                "& .MuiTabs-indicator": {
                  display: value !== null ? "block" : "none", // Conditionally hide the indicator if `value` is undefined
                },
              }}
            >
              {/* Render the home button if user is logged in */}
              {user && <Tab
                label='HOME'
                onClick={navigateToHome}
                sx={{
                  color: "black",
                  fontWeight: "bold",
                  "&:hover": {
                    color: "lightgray",
                  },
                }}
              />}
              {/* Load other menu items based on the role */}
              {links.map((link) => {
                const { id, text, roles } = link;
                return (
                  (!roles || JSON.stringify(roles).includes(user?.roles)) && (
                    <Tab
                      key={id}
                      label={text}
                      value={id}
                      sx={{
                        color: "black",
                        fontWeight: "bold",
                        "&:hover": {
                          color: "lightgray",
                        },
                      }}
                    />
                  )
                );
              })}
            </Tabs>
          </div>

          <IconButton
            size="large"
            edge="end"
            color="inherit"
            aria-label="menu"
            sx={{ color: "black" }}
            onClick={handleClick}
          >
            <MenuIcon />
          </IconButton>

          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            sx={{
              "& .MuiPaper-root": {
                position: "fixed",
                top: 0,
                right: 0,
                height: "100%",
                width: "300px",
                transform: anchorEl ? "translateX(0)" : "translateX(100%)",
                transition: "transform 0.4s ease-in-out",
                boxShadow: "none",
                border: "1px solid lightgray",
              },
            }}
          >
            {/* Separate container for the close button */}
            <Box
              sx={{
                position: "absolute",
                top: 0,
                right: 0,
                width: "100%",
                display: "flex",
                justifyContent: "flex-end",
                padding: "10px",
              }}
            >
              <IconButton
                onClick={handleClose}
                sx={{
                  position: "relative",
                  zIndex: 1000, // Ensure it's above other content
                }}
              >
                <CloseIcon />
              </IconButton>
            </Box>

            {/* Menu items */}
            <Box sx={{ padding: "2em 1em" }}> {/* Add padding to avoid overlap */}
              {board?._links &&
                Object.keys(board._links).map((linkKey) => {
                  const link = board._links[linkKey];
                  const buttonText = linkKey.replace(/\/\/\//g, " ");

                  return (
                    <MenuItem
                      key="linkKey"
                      onClick={() => {
                        handleExecuteLink(link?.href);
                      }}
                    >
                      <a>{buttonText}</a>
                    </MenuItem>
                  );
                })}

              <MenuItem key="auth" onClick={user ? handleLogout : handleSignIn}>
                <a>{user ? "Logout" : "Sign In"}</a>
              </MenuItem>
            </Box>
          </Menu>

        </Toolbar>

        <div
          className="navbarLinks-container"
          ref={linksContainerRef}
          style={linkStyles}
        >
          <ul className="navbarLinks" ref={linksRef}>
          {links.map((link) => {
              const { id, url, text, roles } = link;
              return (
                (!roles || JSON.stringify(roles).includes(user?.roles)) && (
                  <li key={id}>
                    <a href={url}>{text}</a>
                  </li>
                )
              );
            })}
          </ul>
        </div>
      </Box>
    </AppBar>
  );
};

export default Navbar;
