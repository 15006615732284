/**
 * Verify Email Page. Static page that shows a nice message to the user about verifying their email.
 *
 */

import React from "react";
import { CssBaseline } from "@mui/material";
import EmailIcon from '@mui/icons-material/Email'; 
import useStyles from "../../styles/styles";

export const VerifyEmailPage = ( { email, header } ) => {
    const classes = useStyles();

  return (
    <>
      <CssBaseline />
      <div className={classes.InputFormFlex}>
        <div style={{ boxShadow: "0 4px 8px rgba(0,0,0,0.1)", padding: "20px", borderRadius: "10px" }}>
            <h1>{header}</h1>
            <div style={{ marginBottom: "40px", alignItems: "center" }}>
            <EmailIcon sx={{ fontSize: 60, marginTop: "20px" }} />
            </div>
            <h3>You're almost there! We sent a verification email to</h3>
            <h2>{email}</h2>
            <p style={{ textAlign: "left" }}>It may take several minutes after registration to receive. Simply click the link in the email <br />to verify once you have received it. Make sure to check your spam folder as well.</p>
        </div>
      </div>
    </>
  );
};
