import React, { useState, useContext, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { UserContext } from "../../provider/AppProvider";
import ErrorSection from "../utils/ErrorsSection";
import { recoverAccount } from "../../api/password";
import { CssBaseline, Typography } from "@mui/material";
import useStyles from "../../styles/styles";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Box from "@mui/system/Box";

const RecoverReset = () => {
  const classes = useStyles();
  const [new_pass0, setNewPass0] = useState("");
  const [new_pass1, setNewPass1] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const { setErrors, errors } = useContext(UserContext);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [token, setToken] = useState("");

  useEffect(() => {
    setToken(searchParams.get("token"));
    // if(token){
    //   validateToken(token)
    // } else {
    //   setErrors("Token errors")
    // }
  }, []);

  const exeResetPassword = async (new_pass0) => {

    recoverAccount(token, new_pass0)
      .then((response) => {
        console.log("Recovery Sent! ", response);
        alert("Recovery Sent! give it a second to propogate... ", response);
        navigate("/login?recovery=true");
      })
      .catch((error) => {
        console.error("Recover Pass Change failed, ", error);
        setErrors(
          `Error Type: ${error.response.data.error.type ?? 'Unknown'}\n` +
          `Error Detail: ${error.response.data.error.detail ?? 'Unknown'}\n` +
          `Error Title: ${error.response.data.error.title ?? 'Unknown'}\n` +
          `Error Code: ${error.response.data.error.errorCode ?? 'Unknown'}`
        );
      });
  };

  const submitChangePass = (e) => {
    setErrors(null);
    e.preventDefault();

    if (new_pass0 ==  "" || new_pass1 ==  "") {
      setErrors("All fields required");
    } else if (new_pass0 === new_pass1) {
      console.log("Passwords match");
      exeResetPassword(new_pass0);
    } else {
      setErrors("New Passwords do not match");
    }
  };

  return (
    <>
      <CssBaseline />
      <div className={classes.InputFormFlex}>
        <div>
          <Typography variant="h3" className={classes.FormCaption}>
            Change Password
          </Typography>
            <Grid item xs={12} sm={12}>
              <TextField
                required
                id="newPassword"
                name="newPassword"
                type="password"
                label="New Password"
                value={new_pass0}
                fullWidth
                variant="standard"
                onChange={(e) => setNewPass0(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                required
                id="repeatNewPass"
                name="repeatNewPass"
                type="password"
                label="Repeat New Password"
                value={new_pass1}
                fullWidth
                variant="standard"
                onChange={(e) => setNewPass1(e.target.value)}
              />
            </Grid>
          <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
          >
            <Box sx={{ width: "300px" }}>
              <Button
                className={classes.button}
                onClick={submitChangePass}
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Submit
              </Button>
            </Box>
          </Grid>

          <Grid container direction="column" alignItems="center">
            <Box sx={{ maxWidth: "550px" }}>
              <div>
                {errors && <ErrorSection errors={errors}></ErrorSection>}
              </div>
            </Box>
          </Grid>
        </div>
      </div>
    </>
  );
};

export default RecoverReset;
