import React, { useContext } from "react";
import { DashboardContext, UserContext } from "../provider";
import { useOrgLinks } from "../action/dashboardActions";
import useStyles from "../styles/styles";
import theme from "../styles/theme";
import { ThemeProvider } from "@mui/material/styles";
import { Box, Button, CssBaseline, Typography, styled } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import BadgeListItem from "./BadgeListItem";

export default function BadgeList() {
  const classes = useStyles();
  const { board } = useContext(DashboardContext);
  const { handleExecuteLink } = useOrgLinks();
  const { user } = useContext(UserContext);

  console.log(board);

  const StyledTableRow = styled(TableRow)`
    &:nth-of-type(odd) {
      background-color: ${({ theme }) =>
        theme.palette.action.hover}; // accessing the theme
    }
    &:nth-of-type(even) {
      background-color: "grey";
    }
  `;

  return (
    <>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {user.roles.includes("ROLE_OWNER") && (
          <Box>
            <TableCell>
              <Button
                variant="contained"
                onClick={() =>
                  handleExecuteLink(board._links["Add///New///Badge"].href)
                }
              >
                Create New Badge
              </Button>
            </TableCell>
          </Box>
        )}
        <Typography className={classes.TableTitle} variant="h3">
          Badges
        </Typography>
        <Table stickyHeader size="small">
          <TableHead>
            <TableRow>
              <TableCell>
                <Typography variant="h6">Title</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h6">Description</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h6">Minimum Endorsements</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h6">Badge</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {board?.dash?.Badges.map((badge) => (
              <BadgeListItem key={badge.email} badge={badge}></BadgeListItem>
            ))}
          </TableBody>
        </Table>
      </ThemeProvider>
    </>
  );
}
