import React, { useState, useEffect } from "react";
import { useLocation, Link } from "react-router-dom";
import { getRequest } from "../../api/helper";
import { CssBaseline, Typography, Paper } from "@mui/material";
import useStyles from "../../styles/styles";
import theme from "../../styles/theme";
import { ThemeProvider } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";

const OrgDetails = () => {
  const classes = useStyles();
  const { state } = useLocation();
  const { url } = state;

  const [OrgData, setOrgData] = useState([]);

  const loadOrgDetails = async () => {
    try {
      const response = await getRequest(url);
      const report = await response;
      setOrgData(report);
      console.log("REPORT: " + report);
    } catch (error) {
      console.error("Error fetching org details:", error);
    }
  };

  useEffect(() => {
    loadOrgDetails();
  }, []);

  if (OrgData.length === 0) {
    return (
      <>
        <ThemeProvider theme={theme}>
          <CssBaseline />

          <Typography variant="h6" style={{ textAlign: "left" }}>
            <Link to={"/organizations"} style={{ marginRight: "20px" }}>
              Back to Organizations
            </Link>
          </Typography>

          <Typography variant="h3">Organization Details: </Typography>

          <Table stickyHeader size="small">
            <TableBody>
              <Paper
                sx={{
                  p: 2,
                  m: 4,
                  boxShadow: 5,
                }}
              >
                <TableRow>
                  <TableCell style={{ borderBottom: "none" }}>
                    <Typography variant="h6">
                      No details available for this organization.
                    </Typography>
                  </TableCell>
                </TableRow>
              </Paper>
            </TableBody>
          </Table>
        </ThemeProvider>
      </>
    );
  } else {
    return (
      <>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Typography variant="h6" style={{ margin: 20, textAlign: "left" }}>
            <Link to={"/organizations"}>Back to Organizations</Link>
          </Typography>

          <Typography variant="h3">Organization Details</Typography>

          <Table stickyHeader size="small">
            <TableBody>
              <>
                <Paper
                  sx={{
                    p: 2,
                    m: 4,
                    boxShadow: 5,
                  }}
                >
                  <TableRow>
                    <TableCell style={{ borderBottom: "none" }}>
                      <Typography variant="h6">
                        <Typography
                          display="inline"
                          variant="h6"
                          sx={{ fontWeight: "bold" }}
                        >
                          Name:{" "}
                        </Typography>
                        {OrgData.name}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{ borderBottom: "none" }}>
                      <Typography variant="h6">
                        <Typography
                          display="inline"
                          variant="h6"
                          sx={{ fontWeight: "bold" }}
                        >
                          Description:{" "}
                        </Typography>
                        {OrgData.description}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{ borderBottom: "none" }}>
                      <Typography variant="h6">
                        <Typography
                          display="inline"
                          variant="h6"
                          sx={{ fontWeight: "bold" }}
                        >
                          Domain:
                        </Typography>{" "}
                        {OrgData.domain}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{ borderBottom: "none" }}>
                      <Typography variant="h6">
                        <Typography
                          display="inline"
                          variant="h6"
                          sx={{ fontWeight: "bold" }}
                        >
                          Badges:
                        </Typography>{" "}
                        {OrgData.badges.length}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{ borderBottom: "none" }}>
                      <Typography variant="h6">
                        <Typography
                          display="inline"
                          variant="h6"
                          sx={{ fontWeight: "bold" }}
                          ng
                        >
                          Admins:
                        </Typography>{" "}
                        {OrgData.admins.length}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{ borderBottom: "none" }}>
                      <Typography variant="h6">
                        <Typography
                          display="inline"
                          variant="h6"
                          sx={{ fontWeight: "bold" }}
                        >
                          Users:
                        </Typography>{" "}
                        {OrgData.users.length}
                      </Typography>
                    </TableCell>
                  </TableRow>
                </Paper>
              </>
            </TableBody>
          </Table>
        </ThemeProvider>
      </>
    );
  }
};

export default OrgDetails;
