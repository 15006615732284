import React, { useState, useContext } from "react";
import { UserContext, DashboardContext } from "../../provider";
import ErrorSection from "../utils/ErrorsSection";
import { apiCreateNewOrg } from "../../api/organization";
import { CssBaseline, Typography } from "@mui/material";
import useStyles from "../../styles/styles";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Box from "@mui/system/Box";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { ErrorType } from "../../api/APIErrors";
import { toast } from "react-toastify";
import "../../styles/assets/css/main.css";
import { OwnerEndpoints } from "../../api/Endpoints";
import { sendEndpointRequestFile } from "../../iop/iop";
import { FieldErrorMapEntry, fieldErrorHandlerCommon } from "../../api/validation";
import { errorMap } from "./Registration";

const CreateOrg = () => {
  const classes = useStyles();

  const [name, setName] = useState("");
  const [domain, setDomain] = useState("");
  const [description, setDescription] = useState("");
  const [allowEvents, setAllowEvents] = useState(false);
  const [teamsEnabled, setTeamsEnabled] = useState(false);
  const [restrictEndorsements, setRestrictEndorsements] = useState(false);
  const [showDomainField, setShowDomainField] = useState(false);
  const [maxCharacters] = useState(1000);
  const [selectedFile, setSelectedFile] = useState(null);
  const { navigate, errors } = useContext(UserContext);

  const fieldErrorMap = [
    new FieldErrorMapEntry("organization.name", ErrorType.ERR_INPUT_VALIDATION_MISSING_REQUIRED_FIELD, "name", "Organization name cannot be blank."),
    new FieldErrorMapEntry("organization.name", ErrorType.ERR_INPUT_VALIDATION_SIZE_EXCEEDED, "name", "Organization name must be less than 255 characters."),
    new FieldErrorMapEntry("organization.description", ErrorType.ERR_INPUT_VALIDATION_MISSING_REQUIRED_FIELD, "description", "Description cannot be blank."),
    new FieldErrorMapEntry("organization.description", ErrorType.ERR_INPUT_VALIDATION_SIZE_EXCEEDED, "description", "Description must be less than 255 characters."),
    new FieldErrorMapEntry("organization.domain", ErrorType.ERR_INPUT_VALIDATION_SIZE_EXCEEDED, "domain", "Description must be less than 255 characters."),
  ]

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const fileType = file.name.split(".").pop();
      if (fileType === "csv") {
        setSelectedFile(file);
      } else {
        setSelectedFile(null);
        toast.error("Please select a CSV file.");
      }
    }
  };

  const handleCreateNewOrg = async () => {
    /* Pack up the config options into an object */
    const configOptions = { allowEvents, teamsEnabled, restrictEndorsements };

    /* Pack up the organization data into an object */
    let organization = {
      name,
      domain,
      description,
    };

    /* Send the request to the API */
    let outData = Object();
    let response = await apiCreateNewOrg(organization, configOptions, outData);

    /* If the response is successful, indicate no error, re-load
     * the organizations, and navigate to the dashboard.
     */
    if (response.success) {
      /* Clear all of the fields */
      setName("");
      setDomain("");
      setDescription("");
      setAllowEvents(false);
      setTeamsEnabled(false);
      setRestrictEndorsements(false);
      setShowDomainField(false);

      /* if we were given a file upload it. API is supposed to return the
       * id of the organization created. If it does not, we will not upload
       * the file because we simply cannot.
       */
      if ((selectedFile !== null) & (outData?.id !== null)) {
        let data = new FormData();
        data.append("csv", selectedFile);
        data.append("orgid", outData.id);

        let endpoint = OwnerEndpoints.endpoints.uploadBulkUserJoinRequestList;
        response = await sendEndpointRequestFile(endpoint, null, data);

        if (response.success) {
          toast.success("User join requests sent successfully.");
        } else {
          displayToastError(response.error.code);
        }
      }

      toast.success("Organization created successfully.");
      // navigate("/dashboard");
    } else {
      /* If we can't extract any field errors, handle the non-field
         related error */
      if( !fieldErrorHandlerCommon(response, errorMap)) {
        displayToastError(response.error.code);

      }

    }
  };

  function displayToastError(error) {
    /* Based on the provided error type, display the appropriate
     * error message to the user.
     */
    switch (error) {
      case ErrorType.ERR_DATABASE_DUPLICATE_ENTITY:
        toast.error("An organization with this name already exists.");
        break;

      case ErrorType.ERR_SERVER_INTERNAL:
        toast.error("Internal server error.");
        break;

      case ErrorType.ERR_INPUT_VALIDATION_INVALID_INPUT:
        toast.error("Check form fields.");
        break;

      default:
        toast.error("Failed to create organization.");
        break;
    }
  }
  return (
    <>
      <CssBaseline />
      <div className={classes.InputFormFlex}>
        <div>
          <Typography variant="h3" className={classes.FormCaption}>
            Create New Organization
          </Typography>
          <section id="formelement">
            <div>
              <Grid item xs={12} sm={12} style={{ textAlign: "left" }}>
                <h2>Basic information</h2>
                <p>
                  Name and description that are shown to the end user. These can
                  always be updated later. ** at the moment there is no way to
                  update these elements using the front end client. please ask
                  dev to
                </p>
              </Grid>
              <Grid container spacing={3} maxWidth={700}>
                <Grid item xs={12} sm={12}>
                  <TextField
                    required
                    id="name"
                    name="name"
                    label="Name"
                    value={name}
                    fullWidth
                    variant="standard"
                    onChange={(e) => setName(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <TextField
                    id="description"
                    name="description"
                    label="Description"
                    value={description}
                    fullWidth
                    variant="standard"
                    multiline
                    rows={4}
                    onChange={(e) => setDescription(e.target.value)}
                    inputProps={{ maxLength: 1000 }}
                  />
                </Grid>
                <Grid item></Grid>
                <div style={{ textAlign: "right", marginTop: "5px" }}>
                  Character Count: {description.length}/{maxCharacters}
                </div>
              </Grid>
            </div>
          </section>
          <section id="formelement">
            <div>
              <Grid item xs={12} sm={12} style={{ textAlign: "left" }}>
                <h2>Configuration</h2>
                <p>
                  Set configuraiton options for this organization. These can
                  always be changed later, however it may cause unintended
                  consequences.
                </p>
              </Grid>
              <Grid item xs={12} sm={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={showDomainField}
                      onChange={(e) => setShowDomainField(e.target.checked)}
                    />
                  }
                  label="Organization has specific domain"
                />
              </Grid>
              {showDomainField && (
                <Grid item xs={12} sm={12}>
                  <TextField
                    required
                    id="domain"
                    name="domain"
                    label="Domain"
                    value={domain}
                    fullWidth
                    variant="standard"
                    onChange={(e) => setDomain(e.target.value)}
                  />
                </Grid>
              )}
              <Grid item xs={12} sm={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={allowEvents}
                      onChange={(e) => setAllowEvents(e.target.checked)}
                    />
                  }
                  label="Allow users to create events"
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={teamsEnabled}
                      onChange={(e) => setTeamsEnabled(e.target.checked)}
                    />
                  }
                  label="Teams enabled"
                />
              </Grid>
              {teamsEnabled && (
                <Grid item xs={12} sm={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={restrictEndorsements}
                        onChange={(e) =>
                          setRestrictEndorsements(e.target.checked)
                        }
                      />
                    }
                    label="Restrict endorsements to team members only"
                  />
                </Grid>
              )}
            </div>
          </section>
          <section id="formelement">
            <div>
              <Grid item xs={12} sm={12} style={{ textAlign: "left" }}>
                <h2>Send user join requests</h2>
                <p>
                  Upload a list of users to send join requests to. File must be
                  a CSV with a single column of email addresses. This can always
                  be done later.
                </p>
              </Grid>
              <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justifyContent="center"
              >
                {
                  <Grid container spacing={3} maxWidth={700}>
                    <Grid item xs={12} sm={12}>
                      <input
                        type="file"
                        accept=".csv"
                        onChange={handleFileChange}
                      />
                    </Grid>
                  </Grid>
                }
              </Grid>
            </div>
          </section>
          <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
          >
            <Box sx={{ width: "300px" }}>
              <Button
                className={classes.button}
                onClick={handleCreateNewOrg}
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Create Organization
              </Button>
            </Box>
          </Grid>
          <Grid container direction="column" alignItems="center">
            <Box sx={{ maxWidth: "550px" }}>
              <div>
                {errors && <ErrorSection errors={errors}></ErrorSection>}
              </div>
            </Box>
          </Grid>
        </div>
      </div>
    </>
  );
};

export default CreateOrg;
