import React from "react";
import { Button, Container, CssBaseline, Typography, Paper, Box } from "@mui/material";
import { ThemeProvider } from '@mui/material/styles';
import theme from '../../styles/theme';
import useStyles from "../../styles/styles";

const SubmissionPopup = ({ header, message, onClose, altButton1Title=null, altButton1Action=null }) => {
  const classes = useStyles();

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Paper
        sx={{
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          zIndex: 999,
          backgroundColor: "white",
          boxShadow: 5,
          padding: 4,
          maxWidth: 500,
          textAlign: "center"
        }}
      >
        <Container>
        <Typography variant="h4" gutterBottom>{header}</Typography>
        <Typography
                  sx={{
                    textAlign: "left",
                    marginTop: "2em",
                  }}
                >
                 {message}
                </Typography>
          <Button
            color="primary"
            variant="contained"
            onClick={onClose}
            sx={{ mt: 3 }}
          >
            Close
          </Button>
          { ( altButton1Title != null ) && ( altButton1Action != null ) &&
            <Button
              color="primary"
              variant="contained"
              onClick={altButton1Action}
              sx={{ mt: 3,
              }}
            >
              {altButton1Title}
          </Button>}
        </Container>
      </Paper>
    </ThemeProvider>
  );
};

export default SubmissionPopup;
