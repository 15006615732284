import React from "react";
import { useParams } from "react-router-dom";
import theme from "../../styles/theme";
import "../../styles/assets/css/main.css";
import { ThemeProvider } from "@mui/material/styles";
import { CssBaseline } from "@mui/material";
import UserProfilePane from "./home/UserProfilePane";
import UserQuickActionPane from "./home/UserQuickActionPane";
import Footer from "./home/Footer";
import UserStatsPane from "./home/UserStatsPane";  // Import the new component

export default function Main() {
  const { username } = useParams();

  return (
    <>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div style={{ display: 'flex', height: '100vh' }}>
          {/* Left 30% of the screen */}
          <div style={{ flex: '0 0 30%', marginRight: '10px' }}>
            <div style={{ marginTop: '-60px' }}> {/* Adjust this value to move up */}
              <UserProfilePane username={username} />
            </div>
            
            {/* Stat Pane below the UserProfilePane */}
            <div style={{ marginTop: '20px' }}>  {/* Add some space between the panes */}
              <UserStatsPane username={username} />  {/* New stats pane */}
            </div>
          </div>

          {/* Vertical divider */}
          <div style={{
            width: '1px', 
            backgroundColor: theme.palette.divider, 
            margin: '0 10px'
          }}></div>

          {/* Right 70% of the screen */}
          <div style={{ flex: '1' }}>
            <UserQuickActionPane username={username} />
          </div>
        </div>
        <Footer />
      </ThemeProvider>
    </>
  );
}
