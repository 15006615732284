import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import StarIcon from '@mui/icons-material/Star';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import { SkillEndpoints } from "../../../api/Endpoints";
import { sendEndpointRequestNoAuth } from "../../../iop/iop";
import { toast } from "react-toastify";

const UserStatsPane = ({ username }) => {
  const [skillStats, setSkillStats] = useState(null);
  const [error, setError] = useState(false);

  useEffect(() => {
    const getSkillStats = async () => {
      if (!username) {
        setError(true);
        return;
      }

      try {
        let params = { "userName": username };
        const response = await sendEndpointRequestNoAuth(SkillEndpoints.endpoints.getUserSkillStats, params, null);
        
        if (response && response.data) {
          setSkillStats({
            skillCount: response.data.totalSkillCount,
            endorsementCount: response.data.totalEndorsementCount,
          });
        } else {
          setError(true);
        }
      } catch (err) {
        console.error("Error fetching skill stats:", err);
        setError(true);
        toast.error("An error occurred while retrieving user skill stats.");
      }
    };

    getSkillStats();
  }, [username]);

  if (error) {
    return (
      <Box sx={{ padding: 2, backgroundColor: '#f9f9f9', borderRadius: '8px', boxShadow: 1 }}>
        <Typography variant="h6" gutterBottom>
          User Statistics
        </Typography>
        <Typography variant="body1" color="error" align="center">
          Can't retrieve data
        </Typography>
      </Box>
    );
  }

  if (!skillStats) {
    return (
      <Box sx={{ padding: 2, backgroundColor: '#f9f9f9', borderRadius: '8px', boxShadow: 1 }}>
        <Typography variant="h6" gutterBottom>
          User Statistics
        </Typography>
        <Typography variant="body1" align="center">
          Loading...
        </Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ padding: 2, backgroundColor: '#f9f9f9', borderRadius: '8px', boxShadow: 1 }}>
      <Typography variant="h6" gutterBottom>
        User Statistics
      </Typography>

      {/* Stats display - using a flexbox for alignment */}
      <Box display="flex" flexDirection="column" gap={4}>
        {/* Skills */}
        <Box display="flex" alignItems="center">
          <Box display="flex" alignItems="center" sx={{ width: '30px', marginRight: 1 }}>
            <StarIcon fontSize="large" color="primary" />
          </Box>
          <Box display="flex" flexDirection="column" alignItems="center" sx={{ flexGrow: 1 }}>
            <Typography variant="h4" fontWeight="bold" color="primary" align="center">
              {skillStats.skillCount !== null ? skillStats.skillCount : "0"}
            </Typography>
            <Typography variant="body1" style={{ fontFamily: 'Courier New, Courier, monospace' }} align="center">
              Skills
            </Typography>
          </Box>
        </Box>

        {/* Endorsements */}
        <Box display="flex" alignItems="center">
          <Box display="flex" alignItems="center" sx={{ width: '30px', marginRight: 1 }}>
            <ThumbUpIcon fontSize="large" color="secondary" />
          </Box>
          <Box display="flex" flexDirection="column" alignItems="center" sx={{ flexGrow: 1 }}>
            <Typography variant="h4" fontWeight="bold" color="primary" align="center">
              {skillStats.endorsementCount !== null ? skillStats.endorsementCount : "0"}
            </Typography>
            <Typography variant="body1" style={{ fontFamily: 'Courier New, Courier, monospace' }} align="center">
              Endorsements
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default UserStatsPane;
