/**
 * Defines the navigation links for the application. Each link object may include a `roles` array
 * to specify which user roles can view that link. 
 *
 * If a link does not have a `roles` array defined, it will be displayed to all users (including
 * users that are not logged in).
 * 
 * NOTE: if a link should be displayed for all logged in users, but not for users that are not
 * logged in, you must specify all of the roles, e.g., `["ROLE_USER", "ROLE_ADMIN", "ROLE_OWNER"]`
 * 
 */

export const links = [
  // TODO PMG hiding this for now b/c /dashboard is very much in flux
  // {
  //   id: 1,
  //   url: "/dashboard",
  //   text: "home",
  //   roles: ["ROLE_USER", "ROLE_ADMIN", "ROLE_OWNER"],
  // },
  // TODO PMG - legacy menu option
  // {
  //   id: 2,
  //   url: "/organizations",
  //   text: "organizations",
  //   roles: ["ROLE_USER", "ROLE_ADMIN", "ROLE_OWNER"],
  // },
  {
    id: 3,
    url: "/admins",
    text: "admins",
    roles: ["ROLE_ADMIN", "ROLE_OWNER"],
  },
  {
    id: 4,
    url: "/users",
    text: "users",
    roles: ["ROLE_ADMIN", "ROLE_OWNER"],
  },
  // TODO PMG - legacy menu options
  // {
  //   id: 5,
  //   url: "/badges",
  //   text: "badges",
  //   roles: ["ROLE_OWNER"],
  // },
  // {
  //   id: 6,
  //   url: "/org-badges",
  //   text: "org-badges",
  //   roles: ["ROLE_ADMIN", "ROLE_USER"],
  // },
  // {
  //   id: 7,
  //   url: "/mybadges",
  //   text: "mybadges",
  //   roles: ["ROLE_USER"],
  // },
  {
    id: 8,
    url: "/skill-manager",
    text: "Edit Skills",
    roles: ["ROLE_OWNER"],
  },
  {
    id: 9,
    url: "/skills",
    text: "skills",
  },
  {
    id: 10,
    url: "/my-endorsements",
    text: "endorsements",
    roles: ["ROLE_USER"],
  },

  {
    id: 11,
    url: "/faq",
    text: "FAQ",
    roles: ["ROLE_USER", "ROLE_ADMIN", "ROLE_OWNER"],
  },
];
