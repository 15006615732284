import React, { useEffect, useState } from "react";
import Box from "@mui/system/Box";
import { useAppProvider } from "../../provider";
import { CssBaseline, Typography } from "@mui/material";
import useStyles from "../../styles/styles";
import Navigation from "../utils/Navigation";
import theme from "../../styles/theme";
import { ThemeProvider } from "@mui/material/styles";
import Navbar from "../navbar/Navbar";


const Page = (props) => {
  const classes = useStyles();
  const Content = props.content;
  const { user } = useAppProvider();
  const showNav = props.showNav === undefined ? true : props.showNav;
  const isDash = props.isDash === undefined ? false : props.isDash;
  const navBoxClass =
    props.showNav === undefined ? classes.NavOpenWidth : classes.NavClosedWidth;
  const [windowSize, setWindowSize] = useState(window.innerWidth);

  useEffect(() => {
    // Attach the event listener to the window object
    window.addEventListener("resize", handleResize);
  }, []);

  const handleResize = () => {
    setWindowSize(window.innerWidth); //update state on resize to trigger re-rendering of sidenav
  };

  return (
    <>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Box
          sx={{
            backgroundColor: isDash ? theme.palette.grey[100] : "white",
            height: "100vh",
          }}
        >
          {showNav ? <Navbar /> : <></>}
          <Box sx={{ paddingLeft: "3%", paddingRight: "3%" }}>
            <Content />
          </Box>
        </Box>
      </ThemeProvider>
    </>
  );
};

export default Page;
