import React, { useState, useContext, useEffect } from "react";
import { UserContext, DashboardContext } from "../../provider";
import { useLocation, Link } from "react-router-dom";
import ErrorSection from "../utils/ErrorsSection";
import { Box, CssBaseline, Typography } from "@mui/material";
import useStyles from "../../styles/styles";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { sendEndpointRequest } from "../../iop/iop";
import { ErrorType } from "../../api/APIErrors";

const CreateEvent = () => {
  const STATE_SAVE_INTERVAL = 7000;  /* Milliseconds for save task period */
  const UPDATE_EVENT_TIMEOUT = 3000;  /* Milliseconds for update event timeout */

  /* Save state display messages */
  const SaveStates = {
    SAVING:           "Saving...",
    ERROR_AUTO:       "Autosave failed.",
    ERROR:            "Save failed.",
    PENDING:          "",
    LOST_CONNECTION:  "Lost connection.",
  };
  const [savingState, setSaving] = useState(SaveStates.PENDING);

  const classes = useStyles();
  const { state } = useLocation();
  let { url, badgeTitle, badgeDescription, eventId, existingEventTitle, existingEventDate, existingEventDescription } = state || {};

  existingEventTitle = existingEventTitle ? existingEventTitle : "New Event";
  existingEventDate = existingEventDate ? existingEventDate : "";
  existingEventDescription = existingEventDescription ? existingEventDescription : "";

  const { errors, setErrors, navigate } = useContext(UserContext);
  const [maxCharacters] = useState(1000);

  const [eventTitle, setEventTitle] = useState(existingEventTitle);
  const [eventDate, setEventDate] = useState(existingEventDate);
  const [eventDescription, setEventDescription] = useState(existingEventDescription);

  /* Event id for saved entity */
  const [workingEventId, setWorkingEventId] = useState(eventId || null);

  /* Autosave task,
    note updateEvent allows for event creation by using null event id

    @param commit - if true, will close out the page after saving
  */
  async function saveEventData (commit = false){
    setErrors(null);
    // TODO PMG need to refactor this in iop layer. shouldn't have to be concernd with url params
    /* Get the parameters from the URL */
    const queryString = url.split('?')[1];
    const params = new URLSearchParams(queryString);
    const userBadgeId = params.get('userBadgeId');
    const updateParams = `userBadgeId=${userBadgeId}`;

    if (eventDescription == "" || eventDate == "") {
      setErrors("All fields are required");
      return;
    }

    const event = {
      title: eventTitle,
      eventDate: eventDate,
      description: eventDescription,
      id: workingEventId
    };

    setSaving(SaveStates.SAVING);

    // TODO PMG EU - this will cause a fault when submitting the form. EventEndpoints to not exist any longer
    let selectedState = SaveStates.PENDING;
    const response = null /* = await sendEndpointRequest(EventEndpoints.endpoints.updateEvent, updateParams, event); */

    /* If the update call was successful, update the working id */
    if (response.success) {
      setWorkingEventId(response.data.id);
      selectedState = SaveStates.PENDING;

    /* If the update call was unsuccessful, set the error state based 
     * on the response
     */
    } else if (response.error.code === ErrorType.ERR_LOST_CONNECTION) {
      selectedState = SaveStates.LOST_CONNECTION;
    } else {
      selectedState = SaveStates.ERROR_AUTO;
    }

    /* Set UI state unless we lost connection */
    if (selectedState !== SaveStates.LOST_CONNECTION) {
      setTimeout(async () => {
        setSaving(selectedState);
      }, UPDATE_EVENT_TIMEOUT);
    }

    /* Close out this page if user committed */
    if(commit) {
      navigate("/mybadges");
    }
  };

  /* Periodically save the event */
  useEffect(() => {
    const intervalId = setInterval(saveEventData, STATE_SAVE_INTERVAL);

    return () => clearInterval(intervalId);
  }, [url, eventTitle, eventDate, eventDescription, workingEventId]);


  /* Do final commit */
  async function submitEventCreation() {
    setErrors(null);
      saveEventData(true);
  };

  /* UI Components */
  return (
    <>
      <CssBaseline />

      <div className={classes.InputFormFlex}>
        <div>
          <Grid container spacing={3} maxWidth={700}>
            <Grid item xs={12} sm={12}>
              <Table stickyHeader size="small">
                <TableBody>
                  <TableRow key={badgeTitle}>
                    <TableCell style={{ borderBottom: "none" }}>
                    </TableCell>
                    <TableCell style={{ borderBottom: "none" }}>
                      <Typography variant="h3">{badgeTitle}</Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow key={badgeDescription}>
                    <TableCell style={{ borderBottom: "none" }}>
                    </TableCell>
                    <TableCell style={{ borderBottom: "none" }}>
                      <Typography variant="h6">{badgeDescription}</Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                required
                id="eventTitle"
                name="eventTitle"
                label="Event Title"
                value={eventTitle}
                fullWidth
                variant="standard"
                onChange={(e) => setEventTitle(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                InputLabelProps={{ shrink: true }}
                required
                id="eventDate"
                name="eventDate"
                label="Event Date"
                value={eventDate}
                fullWidth
                type="date"
                variant="standard"
                onChange={(e) => setEventDate(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                id="eventDescription"
                name="eventDescription"
                label="Event Description"
                value={eventDescription}
                fullWidth
                variant="standard"
                multiline
                rows={4}
                onChange={(e) => setEventDescription(e.target.value)}
                inputProps={{ maxLength: 1000 }}
              />
            </Grid>
            <Grid item></Grid>
            <div style={{ textAlign: "right", marginTop: "5px" }}>
              Character Count: {eventDescription.length}/{maxCharacters}
            </div>
          </Grid>
          <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
          >
            <Box sx={{ width: "300px" }}>
              <Button
                className={classes.button}
                onClick={submitEventCreation}
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Save
              </Button>
                <div style={{ textAlign: "center", marginTop: "10px" }}>
                  <Typography variant="body1">{savingState}</Typography>
                </div>
            </Box>
          </Grid>

          <Grid container direction="column" alignItems="center">
            <Box sx={{ maxWidth: "550px" }}>
              <div>
                {errors && <ErrorSection errors={errors}></ErrorSection>}
              </div>
            </Box>
          </Grid>
        </div>
      </div>
    </>
  );
};

export default CreateEvent;
