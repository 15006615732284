import React from "react";
import { CssBaseline, Typography, CircularProgress } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    backgroundColor: "#fff",
    flexDirection: "column",
    textAlign: "center",
  },
  typography: {
    fontSize: "2rem",
    fontWeight: "500",
    color: "#333",
    marginBottom: "20px",
  },
  loader: {
    color: "#333", 
  },
}));

const Loading = () => {
  const classes = useStyles();

  return (
    <>
      <CssBaseline />
      <div className={classes.root}>
        <Typography className={classes.typography}>Loading...</Typography>
        <CircularProgress className={classes.loader} />
      </div>
    </>
  );
};

export default Loading;
