import { toast } from "react-toastify";
import { ErrorType } from "./APIErrors";

/**
 * Helpful utilty for handling common file upload errors received
 * from the server.
 * 
 * @param {ErrorType} error 
 */
export function handleCommonFileError(error) {
    switch (error.code) {
        case ErrorType.ERR_FILE_FILE_SIZE_EXCEEDED:
          toast.error("File size exceeded. Please upload a smaller file.");
          break;
        
        case ErrorType.ERR_FILE_UNSUPPORTED_FILE_TYPE:
          toast.error("Unsupported file type.");
          break;
        
        case ErrorType.ERR_FILE_FILE_FORMAT_INVALID:
          toast.error("Invalid file format. Please upload a valid file.");
          break;
  
        case ErrorType.ERR_FILE_FILE_UPLOAD_FAILED:
        default:
          toast.error("An error occurred while uploading the file. Please try again later.");
          break;
      }
}