import React from "react";
import { Link } from "react-router-dom";
import useStyles from "../../styles/styles";

const NotFoundPage = () => {
  const classes = useStyles();

  return (
    <div className={classes.notFoundContainer}>
      <h1 className={classes.notFoundTitle}>404</h1>
      <p className={classes.notFoundMessage}>
        Oops! The page you're looking for doesn't exist.
      </p>
      <Link to="/" className={classes.notFoundLink}>
        Go back to Home
      </Link>
    </div>
  );
};

export default NotFoundPage;
