import React, { useState, useContext, useEffect } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import ErrorSection from "../utils/ErrorsSection";
import { UserContext } from "../../provider";
import theme from "../../styles/theme";
import { CssBaseline, ThemeProvider, Typography, Paper } from "@mui/material";
import useStyles from "../../styles/styles";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Box from "@mui/system/Box";
import { EndorsementEndpoints } from "../../api/Endpoints";
import { sendEndpointRequestNoAuth } from "../../iop/iop";
import { fieldErrorHandlerCommon, FieldErrorMapEntry } from "../../api/validation";
import { ErrorType } from "../../api/APIErrors";
import SkillViewComponent from "../skills/SkillResource";
import { toast } from "react-toastify";
import Loading from "./Loading";

/* Field violations error map */
export const errorMap = [
  new FieldErrorMapEntry("phoneNumber", ErrorType.ERR_INPUT_VALIDATION_PHONE_NUMBER_FORMAT, "phoneNumber", "Invalid phone number."),
]

/**
 * Endorsement Container.
 *
 * Contains the form for submitting an endorsement in addition to the content that is
 * being endorsed. The information that this page displays is dependent upon a third
 * party token that is passed in the URL. This token is used to retrieve the context
 * of the endorsement from the server in addition to track the endorsement object
 * from creation to eventual submission.
 *
 */
const EndorsementContainer = () => {

  const PageMode = {
    PAGE_MODE_EXPIRED: "PAGE_MODE_EXPIRED",
    PAGE_MODE_ERROR_STATE: "PAGE_MODE_ERROR_STATE",
    PAGE_MODE_LOADING: "PAGE_MODE_LOADING",
    PAGE_MODE_LOADED: "PAGE_MODE_LOADED",
  };

  const classes = useStyles();

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [token, setToken] = useState("");
  const [number, setNumber] = useState("");
  const [endorsement, setEndorsement] = useState("");
  const [endorsementContext, setEndorsementContext] = useState([]);
  const [submissionMessage, setSubmissionMessage] = useState("");
  const [maxCharacters] = useState(200);
  const [pageMode, setPageMode] = useState(PageMode.PAGE_MODE_LOADING);
  const { errors, setErrors } = useContext(UserContext);
  const [successfulSubmission, setSuccessfulSubmission] = useState(false);

  const loadContext = async (token) => {
    let response;
    try {
      response = await sendEndpointRequestNoAuth(EndorsementEndpoints.endpoints.getEndorsementContext, { token: token }, null);
    } catch (err) {
      console.error("Failed Loading context", err);
    }

    /* If request was successful, set the event context;
       otherwise handle errors and set the approproate
       page state */
    if(response?.data) {
      setEndorsementContext(response.data);

      setPageMode(PageMode.PAGE_MODE_LOADED);
    } else {
      handleContextLoadError(response);
    }
  };

  const handleContextLoadError = (response) => {
    switch (response?.error?.code) {

      case ErrorType.ERR_GENERIC_TOKEN_EXPIRED:
        setPageMode(PageMode.PAGE_MODE_EXPIRED);
        break;

      default:
        setPageMode(PageMode.PAGE_MODE_ERROR_STATE);
        break;
    }
  };

  useEffect(() => {
    const tokenFromParams = searchParams.get("token");
    const firstNameFromParams = searchParams.get("firstName");
    const lastNameFromParams = searchParams.get("lastName");

    if (tokenFromParams) {
      setToken(tokenFromParams);
      loadContext(tokenFromParams);
    }
  }, [searchParams, setErrors]);

  const handleEndorsementSubmission = async () => {
    setPageMode(PageMode.PAGE_MODE_LOADING);

    setErrors(null);
    if (endorsement == "" || number == "") {
      setErrors("All fields are required");
      return;
    }

    let response;
    try {
      const params = {token: token}
      const body = {
        endorsementBody: endorsement,
        phoneNumber: number,
      };
      response = await sendEndpointRequestNoAuth(EndorsementEndpoints.endpoints.saveEndorsement, params, body)

    } catch (err) {
      console.error(err);
      setSubmissionMessage("Error submitting endorsement: ", err,);
    }

    /* If some kind of error was encoutered handle it now */
    if( !(response?.success) ) {
      handleEndorsementSubmissionError(response);
    }

    setPageMode(PageMode.PAGE_MODE_LOADED);
    setSuccessfulSubmission(true);

    /* If the submission was successful, display a success message */
    toast.success("Endorsement submitted successfully");

  };

  const handleEndorsementSubmissionError = (response) => {
    /* Check for any field validation errors, e.g., bad
       phone number */
    if( fieldErrorHandlerCommon(response, errorMap) ) {
      return;
    }

    /* Handle other errors */
    switch (response?.error?.code) {
      case ErrorType.ERR_GENERIC_TOKEN_EXPIRED:
        setPageMode(PageMode.PAGE_MODE_EXPIRED);
        break;

      default:
        setSubmissionMessage("Error submitting endorsement. Please try again later.");
        setPageMode(PageMode.PAGE_MODE_ERROR_STATE);
        break;
    }
  }

  return (
    <>
      <CssBaseline />
      <ThemeProvider theme={theme}>

        { ( pageMode === PageMode.PAGE_MODE_LOADING ) && <Loading/>}

        {/* Normall/Successful fetch view */}
        { ( pageMode === PageMode.PAGE_MODE_LOADED ) && <Box className="inputFormFlex">

          {/* Endorsement Form view */}
          { ( !successfulSubmission ) && <Grid container spacing={3} maxWidth={700}>
            <Grid item xs={12} sm={12}>
              {submissionMessage || (
                // Display the form sections when there's no submission message
                <Box className="mainSection"
                     sx={{
                        position: "fixed",
                     }}>
                  {/* User Input Sections */}
                  <Typography variant="h2" className={classes.FormCaption}>
                    Endorsement
                  </Typography>
                  <TextField
                    required
                    id="phoneNumber"
                    name="phoneNumber"
                    label="Phone Number (max 15 characters)"
                    value={number}
                    fullWidth
                    variant="standard"
                    onChange={(e) => setNumber(e.target.value)}
                  />
                  <Grid item xs={12} sm={12}>
                    <TextField
                      id="endorsement"
                      name="endorsement"
                      label="Endorsement"
                      value={endorsement}
                      fullWidth
                      variant="standard"
                      multiline
                      rows={4}
                      onChange={(e) => setEndorsement(e.target.value)}
                      inputProps={{ maxLength: 200 }}
                    />
                  </Grid>
                  <Grid item>
                    <Box style={{ textAlign: "right", marginTop: "5px" }}>
                      Character Count: {endorsement.length}/{maxCharacters}
                    </Box>
                  </Grid>
                  <Grid
                    container
                    spacing={0}
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Box sx={{ width: "300px" }}>
                      <Button
                        className={classes.button}
                        onClick={handleEndorsementSubmission}
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                      >
                        Submit Endorsement
                      </Button>
                    </Box>
                  </Grid>
                  <Grid container direction="column" alignItems="center">
                    <Box sx={{ maxWidth: "550px" }}>
                      <Box>
                        {errors && (
                          <ErrorSection errors={errors}></ErrorSection>
                        )}
                      </Box>
                    </Box>
                  </Grid>
                </Box>
              )}
            </Grid>
          </Grid> }

          {/* Successful submission view */}
          { ( successfulSubmission ) && <Box
            sx={{
              padding: 3,
              maxWidth: 600,
              margin: "auto",
              textAlign: "center",
            }}
          >
            <Typography variant="h4" gutterBottom>
              Thank you for your endorsement!
            </Typography>
            <Typography variant="body1" gutterBottom>
              Your endorsement has been submitted successfully.
            </Typography>
          </Box> }

          <Box>
            <SkillViewComponent
              evidenceTitle={endorsementContext.evidencePath?.contentTitle}
              skillTitle={endorsementContext.evidencePath?.skillTitle}
              userEmail={endorsementContext.evidencePath?.userName}
              />
          </Box>

        </Box> }

        {/* Token expired view */}
        {pageMode === PageMode.PAGE_MODE_EXPIRED && (
          <Box className="expiredMessage">
            <Paper elevation={3} sx={{ padding: 3, maxWidth: 600, margin: "auto", textAlign: "center" }}>
              <Typography variant="h4" gutterBottom>
                This Link Is No Longer Active
              </Typography>
              <Typography variant="body1" gutterBottom>
                It looks like this link has expired or is no longer valid. This could be because the endorsement has already been submitted or enough time has passed since the link was generated.
              </Typography>
              <Typography variant="body1">
                If you still want to submit an endorsement, please reach out to the person who sent you this link for a new one.
              </Typography>
              <Button
                variant="contained"
                color="primary"
                onClick={() => navigate("/skills")}
                sx={{ marginTop: 2 }}
              >
                Return Home
              </Button>
            </Paper>
          </Box>
        )}

        {/* Token expired view */}
        {pageMode === PageMode.PAGE_MODE_ERROR_STATE && (
          <Box className="expiredMessage">
            <Paper elevation={3} sx={{ padding: 3, maxWidth: 600, margin: "auto", textAlign: "center" }}>
              <Typography variant="h4" gutterBottom>
                Something went wrong
              </Typography>
              <Typography variant="body1" gutterBottom>
                Could not load the context for this endorsement. This could be due to a number of reasons, such as an invalid link or a server error.
                We appologize for the inconvenience. Please try again later.
              </Typography>
              <Button
                variant="contained"
                color="primary"
                onClick={() => navigate("/skills")}
                sx={{ marginTop: 2 }}
              >
                Return Home
              </Button>
            </Paper>
          </Box>
        )}

      </ThemeProvider>
    </>
  );
};

export default EndorsementContainer;
