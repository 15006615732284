import React, { createContext, useEffect, useState } from "react";
import { softDeleteUser } from "../api/user";
import { getRequest } from '../api/helper'
import { removeAUserFromOrg, softDeleteOrg, addUserBadgeFromOrgBadgeList } from "../api/organization";
import { useNavigate } from "react-router-dom";
import { useProvider } from "./AppProvider";
import { config } from '../Constants';


export const DashboardContext = createContext({});

const defaultBoard = {
    dash: {
        Admins: [],
        Badges: [],
        Organizations: [],
        Users: [],
        UserBadges: []
    },
    _links: {}
}

export default function DashboardProvider({ children }) {

    const [board, setBoard] = useState(defaultBoard);
    const [errors, setErrors] = useState()
    const [loading, setLoading] = useState(false);
    const {user} = useProvider();
    const navigate = useNavigate();

    const setBoardValue = (key, value) => {
        const newBoard = board

        newBoard.dash[key] = value
        setBoard(newBoard)
        setLoading(false)
    }
    useEffect(() =>{
        setBoard(null)
    }, [user])

    const setAdmins = (values) => setBoardValue('Admins', values)
    const setBadges = (values) => setBoardValue('Badges', values)
    const setOrganizations = (values) => setBoardValue('Organizations', values)
    const setUsers = (values) => setBoardValue('Users', values)
    const setUserBadges = (values) => setBoardValue('UserBadges', values)

    const loadUserBadges = () => {
        if (Object.keys(board._links).length) {
          setLoading(true);
          getRequest(`${config.url.API_BASE_URL}/api/badge/user-badges`)
          .then((data) => {
            console.log('User Badges Endpoint: ', data);
            const { _embedded } = data;
            const userBadgeDtoList = _embedded?.userBadgeDtoList;
            setUserBadges(userBadgeDtoList);
          })
          .catch((error) => {
            console.error("Error in LoadBadges DashContext: ", error);
            setErrors(error.response);
        })
        }
    };

    const loadBadges = () => {
        if (Object.keys(board._links).length) {
          setLoading(true);
          getRequest(`${config.url.API_BASE_URL}/api/badge/all`)
          .then((data) => {
            console.log('All Badges Endpoint: ', data);
            // const { _embedded } = data;
            // const badgeDtoList = _embedded?.badgeDtoList;
            // setBadges(badgeDtoList);
            setBadges(data);
          })
          .catch((error) => {
            console.error("Error in LoadBadges DashContext: ", error);
            setErrors(error.response);
        })
        }
    };

    const loadMyOrgsBadgesAsUser = () => {
        setLoading(true);
        getRequest(`${config.url.API_BASE_URL}/api/organization/`)
            .then((data) => {
                console.log('All Badges Of Org For User Endpoint: ', data);
                // this can be optimized later to not return the whole org, or not, idk
                const badgeDtoList = data?.badges;
                console.log(badgeDtoList);
                setBadges(badgeDtoList);
        })
        .catch((error) => {
            console.error("Error in load My Orgs Badges As User DashContext: ", error);
            setErrors(error.response);
        })
    };

    const loadAdminsOrg = () => {
        if (Object.keys(board._links).length) {
          setLoading(true);
          getRequest(`${config.url.API_BASE_URL}/api/organization/admin-orgs`)
          .then((data) => {
            console.log('Admins Orgs Endpoint: ', data);
            const { _embedded } = data;
            const organizationDtoList = _embedded?.organizationDtoList;
            setOrganizations(organizationDtoList);
          })
          .catch((error) => {
            console.error("Error in loadAdminsOrg DashContext: ", error);
            setErrors(error.response);
        })
        }
    };

    const loadOrganizations = () => {
        if (Object.keys(board._links).length) {
          setLoading(true);
          getRequest(`${config.url.API_BASE_URL}/api/organization/all`)
          .then((data) => {
            console.log('All Orgs Endpoint: ', data);
            const { _embedded } = data;
            const organizationDtoList = _embedded?.organizationDtoList;
            setOrganizations(organizationDtoList);
          })
          .catch((error) => {
            console.error("Error in loadOrganizations DashContext: ", error);
            setErrors(error.response);
        })
        }
    };
      
      // currently, same endpoint as loadUsers, later, need to add endpoints to handle admin and user retrieval
    const loadAdmins =  () => {
        if(Object.keys(board._links).length){
            setLoading(true);
            getRequest(`${config.url.API_BASE_URL}/api/owner/get-all-role-admins`)
            .then((data) =>{
                    console.log('All Admins Endpoint: ', data);
                    const { _embedded } = data;
                    const userDtoList = _embedded?.userDtoList; 
                    setAdmins(userDtoList);
                }
            )
            .catch((error) => {
                console.error("Error in LoadAdmin DashContext: ", error);
                setErrors(error.response);
            })
        }
    }

          // currently, same endpoint as loadAdmins, later, need to add endpoints to handle admin and user retrieval
    const loadUsers =  () => {
        if(Object.keys(board._links).length){
            setLoading(true);
            getRequest(`${config.url.API_BASE_URL}/api/owner/get-all-role-users`)
            .then((data) =>{
                    console.log('All Users Endpoint: ', data);
                    const { _embedded } = data;
                    const userDtoList = _embedded?.userDtoList; 
                    setUsers(userDtoList);
                }
            )
            .catch((error) => {
                console.error("Error in LoadUsers DashContext: ", error);
                setErrors(error.response);
            })
        }
    }

    const exeSoftDeleteOrg = (link) => {
        softDeleteOrg(link)
            .then((response) => {
                console.log("Soft Deleted Organization:", response);
                loadOrganizations();
                setErrors(null);
            })
            .catch((error) => {
                console.error("Soft delete Organization failed:", error);
                setErrors(error.response);
            })
    };

    const exeSoftDeleteUser = (link) => {
        softDeleteUser(link)
            .then((response) => {
                console.log("Soft Deleted User:", response);
                loadAdmins();
                loadUsers();
                setErrors(null);
                navigate("/login?register=true");
            })
            .catch((error) => {
                console.error("Soft delete user failed:", error);
                setErrors(error);
            })
    };

    const exeRemoveAUserFromOrg = (link) => {
        removeAUserFromOrg(link)
        .then((response) => {
            console.log("Removed a User/Admin from an org:", response);
            setErrors(null);
            console.log("USERRRRR: ", user)
            if (user.roles && user.roles.includes("ROLE_ADMIN")) {
                loadAdminsOrg();
            } else {
                loadOrganizations();
            }
        })
            .catch((error) => {
                console.error("FAILED removing a User/Admin from an org:", error);
                setErrors(error);
            })
    };

    const addUserBadge = (link) => {
        addUserBadgeFromOrgBadgeList(link)
        .then((response) => {
            console.log("SUCCESS creating a user badge from an org badge:", response);
            setErrors(null);
            loadMyOrgsBadgesAsUser();
        })
            .catch((error) => {
                console.error("FAILED creating a user badge from org badges:", error);
                setErrors(error);
            })
    }

    return <DashboardContext.Provider
        value={{
            exeSoftDeleteUser,
            exeRemoveAUserFromOrg,
            exeSoftDeleteOrg,
            addUserBadge,
            loadOrganizations,
            loadAdmins,
            loadUsers,
            loadBadges,
            loadUserBadges,
            loadMyOrgsBadgesAsUser,
            board,
            updateBoard: (board) => { console.log("BOARDDDDDDDDD", board); setBoard(board) }
        }}
    >
        {children}
    </DashboardContext.Provider>

}