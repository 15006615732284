import './RichTextEditor.css';
import './TitleEditor.css';
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { config } from '../../Constants';
import { debounce } from 'lodash';
import { useParams } from 'react-router-dom';
import { SkillEndpoints } from '../../api/Endpoints';
import { sendEndpointRequest, sendEndpointRequestFile } from "../../iop/iop";
import { ErrorType } from "../../api/APIErrors";
import { toast } from "react-toastify";
import { sleep } from '../../Utils';

const OwnerRTE = () => {
    const { skillTitle } = useParams();
    const [id, setId] = useState(null);
    const [loading, setLoading] = useState(false);
    const [saving, setSaving] = useState(false);
    const [waitingOn, setWaitingOn] = useState('');
    const typingTimeoutRef = useRef(null);
    const [content, setContent] = useState('');
    const [initialLoadFinished, setInitialLoadFinished] = useState(false);
    const [titleInput, setTitleInput] = useState('');
    const [currentTitle, setCurrentTitle] = useState('');
    const elementRef = useRef(null);
    const navigate = useNavigate();
    const [attributes, setAttributes] = useState([]);

    // TODO PMG - Image deletion posponed for now
    //const [uploadedImages, setUploadedImages] = useState([]);
    
    const handleResourceRedirection = async () => {
        try {
            const newTab = window.open(`/skill/${currentTitle}`, '_blank');

            if (newTab) {
                newTab.focus();
            } else {
                console.error('{OwnerRTE} Failed to open new tab');
            }
        } catch (error) {
            console.error(`{OwnerRTE} handleResourceRedirection() Error Response: }`, error);
        }
    };  

    const handleSaveContentButtonPress = (buttonRef) => {
        handleSaveContent(content);
    }

    /**
     * Save the current content in the editor.
     * 
     * @param {string} contentToSave - formatted content to save
     * @returns 
     */
    const handleSaveContent = async (contentToSave) => {
        if (!contentToSave || !initialLoadFinished) {
            setWaitingOn("");
            return;
      };
        setSaving(true);
        try {
            /* Send request to save the content */
            const response = await sendEndpointRequest( SkillEndpoints.endpoints.saveSkillContent,
                                                        null,
                                                        {content: contentToSave,
                                                        skillId: id},
                                                        null);

            /* Check success status of the response and ensure content was not modified */
            if(response?.success && response?.data === contentToSave) {
                setContent(response.data);
            } else {
                /* Handle API errors */
                handleSaveContentError(response);

                setTitleInput(currentTitle);
            }

        /* Handle local errors */
        } catch (error) {
            console.error(`{OwnerRTE} Save Skill Content Error Response: `, error);
            toast.error("An unknown error occurred while saving the skill content.");
        } finally {
            /* Artificially delay UI saving state so that user has enough
               time to perceive the save */
            await sleep(800);

            setSaving(false);
            setWaitingOn("");
        }
    };  

    /**
     * Handle errors that occur while saving the content. Parses the common response object
     * for API error codes and displays a toast message to the user.
     * 
     * @param {GResponseCommonObject} response 
     * @returns 
     */
    const handleSaveContentError = (response) => {
        switch(response.error?.code) {
            case ErrorType.ERR_INPUT_VALIDATION_INVALID_INPUT:
                toast.error("Invalid input. Please check your content and try again.");
                break;
            
            default:
                toast.error("Error code: " + response.error?.code);
                break;
        }
    };

    const handleUpdateTitle = async (titleToSave) => {

        /* Warn owner that changing the title will change the URL */
        if (attributes?.isPublic && !window.confirm("Changing the title will change the URL. This means that links that "
                          + " references this skill's content will be broken until updated. Do you want to proceed?")) {
            return;
        }

        if (!titleToSave || titleToSave === "" || titleToSave === currentTitle) {
            setWaitingOn("");
            return;
        }
        setSaving(true);

        try {
            const response = await sendEndpointRequest( SkillEndpoints.endpoints.updateSkillTitle,
                                                        {replacement: titleToSave},
                                                        null,
                                                        {"skill-title": currentTitle});

            if (response?.success && response?.data) {
                setCurrentTitle(titleToSave);
                
                navigate(`/skill-manager/${titleToSave}`);
            } else {
                handleTitleChangeErrors(response);

                setTitleInput(currentTitle);
            }
            
            
            console.log(`{OwnerRTE} Save Skill Title Success Response: `, response);
            await new Promise(resolve => setTimeout(resolve, 1000));
            

        } catch (error) {
            console.error(`{OwnerRTE} Save Skill Title Error Response: `, error);
            await new Promise(resolve => setTimeout(resolve, 1000));

        } finally {
            setSaving(false);
            setWaitingOn("");
        }
    };

    const handleTitleChangeErrors = (response) => {
        switch(response.error?.code) {
            case ErrorType.ERR_RELATION_DUPLICATE_MEMBER:
                toast.error("FAILED UPDATE: Title cannot be the same as another skill's title.");
                break;
            
            /* Owner facing UI so just show the error code because it might 
               be easier to debug */
            default:
                toast.error("Error code: " + response.error?.code);
                break;
        }
    }

    const handleTitleChange = (event) => {
        if (waitingOn === "content") return;
        setWaitingOn("title");
        const data = event.target.value;
        setTitleInput(data);

        if (typingTimeoutRef.current) {
            clearTimeout(typingTimeoutRef.current);
        }

        typingTimeoutRef.current = setTimeout(() => {
            handleUpdateTitle(data);
        }, 1500);

    };

    const handleChange = (event, editor) => {
        if (waitingOn === "title") return;
        setWaitingOn("content");
        const data = editor.getData();
        setContent(data); 

        if (typingTimeoutRef.current) {
            clearTimeout(typingTimeoutRef.current);
        }

        typingTimeoutRef.current = setTimeout(() => { 
            handleSaveContent(data);
        }, 1000); 
        
    };

    /**
     * Store an image used in the content editor.
     * 
     * @param {File} file 
     * @returns 
     */
    const uploadImage = async (file) => {
        const formData = new FormData();
        formData.append('image', file);

        const response = await sendEndpointRequestFile(SkillEndpoints.endpoints.uploadSkillImage, null, formData, null);
        if (!response) {
            toast.error("Image upload unsuccessful!");
        }
    
        return response;
    };

    // TODO PMG - Image deletion posponed for now
    // const deleteImageFromServer = async (imageUrl) => {
    //     setLoading(true);

    //     if (!imageUrl) {
    //         return;
    //     }

    //     try {
    //         const imageKey = imageUrl.split('amazonaws.com/')[1];  // Extract image key from URL
    //         await axios.put(`${config.url.API_BASE_URL}/api/skill/delete-image`, null, {
    //             params: { 'image-key': imageKey },
    //             headers: { 'Authorization': `Bearer ${getToken()}` }
    //         });
    //     } catch (error) {
    //         console.error("{OwnerRTE} Delete Image Error Response: ", error);
    //     } finally {
    //         setLoading(false);
    //     }
    // };

    const onEditorReady = (editor) => {
        console.log("Editor is ready", editor);
        
        // TODO PMG - Image deletion posponed for now
        // const deleteCommand = editor.commands.get('delete');
        // const deleteForwardCommand = editor.commands.get('deleteForward');  // forward delete (backspace)
    
        // if (deleteCommand) {
        //     deleteCommand.on('execute', () => {
        //         console.log("Delete command executed");
        //         handleImageDelete(editor);
        //     });
        // }
    
        // if (deleteForwardCommand) {
        //     deleteForwardCommand.on('execute', () => {
        //         console.log("DeleteForward command executed");
        //         handleImageDelete(editor);
        //     });
        // }
    };

    // TODO PMG - Image deletion posponed for now
    // const handleImageDelete = (editor) => {
    //     console.log("{OwnerRTE} handleImageDelete");
    //     console.log("{OwnerRTE} editor: ", editor);

    //     const selection = editor.model.document.selection;
    //     const selectedElement = selection.getSelectedElement();
    //     console.log("{OwnerRTE} selection: ", selection);
    //     console.log("{OwnerRTE} selectedElement: ", selectedElement);
        

    //     // Check if there are ranges selected
    //     const selectedRanges = selection.getRanges();
    //     console.log("{OwnerRTE} selectedRanges: ", selectedRanges);

    //     if (selectedRanges.length > 0) {
    //         selectedRanges.forEach(range => {
    //             const startNode = range.start.nodeAfter;
    //             const endNode = range.end.nodeBefore;

    //             console.log("{OwnerRTE} startNode: ", startNode);
    //             console.log("{OwnerRTE} endNode: ", endNode);

    //             // Check if the start or end node is an image
    //             if (startNode && startNode.is('element', 'image')) {
    //                 const imageUrl = startNode.getAttribute('src');
    //                 console.log("{OwnerRTE} Image selected for deletion:", imageUrl);
    //                 setExplicitlyDeletedImages((prev) => [...prev, imageUrl]);
    //                 deleteImageFromServer(imageUrl);
    //             } else if (endNode && endNode.is('element', 'image')) {
    //                 const imageUrl = endNode.getAttribute('src');
    //                 console.log("{OwnerRTE} Image selected for deletion:", imageUrl);
    //                 setExplicitlyDeletedImages((prev) => [...prev, imageUrl]);
    //                 deleteImageFromServer(imageUrl);
    //             } else {
    //                 console.log("{OwnerRTE} Text deleted, but no image involved.");
    //             }
    //         });
    //     } else {
    //         console.log("No selection made.");
    //     }
    // };
    
    class MyUploadAdapter {
        constructor(loader) {
            this.loader = loader;
        }

        upload() {
            return this.loader.file.then((file) => {
                return new Promise((resolve, reject) => {
                    setLoading(true);
                    uploadImage(file)
                      .then((result) => {
                        resolve({ default: result.data });
                        })
                        .catch((error) => {
                            console.error("{OwnerRTE} Upload Image Error: ", error);
                            reject(error);
                        })
                        .finally(() => {
                            setLoading(false);
                        });
                });
            });
        }

        abort() {}
    }

    function CustomUploadAdapterPlugin(editor) {
        editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
            return new MyUploadAdapter(loader);
        };
    }

    const fetchContent = async () => {
        if (!skillTitle) return;

        setLoading(true);
        try {
            const response = await sendEndpointRequest(SkillEndpoints.endpoints.getSkillContext, null, null, {"skill-title": skillTitle});
    
            if (!response.success) {
                /* First fetch fails for some reason? Don't show an error
                   just return. This should be investigated */
                return;
            }

            const newContent = response.data.txtContent || '';
            setContent((prevContent) => newContent !== prevContent ? newContent : prevContent);
            console.log("{OwnerRTE} Fetch Content Success: ", response);
            setId(response.data.id);
            setCurrentTitle(skillTitle);
            setTitleInput(skillTitle);
            setAttributes(response.data.attributes);
    
        } catch (error) {
            console.error("{OwnerRTE} Fetch Content Error: ", error);
        } finally {
            await new Promise(resolve => setTimeout(resolve, 1000));
            setLoading(false);
            setInitialLoadFinished(true);
        }
    };

    useEffect(() => {
        fetchContent();
    }, [skillTitle]);

    useEffect(() => {
        const handleResize = debounce(() => {
            console.log('Resized:', elementRef.current.offsetWidth);
        }, 100);

        const resizeObserver = new ResizeObserver(handleResize);
        if (elementRef.current) {
            resizeObserver.observe(elementRef.current);
        }

        return () => {
            resizeObserver.disconnect();
        };
    }, []);

    return (
        <div className="layout-container">
            {/* Editor section (Header and Editor) */}
            <div className="editor-content">
                {/* Editor header bar */}
                <div className="header-container">
                    <div className="title-editor">
                        <input
                            className="title-editor-text"
                            type="text"
                            value={titleInput}
                            onChange={handleTitleChange}
                            disabled={loading || saving || waitingOn === "content"}
                        />
                    </div>
                    <button
                        style={{ width: '130px', height: '50px' }}
                        className="right-button"
                        onClick={handleSaveContentButtonPress}
                        disabled={loading || saving}
                    >
                        {loading ? 'Loading..' : saving ? 'Saving..' : 'Save'}
                    </button>
                </div>
    
                {/* Editor */}
                <CKEditor
                    editor={ClassicEditor}
                    data={content}
                    onChange={handleChange}
                    onReady={onEditorReady}
                    disabled={loading || waitingOn === "title"}
                    config={{
                        extraPlugins: [CustomUploadAdapterPlugin],
                        toolbar: [
                            'heading', '|', 'bold', 'italic', 'underline', '|',
                            'link', 'bulletedList', 'numberedList', '|',
                            'blockQuote', 'imageUpload', '|', 
                            'undo', 'redo', 'removeFormat'
                        ],
                    }}
                />
            </div>
    
            {/* Widget bar */}
            <div className="widget-bar">
                <button
                    className="right-button"
                    onClick={handleResourceRedirection}
                    disabled={loading || saving}
                >
                    How the user will see this page
                </button>
                <p>
                    link: <a href={`${config.url.UI_BASE_URL}/skill/${skillTitle}`} target="_blank" rel="noopener noreferrer">
                        {config.url.UI_BASE_URL}/skill-resource/{skillTitle}
                    </a>
                </p>
            </div>
        </div>
    );
    
};

export default OwnerRTE;
