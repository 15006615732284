import React, { useEffect, useState } from "react";
import { Loading } from "../index";
import { confirmEmail } from "../../api/password";
import { useNavigate, useSearchParams } from "react-router-dom";
import { CssBaseline, Typography } from "@mui/material";

export default function ConfirmEmail() {
  const [searchParams, ___] = useSearchParams();
  const [error, setError] = useState();
  const navigate = useNavigate();
  const validateToken = async (token) => {
    try {
      await confirmEmail(token);
      navigate("/login?confirm=true");
    } catch (e) {
      setError(e);
    }
  };

  useEffect(() => {
    const token = searchParams.get("token");
    if (token) {
      validateToken(token);
    } else {
      setError("Empty Token");
    }
  }, []);

  return (
    <>
      <CssBaseline />
      {!error && (
        <>
          <Typography variant="h3">Validating your token</Typography>
          <Loading />
        </>
      )}
      {error && (
        <>
          <Typography variant="h3">Invalid Token</Typography>
          <p>{error.message}</p>
        </>
      )}
    </>
  );
}
