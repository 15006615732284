import axios from 'axios';
import { config } from '../Constants';


export const sendPassRecoveryEmail = async (username) => {
    const headers = {
      Accept: "*/*",
      "Content-Type": "application/json"
    };
    const queryParams = {
      email: username
    };
    
    const url = `${config.url.API_BASE_URL}/api/resetPassword?email=${queryParams.email}`;
    console.log("URL", url);
    try {
      const response = await axios.post(url, null, { headers });
      console.log(response);
      return response.data;
    } catch (err) {
      console.error('Recovery failed:', err);
      throw err;
    }
}

export const authenticatedPasswordChange = async (url, c_pass, new_pass0) => {
  const token = localStorage.getItem('token').toString();
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "*/*",
    "Content-Type": "application/json",
  };

  const body = {
    currentPassword: c_pass,
    newPassword: new_pass0,
  };

  console.log("URL ", url, "Headers: ", headers, "Body ", body);

  try {
    const response = await axios.post(url, body, { headers });
    console.log(response);
    return response.data;
  } catch (err) {
    console.error('Recovery failed:', err);
    throw err;
  }
};

export const confirmEmail = async (token) => {
  const headers = {
    Accept: "*/*",
    "Content-Type": "application/json",
  };

  const body = {
    token
  };

  console.log("URL ", '/api/registration/confirmRegistration', "Headers: ", headers, "Body ", body);

  try {
    const response = await axios.post(`${config.url.API_BASE_URL}/api/registration/confirmRegistration`, body, { headers });
    console.log(response);
    return response.data;
  } catch (err) {
    console.error('Recovery failed:', err);
    throw err;
  }
};

export const recoverAccount = async (token, new_pass0) => {
  const headers = {
    Accept: "*/*",
    "Content-Type": "application/json",
  };

  const body = {
    token: token,
    newPassword: new_pass0
  };

  try {
    const response = await axios.post(`${config.url.API_BASE_URL}/api/resetPassword/savePassword`, body, { headers });
    console.log(response);
    return response.data;
  } catch (err) {
    console.error('Recovery failed:', err);
    throw err;
  }
};

