import React, { useState, useEffect, useContext } from "react";
import { Box, Typography, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import CircularProgress from '@mui/material/CircularProgress';
import { UserContext } from "../../provider";
import { useParams } from 'react-router-dom';
import { sendEndpointRequestExtended } from "../../iop/iop";
import { SkillEndpoints } from "../../api/Endpoints";
import { SkillRequestPublishEvidence, SkillRequestPublishSkill } from "../../api/skill";
import { ErrorType } from "../../api/APIErrors";
import { toast } from "react-toastify";
import { useNavigate } from 'react-router-dom';


const useStyles = makeStyles((theme) => ({
  centeredContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '200px',
    backgroundColor: '#f9f9f9',
    borderRadius: '8px',
    padding: theme.spacing(3),
  },
  loadingText: {
    color: theme.palette.primary.main,
    marginBottom: theme.spacing(2),
  },
  loadingIcon: {
    color: theme.palette.primary.main,
  },
  editorContainer: {
    '& .ck-editor__main': {
      border: 'none !important',
      boxShadow: 'none !important',
      outline: 'none !important',
    },
    '& .ck-editor__editable': {
      border: 'none !important',
      boxShadow: 'none !important',
      outline: 'none !important',
      backgroundColor: 'transparent',
    },
  },
  container: {
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(2),
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    gap: theme.spacing(2),
  },
  attributesPanel: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    padding: theme.spacing(2),
    minWidth: '10em',
  },
  statusRow: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
  },
  publicDot: {
    width: '12px',
    height: '12px',
    borderRadius: '50%',
    backgroundColor: 'green',
    marginRight: theme.spacing(1),
  },
  privateDot: {
    width: '12px',
    height: '12px',
    borderRadius: '50%',
    backgroundColor: 'orange',
    marginRight: theme.spacing(1),
  },

}));

const SkillViewComponent = ({
  skillTitle: propSkillTitle,
  evidenceTitle: propEvidenceTitle,
  userEmail: propUserEmail,
}) => {
  const PageDisplayMode = {
    PAGE_DISPLAY_MODE__SKILL: 'SKILL',   /* Displaying a skill */
    PAGE_DISPLAY_MODE__EVIDENCE: 'EVIDENCE', /* Displaying evidence */
  };

  const PageRoleMode = {
    PAGE_ROLE_MODE__OWNER:   'OWNER',     /* An owner is viewing the page   */
    PAGE_ROLE_MODE__USER:    'USER',      /* A user is viewing the page     */
    PAGE_ROLE_MODE__DEFAULT: 'DEFAULT',   /* A non-user is viewing the page */
  };

  const [roleMode, setRoleMode] = useState(PageRoleMode.PAGE_ROLE_MODE__DEFAULT);
  const [displayMode, setDisplayMode] = useState(null);

  const { user } = useContext(UserContext);
  const classes = useStyles();
  const [htmlContent, setHtmlContent] = useState('');
  const [loading, setLoading] = useState(true);
  const [attributes, setAttributes] = useState(null);
  const [isPublic, setIsPublic] = useState(false);
  const [id, setId] = useState(0);
  const [open, setOpen] = useState(false);
  const [publishing, setPublishing] = useState(false);
  const navigate = useNavigate();
  const [authorized, setAuthorized] = useState(false);
  const [ userOwnsEvidence, setUserOwnsEvidence ] = useState(false);

  const params = useParams();
  const [skillTitle, setSkillTitle] = useState(propSkillTitle);
  const [evidenceTitle, setEvidenceTitle] = useState(propEvidenceTitle);
  const [userEmail, setUserEmail] = useState(propUserEmail);

  const fetchSkillData = async () => {
    try {
      var response; 

      if (evidenceTitle) {
        response = await sendEndpointRequestExtended({ authorized }, SkillEndpoints.endpoints.getEvidenceContext, null, null, { "user-name" : userEmail, "skill-title": skillTitle, "content-title": evidenceTitle });

        /* Set the display mode to Evidence */
        setDisplayMode(PageDisplayMode.PAGE_DISPLAY_MODE__EVIDENCE);
  
      /* Check if the user owns the evidence */
      if (response.data.publiclyViewable != null) {
        setUserOwnsEvidence(true);
      }

      } else {
        /* Otherwise assume a skill is supposed to be displayed */
        const pathVars = {
          "user-name": userEmail,
          "skill-title": skillTitle,
          "content-title": evidenceTitle
        };

        response = await sendEndpointRequestExtended({ authorized }, SkillEndpoints.endpoints.getSkillContext, null, null, pathVars);

        /* Set the display mode to Skill */
        setDisplayMode(PageDisplayMode.PAGE_DISPLAY_MODE__SKILL);
      }

      if (!(response?.success || response?.status === 200) || !response?.data) {
        throw new Error(`Failed to get user skill evidence: ERROR: ${response.error?.code || 'Unknown error'}`);
      }
      if (response.data.txtContent) {
        setHtmlContent(response.data.txtContent);
      }
      if (response.data.attributes) {
        setRoleMode(PageRoleMode.PAGE_ROLE_MODE__OWNER);

        setAttributes(response.data.attributes);
        setIsPublic(response.data.attributes.isPublic);
      } else if(user.email) {
        setRoleMode(PageRoleMode.PAGE_ROLE_MODE__USER);
        setIsPublic(response.data.publiclyViewable);
      } else {
        setRoleMode(PageRoleMode.PAGE_ROLE_MODE__DEFAULT);
      }

      if(response.data.id) {
        setId(response.data.id)
      }

    } catch (error) {
      console.error('{SkillResource} Error fetching skill data:', error);
    } finally {
      setLoading(false);
    }
  };

  /**
   * Handles the publish button click event
   * 
   */
  const handlePublish = () => {
    setOpen(true);
  };

  /**
   * Navigates to the evidence list page
   */
  const navigateToEvidenceListPage = () => {
    navigate(`/user-skill/${userEmail}/${skillTitle}`)
  }

  /**
   * Handles the publish confirmation button click event
   * 
   */
  const handleConfirmPublish =  async () => {
    setPublishing(true);
  
    var response;
  
    /* If this is evidence content we are displaying publish
       the evidence, otherwise assume it is a skill */
    if(evidenceTitle){
      response = await SkillRequestPublishEvidence(evidenceTitle, user.email, skillTitle);
    } else{
      response = await SkillRequestPublishSkill(skillTitle);
    }

    /* Handle errors or reload the page upon successful
       response */
    if (!response?.success) {
      handlePublishSkillErrors(response);
      setPublishing(false);
    } else {
      window.location.reload();
    }
  };

  /**
   * Handles the errors that occur when publishing a skill. Displays a
   * react toast with a user friendly message
   *
   */
  const handlePublishSkillErrors = (response) => {
    switch(response?.error?.code) {
      default:
      case ErrorType.ERR_UNKNOWN:     /* intentional fallthrough */
      case ErrorType.API_ERROR_SERVER_INTERNAL:
        toast.error('Internal server error. Please try again later.');
        break;
      
      case ErrorType.API_ERROR_GENERIC_ACCESS_DENIED: 
        toast.error('Access denied. Please try again later.');
        break;
      }
  }

  /**
   * Handles the cancel publish button click event
   * (closes the dialog)
   */
  const handleCancelPublish = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (user) {
      setAuthorized(true);
      setUserEmail(user.email);
    }

    if (!skillTitle && params.skillTitle) setSkillTitle(params.skillTitle);
    if (!evidenceTitle && params.evidenceTitle) setEvidenceTitle(params.evidenceTitle);
    if (!userEmail && !user?.email && params.userEmail) setUserEmail(params.userEmail);

    fetchSkillData();
  }, [params, skillTitle, evidenceTitle, userEmail, user]);


  return (
    <Box className={classes.container}>
      <Box className={classes.contentContainer}>
        {/* Conditionally render the publish button */}
        { ( ( roleMode === PageRoleMode.PAGE_ROLE_MODE__OWNER && displayMode == PageDisplayMode.PAGE_DISPLAY_MODE__SKILL ) || roleMode === PageRoleMode.PAGE_ROLE_MODE__USER  && displayMode == PageDisplayMode.PAGE_DISPLAY_MODE__EVIDENCE ) && (!isPublic) && (
          <Button 
            className={classes.publishButton} 
            onClick={handlePublish}
            sx={{
              backgroundColor: "#333",
              color: "#fff",
              padding: "10px 20px",
              border: "none",
              borderRadius: "5px",
              cursor: "pointer",
              fontWeight: "bold",
              textTransform: "uppercase",
              width: "8em",
              display: "block",
              justifyContent: "flex-start",
              "&:hover": {
                backgroundColor: "#000",
              },
            }}
            >
            Publish
          </Button>
        )}
        { ( ( displayMode === PageDisplayMode.PAGE_DISPLAY_MODE__SKILL )
            && ( roleMode === PageRoleMode.PAGE_ROLE_MODE__USER || roleMode === PageRoleMode.PAGE_ROLE_MODE__OWNER ) )
           && (
          <Button 
            className={classes.publishButton} 
            onClick={navigateToEvidenceListPage}
            sx={{
              backgroundColor: "#cc34eb",
              color: "#fff",
              padding: "10px 20px",
              border: "none",
              borderRadius: "5px",
              cursor: "pointer",
              fontWeight: "bold",
              textTransform: "uppercase",
              width: "12em",
              display: "block",
              justifyContent: "flex-start",
              "&:hover": {
                backgroundColor: "#ec54eb",
              },
            }}
            >
            My Evidence
          </Button>
        )}
        {/* { ( 
           ( userOwnsEvidence )
           && ( displayMode === PageDisplayMode.PAGE_DISPLAY_MODE__EVIDENCE )
           && ( roleMode === PageRoleMode.PAGE_ROLE_MODE__USER || roleMode === PageRoleMode.PAGE_ROLE_MODE__OWNER ) )
           && (
          <Button 
            className={classes.publishButton} 
            onClick={navigateToEvidenceListPage}
            sx={{
              backgroundColor: "#cc34eb",
              color: "#fff",
              padding: "10px 20px",
              border: "none",
              borderRadius: "5px",
              cursor: "pointer",
              fontWeight: "bold",
              textTransform: "uppercase",
              width: "12em",
              display: "block",
              justifyContent: "flex-start",
              "&:hover": {
                backgroundColor: "#ec54eb",
              },
            }}
            >
            Edit
          </Button>
        )} */}

        <Typography 
          sx={{ 
            textAlign: 'left', 
            fontSize: '7em',
            fontWeight: 900,
            fontFamily: 'Arial, sans-serif', 
          }} 
          component="h1" 
          variant="h1">
          {evidenceTitle? evidenceTitle: skillTitle}
        </Typography>

        {loading ? (
          <Box className={classes.centeredContainer}>
            <Typography className={classes.loadingText}>Loading...</Typography>
            <CircularProgress />
          </Box>
        ) : (
          <div className={classes.editorContainer}>
            <CKEditor
              editor={ClassicEditor}
              data={htmlContent}
              disabled={true}
              config={{
                toolbar: [],
              }}
            />
          </div>
        )}
      </Box>
      <Box className={classes.attributesPanel}>
      {roleMode === PageRoleMode.PAGE_ROLE_MODE__OWNER && (
        isPublic ? (
          <div className={classes.statusRow}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <div className={classes.publicDot}></div>
                <Typography sx={{ marginLeft: "4px" }}>Public</Typography>
              </div>
              <Typography
                sx={{
                  fontSize: "0.75rem",
                }}
              >
                Anyone can see this
              </Typography>
            </div>
          </div>
        ) : (
          <div className={classes.statusRow}>
            <div className={classes.privateDot}></div>
            <Typography>Private</Typography>
          </div>
        )
      )}
      </Box>

      {/* Confirmation Dialog */}
      <Dialog open={open} onClose={handleCancelPublish}>
      {!publishing && <DialogTitle>Confirm Publish</DialogTitle>}
        {!publishing &&<DialogContent>
          <DialogContentText>
            Are you sure you want to publish this content? Once published, it will be publicly viewable.
          </DialogContentText>
        </DialogContent>}
        {!publishing && <DialogActions>
          <Button onClick={handleCancelPublish} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirmPublish} color="primary" autoFocus>
            Publish
          </Button>
        </DialogActions>}
        {publishing && <DialogTitle>Publishing</DialogTitle>}
        {publishing &&<DialogContent>
          <DialogContentText>
            This may take a bit...
          </DialogContentText>
        </DialogContent>}
      </Dialog>
    </Box>
  );
};

export default SkillViewComponent;