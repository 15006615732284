import React, { useContext } from "react";
import { DashboardContext, UserContext } from "../../provider";
import { useOrgLinks } from "../../action/dashboardActions";
import useStyles from "../../styles/styles";
import theme from "../../styles/theme";
import { ThemeProvider } from "@mui/material/styles";
import { Box, Button, CssBaseline, Typography, styled } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import "../../styles/assets/css/main.css";

export default function Users() {
  const classes = useStyles();
  const { board } = useContext(DashboardContext);
  const { handleExecuteLink } = useOrgLinks();
  const { user } = useContext(UserContext);

  const StyledTableRow = styled(TableRow)`
    &:nth-of-type(odd) {
      background-color: ${({ theme }) =>
        theme.palette.action.hover}; // accessing the theme
    }
    &:nth-of-type(even) {
      background-color: "grey";
    }
  `;

  return (
    <>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {user.roles.includes("ROLE_OWNER") && (
          <Box>
            <TableCell>
              <Button
                variant="contained"
                onClick={() =>
                  handleExecuteLink(
                    board._links["Unaffiliated///Role///Users"].href
                  )
                }
              >
                Unaffiliated Role Users
              </Button>
            </TableCell>
          </Box>
        )}
        <Typography className={classes.TableTitle} variant="h3">
          Users
        </Typography>
        <Table stickyHeader size="small">
          <TableHead>
            <TableRow>
              <TableCell>
                <Typography variant="h6">Email</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h6">Actions</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {board.dash.Users?.map((user) => (
              <StyledTableRow key={user.email}>
                <TableCell>{user.email}</TableCell>
                <TableCell>
                  {Object.keys(user._links || {}).map((linkKey) => (
                    <Button
                      key={linkKey}
                      className={classes.button}
                      onClick={() =>
                        handleExecuteLink(user._links[linkKey]?.href)
                      }
                      variant="contained"
                      sx={{ m: 1 }}
                      style={{
                        backgroundColor: user._links[linkKey]?.href.includes(
                          "delete"
                        )
                          ? "var(--genics-red)"
                          : ""
                      }}
                    >
                      {linkKey.replace(/\/\/\//g, " ")}
                    </Button>
                  ))}
                </TableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </ThemeProvider>
    </>
  );
}
