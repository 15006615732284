import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { getRequest } from "../../api/helper";
import { Grid, Box, CssBaseline, Typography, Button } from "@mui/material";
import useStyles from "../../styles/styles";
import theme from "../../styles/theme";
import { ThemeProvider } from "@mui/material/styles";
import BadgeImage from "../BadgeImage";
import "../../styles/component.css";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";

const EndorsementsReport = () => {
  const classes = useStyles();
  const { state } = useLocation();
  const { url } = state;
  const [report, setReport] = useState([]);

  const fetchReportData = async () => {
    try {
      const response = await getRequest(url);
      const report = await response.report;
      setReport(report);
    } catch (error) {
      console.error("Error fetching not hidden:", error);
    }
  };

  const printItems = () => {
    window.print();
  };

  const handleExportPDF = () => {
    const input = document.getElementById("componentToExport");

    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF();
      const imgWidth = 210; // mm
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      pdf.addImage(imgData, "PNG", 0, 0, imgWidth, imgHeight);
      pdf.save("myComponent.pdf");
    });
  };

  useEffect(() => {
    fetchReportData();
  }, []);

  if (report.length === 0) {
    return (
      <div className={classes.CentralizeDiv}>
        <Typography variant="h3" color="primary" my={5}>
          Report is currently empty
        </Typography>
      </div>
    );
  }

  return (
    <div>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Box className={classes.CentralizeDiv}>
          <Grid
            container
            spacing={1}
            direction="row"
            alignItems="center"
            sx={{
              borderBottom: 1,
              borderColor: "#26408b",
            }}
          >
            <Grid item sx={{ minWidth: "100%" }}>
              <Grid item>
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <Box
                    displayPrint="none"
                    sx={{ display: "flex", justifyContent: "center" }}
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={printItems}
                      sx={{
                        marginLeft: 5,
                        marginRight: 5,
                        marginTop: 7,
                        marginBottom: 7,
                      }}
                    >
                      Print Report
                    </Button>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Grid>

          <Grid id="componentToExport" container spacing={2} direction="column">
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                borderBottom: 1,
                borderColor: "#26408b",
              }}
            >
              <Typography
                variant="h4"
                textAlign={"center"}
                color="primary"
                my={3}
                p={3}
                sx={{ fontWeight: "bold" }}
              >
                GENICS - Endorsement Report
              </Typography>
            </Box>
            {report.map((badge) => (
              <Grid
                item
                sx={{
                  borderBottom: 1,
                  borderColor: "#26408b",
                  marginTop: 3,
                }}
              >
                <Grid container spacing={2} direction="row">
                  <Grid item>
                    <Box
                      sx={{
                        display: "block",
                        alignItems: "center",
                        marginRight: 3,
                        paddingBottom: 5,
                        maxWidth: "1500px",
                      }}
                    >
                      <Box>
                        <BadgeImage badge={badge.badgeDetails} size="70px" />
                      </Box>
                      <Box sx={{ marginLeft: 3 }}>
                        <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                          {badge?.badgeDetails?.title}
                        </Typography>
                        <Typography variant="h7">
                          {badge?.badgeDetails?.description}
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item>
                    <Grid container spacing={2} direction="column">
                      {badge.endorsementDtos.map((endorsement) => (
                        <Grid container spacing={2} direction="row">
                          <Grid item display="inline">
                            <Box
                              sx={{
                                paddingBottom: 3,
                                paddingLeft: 2,
                                width: "300px",
                              }}
                            >
                              <Typography
                                textAlign={"left"}
                                variant="h7"
                                display="block"
                                gutterBottom
                              >
                                <Typography
                                  display="inline"
                                  sx={{ fontWeight: "bold" }}
                                >
                                  Name:{" "}
                                </Typography>
                                {endorsement.firstName} {endorsement.lastName}
                              </Typography>
                              <Typography
                                textAlign={"left"}
                                variant="h7"
                                display="block"
                                gutterBottom
                              >
                                <Typography
                                  display="inline"
                                  sx={{ fontWeight: "bold" }}
                                >
                                  Email:{" "}
                                </Typography>
                                {endorsement.email}
                              </Typography>
                              <Typography
                                textAlign={"left"}
                                variant="h7"
                                display="block"
                                gutterBottom
                              >
                                <Typography
                                  display="inline"
                                  sx={{ fontWeight: "bold" }}
                                >
                                  Date:{" "}
                                </Typography>
                                {endorsement.updatedAt}
                              </Typography>
                              <Typography
                                textAlign={"left"}
                                variant="h7"
                                display="block"
                                gutterBottom
                              >
                                <Typography
                                  display="inline"
                                  sx={{ fontWeight: "bold" }}
                                >
                                  Endorsement:{" "}
                                </Typography>
                                {endorsement.endorsementBody}
                              </Typography>
                            </Box>
                          </Grid>
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            ))}
          </Grid>
        </Box>
      </ThemeProvider>
    </div>
  );
};

export default EndorsementsReport;
