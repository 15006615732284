import React from "react";
import { CssBaseline } from "@mui/material";
import SignIn from "../utils/SignIn";

import logo from "../../images/skills-log-logo.png";

const Login = () => {
  return (
    <>
      <CssBaseline />
      <div style={{ display: "flex", justifyContent: "center", paddingTop: -20 }}>
        <img src={logo} alt="Skills Log Logo" style={{ width: 400, height: 'auto' }} />
      </div>
      <div style={{ marginTop: -100 }}>
        <SignIn />
      </div>    </>
  );
};

export default Login;
