import React, { useContext } from "react";
import { DashboardContext } from "../../provider";
import { useOrgLinks } from "../../action/dashboardActions";
import useStyles from "../../styles/styles";
import theme from "../../styles/theme";
import { ThemeProvider } from "@mui/material/styles";
import { Button, Box, CssBaseline, Typography, styled } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import BadgeImage from "../BadgeImage";

export default function OrgBadges() {
  const classes = useStyles();
  const { board } = useContext(DashboardContext);
  const { handleExecuteLink } = useOrgLinks();

  // Determine header text based on board, dash, and badges
  let headerText;
  if (!board || !board.dash || !board.dash.Badges) {
    headerText = "Organization Information Cannot Be Loaded";
  } else {
    headerText = "Organization Badges";
  }

  const StyledTableRow = styled(TableRow)`
    &:nth-of-type(odd) {
      background-color: ${({ theme }) =>
        theme.palette.action.hover}; // accessing the theme
    }
    &:nth-of-type(even) {
      background-color: "grey";
    }
  `;

  return (
    <>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Typography className={classes.TableTitle} variant="h3">
          {headerText}
        </Typography>
        <Table>
          <TableBody>
            {!board || !board.dash || !board.dash.Badges ? (
              <TableRow>
                <TableCell colSpan={2}>
                  <Typography variant="h6">
                    If this issue persists, please contact your organization
                    admin.
                  </Typography>
                </TableCell>
              </TableRow>
            ) : board.dash.Badges.length === 0 ? (
              <TableRow>
                <TableCell colSpan={2}>
                  <Typography variant="h6">
                    It looks like this organization doesn't have any badges yet.
                    Please check back later or contact your organization's admin
                    for more information.
                  </Typography>
                </TableCell>
              </TableRow>
            ) : (
              board.dash.Badges.map((badge) => (
                <StyledTableRow key={badge.title}>
                  <TableCell>
                    <BadgeImage key={badge.email} badge={badge} />
                  </TableCell>
                  <TableCell>
                    <Box>
                      <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                        {badge.title}
                      </Typography>
                      <Typography variant="h6">{badge?.description}</Typography>
                      <Typography variant="h6">
                        Minimum endorsements: {badge?.minimumEndorsements}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell>
                    {Object.keys(badge._links || {}).map((linkKey) => (
                      <Button
                        className={classes.button}
                        onClick={() =>
                          handleExecuteLink(badge._links[linkKey]?.href)
                        }
                        variant="contained"
                        sx={{ m: 1 }}
                        key={linkKey}
                      >
                        {linkKey.replace(/\/\/\//g, " ")}
                      </Button>
                    ))}
                  </TableCell>
                </StyledTableRow>
              ))
            )}
          </TableBody>
        </Table>
      </ThemeProvider>
    </>
  );
}
