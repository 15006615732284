import React, { useState, useContext, useEffect } from "react";
import { UserContext, DashboardContext } from "../../provider";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { config } from "../../Constants";
import useStyles from "../../styles/styles";
import theme from "../../styles/theme";
import { ThemeProvider } from "@mui/material/styles";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import "../../styles/assets/css/main.css";

import {
  Button,
  CssBaseline,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
} from "@mui/material";
import { toast } from "react-toastify";

const NoOrgUsers = () => {
  const classes = useStyles();
  const { state } = useLocation();
  const { url } = state;

  const [orgName, setOrgName] = useState("");
  const [nonOrgUsers, setNonOrgUsers] = useState([]);
  const [organizations, setOrganizations] = useState([]);
  const { loadOrganizations, loadBadges } = useContext(DashboardContext);
  const { setErrors, errors, navigate } = useContext(UserContext);
  const [loading, setLoading] = useState(true);
  const [selectedUsers, setSelectedUsers] = useState([]);

  const loadNonOrgUsers = async () => {
    const token = localStorage.getItem("token").toString();
    const headers = {
      Authorization: `Bearer ${token}`,
      Accept: "*/*",
      "Content-Type": "application/json",
    };

    try {
      console.log("URL: ", url);
      const response = await axios.get(url, { headers });
      console.log("No-Org-Users: ", response);
      setNonOrgUsers(response.data);
      loadBadges();
      setLoading(false);
    } catch (err) {
      console.error("Loading NonOrgUsers failed:", err);
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchOrganizations = async () => {
      const headers = {
        Accept: "*/*",
        "Content-Type": "application/json",
      };

      try {
        const response = await axios.get(
          `${config.url.API_BASE_URL}/api/registration/organizations`,
          { headers }
        );
        setOrganizations(response.data);
        setLoading(false);
      } catch (err) {
        setErrors(err);
        console.error("Loading Organizations failed:", err);
        setLoading(false);
      }
    };

    loadNonOrgUsers();
    fetchOrganizations();
  }, []);

  const handleExecuteLink = async () => {
    if (selectedUsers.length === 0 || orgName === "") {
      setErrors("Selected Users or Selected Organization cannot be null");
      toast.error("Selected Users or Selected Organization cannot be null");
      return;
    }
    const token = localStorage.getItem("token").toString();
    const headers = {
      Authorization: `Bearer ${token}`,
      Accept: "*/*",
      "Content-Type": "application/json",
    };
    const data = {
      emails: selectedUsers,
      orgName: orgName,
    };
    try {
      setErrors(null);
      console.log("Executing add to org");
      const response = await axios.post(
        `${config.url.API_BASE_URL}/api/owner/add-to-org`,
        data,
        { headers: headers }
      );
      console.log("Response: ", response);
      loadNonOrgUsers();
      loadOrganizations();
      setSelectedUsers([]);
      toast.success("User(s) added to the organization.");
    } catch (err) {
      setErrors(err);
      console.error("Executing add to org failed:", err);
      setLoading(false);
      toast.error("Something went wrong");
    }
  };

  const toggleEmailSelection = (email) => {
    if (selectedUsers && selectedUsers.includes(email)) {
      setSelectedUsers((prevSelectedUsers) =>
        prevSelectedUsers.filter((user) => user !== email)
      );
    } else {
      setSelectedUsers((prevSelectedUsers) => [
        ...(prevSelectedUsers || []),
        email,
      ]);
    }
  };

  const setOrganization = (org) => {
    setOrgName(org);
  };

  return (
    <>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className={classes.CentralizeDiv}>
          <Typography sx={{ mt: 3 }} variant="h4" gutterBottom>
            Select Organization and Users:
          </Typography>
          <Button
            variant="contained"
            fullWidth
            color="primary"
            sx={{ mt: 3, mb: 3 }}
            disabled={selectedUsers.length === 0 || orgName === ""}
            onClick={() => handleExecuteLink()}
          >
            Add Users to Organization {<KeyboardArrowRightIcon />}
          </Button>

          {nonOrgUsers.length > 0 ? (
            <>
              <select
                className="select"
                value={orgName}
                onChange={(e) => setOrganization(e.target.value)}
                style={{
                  textAlign: "center",
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
              >
                <option value="">Select an organization</option>
                {loading ? (
                  <option>Loading organizations...</option>
                ) : (
                  organizations.map((org, index) => (
                    <option key={index} value={org}>
                      {org}
                    </option>
                  ))
                )}
              </select>
              <Typography variant="h4" gutterBottom>
                All no-org Users
              </Typography>
              <List>
                {nonOrgUsers.map((user, index) => (
                  <ListItemButton
                    style={{
                      backgroundColor: selectedUsers.includes(user.email)
                        ? theme.palette.success.main
                        : "",
                    }}
                  >
                    <ListItem
                      key={index}
                      onClick={() => toggleEmailSelection(user.email)}
                    >
                      <ListItemText style={{ textAlign: "center" }}>
                        {user.email}
                      </ListItemText>
                    </ListItem>
                  </ListItemButton>
                ))}
              </List>
            </>
          ) : (
            <Typography sx={{ mt: 3 }} variant="h6" gutterBottom>
              No users outside oganizations left
            </Typography>
          )}
        </div>
      </ThemeProvider>
    </>
  );
};

export default NoOrgUsers;
