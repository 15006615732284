import React from "react";
import { CssBaseline, Container, Typography, Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import logo from "../../images/skills-log-logo.png";

const FAQPage = () => {
  return (
    <>
      <CssBaseline />
      <div style={{ display: "flex", justifyContent: "center", paddingTop: 20 }}>
        <img src={logo} alt="Skills Log Logo" style={{ width: 400, height: 'auto' }} />
      </div>
      <Container maxWidth="md" style={{ marginTop: 40, textAlign: "left"}}>
        <Typography variant="h4" gutterBottom align="center">
          Frequently Asked Questions
        </Typography>
        <Typography variant="subtitle1" gutterBottom align="center">
          Find answers to commonly asked questions below.
        </Typography>
        
        {/* FAQ Section */}
        <div style={{ marginTop: 20 }}>
          {/* FAQ Item 1 */}
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="h6">What is Skills Log?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Skills Log is a platform designed to help users track, manage, and showcase their skills effectively. It allows users to create a digital portfolio of their skills and share them with others.
                For each skill that Skills Log tracks, users can add evidence, endorsements, and more to provide a comprehensive view of their skills.
              </Typography>
            </AccordionDetails>
          </Accordion>

          {/* FAQ Item 2 */}
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="h6">What is evidence?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Evidence is simply content that supports your claim of having a particular skill and is meant to be viewed by recruiters and/or future employers. It can be plain text, image, links, tables, graphs, etc. Users can add evidence to the existing skills listed in the 'Skills' menu.
              </Typography>
            </AccordionDetails>
          </Accordion>

          {/* FAQ Item 3 */}
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="h6">Can I share my skills with others?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Yes, you can share your skills publicly or with specific individuals by adjusting the privacy settings for each peice of evidence you add. When editing the content of your evidence, you can choose to publish
                the content so that others can see it. You can also share your profile link with others to showcase your skills., e.g., <strong><a href="https://app.skills-log.com/404.">https://app.skills-log.com/profile-landing/my-email@university.edu.</a></strong> From there, others can
                view the skills you have added to your profile so long as they have been published.
              </Typography>
            </AccordionDetails>
          </Accordion>

          {/* FAQ Item 4 */}
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="h6">Can I search other users' profiles to see what skills they are working on?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Currently, the only way to view other users' profiles is by having them share their profile link with you. You can then view their profile and see the skills they have added to their profile.
                Searching for users is a feature that is not available in the current beta version of Skills Log, but it is a feature that is being considered for future versions.
              </Typography>
            </AccordionDetails>
          </Accordion>

          {/* Add more FAQ items here */}
        </div>
      </Container>
    </>
  );
};

export default FAQPage;
